import Image from 'next/image';
import {mutate} from 'swr';
import {
  CC_TYPE_AMEX,
  CC_TYPE_DISCOVER,
  CC_TYPE_MASTERCARD,
  CC_TYPE_VISA
} from '@components/checkout-v3/components/v3-detect-card-brand.jsx';
import {isofetch} from '@helpers/common/fetch.mjs';
import {useState} from 'react';
import AccountButton, {AccountBadge} from '@components/accounts/ui/Common';
import {useAccountProvider} from '@components/accounts/AccountProvider';

export const cardIconMap = {
  [CC_TYPE_VISA]: '/image/checkout/visa-logo.svg',
  [CC_TYPE_AMEX]: '/image/checkout/amex-logo.svg',
  [CC_TYPE_DISCOVER]: '/image/checkout/discover-logo.svg',
  [CC_TYPE_MASTERCARD]: '/image/checkout/mastercard-log.svg'
};

export const AccountCreditCard = ({card, handleEdit}) => {
  const [confirmDelete, setConfirmDelete] = useState();
  const [isDeleting, setIsDeleting] = useState();

  const cardIcon = cardIconMap[card?.brand];

  const {handleDeleteCard} = useAccountProvider();

  const deleteCard = async () => {
    setIsDeleting(true);

    await handleDeleteCard(card.id);

    setIsDeleting(false);
  };

  return (
    <div className="flex items-center md:flex-col md:my-0 md:p-0">
      <div className="flex flex-1 gap-4 items-center">
        <div
          className="w-9"
          style={{
            width: '32px',
            height: '28px'
          }}>
          {cardIcon ? <Image src={cardIcon} width={36} height={32} alt={card?.brand} /> : null}
        </div>
        <div>
          <div>{`Ending in ${card.card_no}`}</div>
          <div>{`Expires ${card.exp_month}/${card.exp_year}`}</div>
          {card.is_default_card === 1 && <AccountBadge>Default</AccountBadge>}
        </div>
      </div>
      <div className="md:mt-4 flex flex-col justify-center gap-4 items-center md:flex-row">
        <AccountButton uppercase outline onClick={handleEdit}>
          Edit
        </AccountButton>

        {card.is_default_card !== 1 && (
          <div
            className="underline text-rose-600 cursor-pointer"
            onClick={() => setConfirmDelete(true)}>
            Delete
          </div>
        )}
      </div>

      {confirmDelete && (
        <div className="absolute top-0 left-0 right-0 w-full h-full bg-neutral-200/90 flex flex-col justify-center items-center md:px-8">
          <div className="text-center">Are you sure you want to delete this credit card?</div>
          <div className="flex gap-4 my-4">
            <AccountButton onClick={deleteCard} submitting={isDeleting} danger>
              Yes, Delete
            </AccountButton>
            <AccountButton onClick={() => setConfirmDelete()}>Cancel</AccountButton>
          </div>
        </div>
      )}
    </div>
  );
};
