import {isofetch} from '@helpers/common/fetch.mjs';

export const checkEmailForAccountAndAccountConsents = async (email) => {
  const data = await isofetch.post(`/api/marketing/check-consent-for-user-email`, {
    email
  });

  // console.log({checkEmailForAccountAndAccountConsents: data});
  const hasAccount = data?.id ? true : false;

  const emailConsentIsTrue = data?.email_consent === 1 ?? data?.email_consent === true;
  const emailConsentStatus = emailConsentIsTrue ? true : false;

  return {
    accountExists: hasAccount,
    consentInfo: data ?? {},
    emailConsentStatus: emailConsentStatus
  };
};
