import {useCallback} from 'react';
import SideCartUiProvider, {useCartUI} from './CartSideCartUiProvider';

export default function CartUiProvider({children}) {
	return (
		<SideCartUiProvider>
			<Provider>{children}</Provider>
		</SideCartUiProvider>
	);
}

function Provider({ children }) {
	const { openCart } = useCartUI()
	const open = useCallback(() => {
		openCart()
	}, [openCart])
	return <>{children}</>
}
