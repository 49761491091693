import {sa_session_cookie} from '../../pages/_app';
import {mutate} from 'swr';

export const ROLE_ID_DEALER = 2;

export const sa_visitor_cookie = 'sa_visitor';


export const deleteCartAndCheckoutTokensOnClientAndServer = async () => {
  try {
    deleteClientSideCookie('sa_cart_token');
    deleteClientSideCookie('sa_checkout_token');
    deleteClientSideCookie('sa_attribution_id');
    const response = await deleteCookiesByNames(['sa_cart_token', 'sa_checkout_token', 'sa_attribution_id', 'sa_invoice_token'], {
      isDevTest: false
    });

    const mutateCartRes = await mutate(`/api/cartv2`);
    const mutateCheckoutRes = await mutate(`/api/checkout-v3/checkout`);
    // console.log("[mutateCheckoutRes][response]: ", {mutateCheckoutRes})
    // console.log("[mutateCartRes][response]: ", {mutateCartRes})
    return response;
  } catch (e) {
    console.error('[deleteCartAndCheckoutTokensOnClientAndServer][error]: ', e?.message);
  }
};

export const deleteCookiesByNames = async (cookieNames, options = {}) => {
  const isDevTest = options?.isDevTest ?? false;
  const apiUrl = isDevTest ? 'https://dev-skaraudio.ngrok.io/api/cookies' : '/api/cookies';

  try {
    // console.log("[deleteCookiesByNames] cookieNames:", cookieNames, apiUrl)
    let response = await fetch(apiUrl, {
      'Content-Type': 'application/json',
      method: 'DELETE',
      body: JSON.stringify({cookieNames})
    });
    response = await response.json();
    // console.log('[deleteCookiesByNames Response]:', response);
    return response;
  } catch (e) {
    console.error('[Error][deleteCookiesByNames] Message:', e?.message);
    console.error('[Error][deleteCookiesByNames] Stack:', e?.stack);
  }
};

export const deleteClientSideCookie = (cookieName) => {
  if (typeof window !== 'undefined') {
    document.cookie = `${cookieName}=; Path=/; Max-Age=0`;
    console.log(` [CART PROVIDER] >> Deleted client-side cookie: ${cookieName}`);
  }
};

export const readCookie = (name) => {
  return typeof document !== 'object'
    ? undefined
    : (() => {
      try {
        let nameEQ = name + '=';
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca?.length; i++) {
          let c = ca[i];
          while (c.charAt(0) === ' ') {
            c = c.substring(1, c?.length);
          }
          if (c.indexOf(nameEQ) === 0) {
            return c.substring(nameEQ.length, c?.length);
          }
        }
        return null;
      } catch (e) {
        console.error('[Error][readCookie]:', e?.message);
      }
    })();
};

export const setCookie = (name, value, days = 730) => {
  try {
    const expires = days
      ? ((date) => {
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        return '; expires=' + date.toUTCString();
      })(new Date())
      : '';
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  } catch (error) {
    console.error('An error occurred while setting the cookie:', error);
  }
};

export const deleteCookie = (name) => {
  try {
    console.log('[deleteCookie]: ', name);
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    document.cookie = `${name}='; expires=${yesterday.toUTCString()} ; path=/'`;
  } catch (error) {
    console.error('An error occurred while setting the cookie:', error);
  }
};

export const setSessionCookie = (sessionCookie, pathUrl) => {
  sessionCookie = sa_session_cookie + '=' + sessionCookie + `; path=${pathUrl}`;
  // console.log({sessionCookie})
  document.cookie = sessionCookie;
};
