import {useCallback, useState} from 'react';

export function useWorking(defaultValue = false) {
	const [state, setState] = useState(defaultValue);

	const wrapWorking = useCallback((code) => {
		return async (...vars) => {
			setState(true);
			const res = await code(...vars);
			setState(false);
			return res;
		};
	});

	return {isWorking: state, wrapWorking, setWorking: setState};
}
