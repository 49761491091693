import {useEffect, useState} from 'react';
import {isofetch} from '@helpers/common/fetch.mjs';

export const useAccountRmas = (data, currentRmaNumber) => {
  // console.log("[useAccountRmas] data", data);
  const [rma, setRma] = useState();
  const [isLoadingRma, setIsLoadingRma] = useState(false);
  const [rmaError, setRmaError] = useState(null);

  const rmas = data?.rmas;
  const orders = data?.orders;
  // console.log('[useAccountRmas] rmas', rmas);

  const getAndSetRma = async () => {
    try {
      setIsLoadingRma(true);
      setRmaError(null);

      let rmaInfo;
      let rmaOrder;

      const matchingRma = rmas?.find((rma) => rma?.rma_number === currentRmaNumber) ?? null;
      if (matchingRma) {
        rmaInfo = matchingRma;
      } else {
        const fetchedRma = await isofetch.get(
          `/api/accounts/rma/${encodeURIComponent(currentRmaNumber)}`
        );
        rmaInfo = fetchedRma;
      }

      const rmaOrderNumber = rmaInfo?.order_number;

      const matchingRmaOrder = orders?.find((order) => order?.orderNumber === rmaOrderNumber);
      if (matchingRmaOrder) {
        rmaOrder = matchingRmaOrder;
      } else {
        const fetchedOrder = await isofetch.get(
          `/api/accounts/rma/${encodeURIComponent(currentRmaNumber)}/order/${encodeURIComponent(rmaOrderNumber)}`
        );
        rmaOrder = fetchedOrder?.data;
      }

      const orderAgeInDays = Math.floor(
        (Date.now() - new Date(rmaOrder?.orderDate).getTime()) / (1000 * 60 * 60 * 24)
      );
      rmaInfo.matchingOrder = rmaOrder ?? {};
      rmaInfo.orderAgeInDays = rmaOrder?.orderAgeInDays ?? orderAgeInDays ?? null;

      const thirtyDays = 30 * 24 * 60 * 60 * 1000;
      const orderDate = rmaOrder?.orderDate ?? rmaOrder?.orderCreatedDate;
      rmaInfo.orderReturnCutOff = new Date(new Date(orderDate).getTime() + thirtyDays) ?? null;

      setRma(rmaInfo);
    } catch (e) {
      // console.error('[useAccountRmas][getAndSetRma] Error', e);
      setRma(null);
      setRmaError('Error loading RMA');
    } finally {
      setIsLoadingRma(false);
    }
  };

  useEffect(() => {
    if (data?.rmas?.length && currentRmaNumber) {
      getAndSetRma();
    }
  }, [data?.rmas, currentRmaNumber]);

  if (rma?.type === 'refund' && rma?.matchingOrder && rma?.returns_items?.length) {
    const refundedItems = rma?.matchingOrder?.refundedItems ?? [];
    // console.log({refundedItems});
    const allItemsRefunded = rma?.returns_items?.every((returnItem) => {
      // Look for a matching refunded item for each returned item
      return refundedItems?.some((refundedItem) => {
        return (
          refundedItem?.sku === returnItem?.sku && refundedItem?.quantity === returnItem?.quantity
        );
      });
    });

    if (allItemsRefunded) {
      rma.status = 'completed';
      // console.log('RMA status updated to completed.');
    } else {
      // console.log('Not all items have been refunded yet.');
    }
  }

  return {
    rmas,
    rma,
    isLoadingRma,
    rmaError
  };
};
