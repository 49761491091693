import {formatValidateAndCorrectEmailV3} from '@helpers/contact-us/validators.mjs';
import {isofetch} from '@helpers/common/fetch.mjs';

export const saveNewPopupSubscriber = async (email, isMobile) => {
  if (!email) throw new Error('No email supplied to form');

  const valRes = formatValidateAndCorrectEmailV3(email);
  const validatedEmail = valRes?.email;
  const emailIsValid = valRes?.valid;
  if (!emailIsValid) {
    throw new Error('Invalid email address');
  }
  const postData = {
    email: validatedEmail,
    popup_code: 'sa_popup',
    form_type: isMobile ? 'mobile' : 'desktop'
  };
  // console.log('[saveNewPopupSubscriber][validatedEmail]:', postData?.email);
  const data = await isofetch.post(
    `/api/marketing/subscribers-popup/save-popup-subscriber`,
    postData
  );

  // console.log('[saveNewPopupSubscriber][Response Data]:', data);
  const isSuccess = data?.status === 'success';
  if (!isSuccess) {
    throw new Error(`Error: Failed to save new subscriber`);
  }
  return isSuccess;
};

export const updateExisitingSubscriberConsentsEmailOnly = async (email, isMobile) => {
  if (email) {
    const valRes = formatValidateAndCorrectEmailV3(email);
    const validatedEmail = valRes?.email;
    if (!validatedEmail) {
      return null;
    }
  }

  const response = await fetch('/api/marketing/update-subscriber', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email: email,
      email_consent: true,
      form_type: isMobile ? 'mobile' : 'desktop'
    })
  });
  const data = await response.json();

  const isSuccess = data?.status === 'success';
  // console.log('Response from [updateExisitingSubscriberConsentsEmailOnly] => ', isSuccess);
  if (!isSuccess) {
    throw new Error('Error: Failed to update existing profile');
  }
  return isSuccess;
};
