export const reformatNameAllLowerCaseRemoveSpecialChars = (name) => {
  if (!name) {
    return null;
  }
  name = name.toLowerCase();
  const lowerCasedName = name;
  try {
    name = name.normalize('NFD').replace(/[^\w\s]/g, '');
  } catch (error) {
    name = lowerCasedName;
  }
  name = name.replace(/[^a-z\s]/g, '');
  return name;
}

export function capitalizeFirstLetter(text) {
  if (!text) {
    return text;
  }
  const temp = text?.split(" ")?.filter(x => x !== ".")?.map(word =>
    word.length > 0
      ? word?.[0].toUpperCase() + word?.substring(1)?.toLowerCase()
      : word)?.join(" ")?.trim();

  return temp?.replace(/\./g, " ");
}
