import Link from 'next/link';
import Image from 'next/image';
import {
  defaultThemeImagePaths,
  returnHighestAndLowestPriceValues
} from '@helpers/config-master.mjs';
import {
  CollectionProductsPageProductCardAddToCartSection
} from '@components/collections/CollectionPageMobileProdCard';
import {InStockIndicatorNoUseHook} from '@components/product/global/InStockIndicator';
import {ReviewStarsWithCountMobile} from '@components/product/reviews/reviews-with-images';
import {splitTitleFe} from '@components/FeHelpers';
import {getSubwooferHoleQtyText} from '@components/vehicle-specific/compatibility/sub-compat-check';

export const RenderVehicleSpecificProductListMobile = ({products, session}) => {
  // console.log("[RenderVehicleSpecificProductListMobile][Products]: ", products)
  const mobileProductImageSaleOverlay = defaultThemeImagePaths?.mobileProductImageSaleOverlay;

  const hasProducts = products?.length > 0;

  return (
    <>
      <div id="product-content-wrapper" className="grid grid-cols-1 gap-2">
        {!hasProducts && (
          <div className={'text-center italic pt-4 pb-8'}>
            No items found, try removing a filter
          </div>
        )}

        {hasProducts &&
          products?.map((product, index) => {
            return (
              <div key={`item-${index}`}>
                <RenderOneVehicleSpecificCollectionCardMobile
                  product={product}
                  session={session}
                  mobileProductImageSaleOverlay={mobileProductImageSaleOverlay}
                />
              </div>
            );
          })}
      </div>
    </>
  );
};

export const RenderOneVehicleSpecificCollectionCardMobile = ({
                                                               product,
                                                               session,
                                                               mobileProductImageSaleOverlay
                                                             }) => {
  // console.log("[RenderOneVehicleSpecificCollectionCardMobile][Product]: ", product)
  const user = session?.user;
  const isDealer = user?.isDealer;

  const sku = product.sku;
  const mpn = sku?.replace('FORD-2009-UP', 'FORD-2015-UP');

  const propProps = product?.properties;

  const averageReviewRating = product?.rating?.avg_rating;
  const totalReviewCount = product?.rating?.count;

  const [highPrice, lowPrice] = returnHighestAndLowestPriceValues(product, {isDealer});

  const imageSourceUrl = product?.image_1?.replace('https://www.skaraudio.com', '');

  const isInStock = product?.currentlyInStock === true;
  const isPreorderEligible = product?.preorderInfo?.is_pre_order_eligible === true;
  const isPreOrderEligibleAndActive = !isInStock && isPreorderEligible;

  const isEmpty = product?.category === 'empty-subwoofer-enclosures';
  const isLoaded = product?.category === 'loaded-subwoofer-enclosures';

  const isPorted = propProps?.['Enclosure Type'] === 'Ported';
  const isSealed = propProps?.['Enclosure Type'] === 'Sealed';
  const portedOrSealed = isPorted ? 'Ported' : 'Sealed';

  const isCarpeted = !String(sku).endsWith('-AR');

  const rmsPowerRating = propProps?.['RMS Power'];
  const peakPowerRating = propProps?.['Peak Power'];
  // console.log('[peakPowerRating]:', peakPowerRating);

  const totalSubwooferHoles = product?.totalSubwooferHoles ?? null;
  const subwooferHoleQtyText = getSubwooferHoleQtyText(totalSubwooferHoles);

  const size = propProps?.Size ?? propProps?.['Subwoofer Size'];

  let productTitle = product?.site_name;
  productTitle = splitTitleFe(productTitle, 'Compatible');

  // if (sku === 'L-SDR-VS-2X10RAM-2002-2018') {
  //   console.log("[sku]:", sku, "[productTitle #1]:", productTitle);
  // }

  let startYear = propProps?.['Start Year'];
  let endYearForTitle = propProps?.['End Year'];

  const skuIsVehicleSpecificTrue = [
    'VS-',
    'VS.L',
    'VS-L',
    'L-VD',
    'L-SVR',
    'L-EVL',
    'L-SDR-',
    'L-VS'
  ];

  const isYearEndUpTypeSku = sku.toLowerCase().includes('-up');
  if (isYearEndUpTypeSku) {
    endYearForTitle = 'Up';
  }

  if (isEmpty) {
    productTitle = splitTitleFe(productTitle, 'Compatible');
    productTitle = removeYearFromTitle(productTitle);
    productTitle = startYear + '-' + endYearForTitle + ' ' + productTitle;
    productTitle = productTitle + ` Compatible ${subwooferHoleQtyText} ${size} Subwoofer Enclosure`;
  }

  if (productTitle?.includes(' undefined')) {
    if (sku === 'VS-1X10FORD-2009-UP-SUPERCREWCAB-V-AR') {
      productTitle = productTitle?.replace(' undefined', ' 10"');
    }
    if (sku === 'VS-1X12FORD-2009-UP-SUPERCREWCAB-V-AR') {
      productTitle = productTitle?.replace(' undefined', ' 12"');
    }
    if (sku === 'VS-2X10FORD-2009-UP-SUPERCREWCAB-AR') {
      productTitle = productTitle?.replace(' undefined', ' 10"');
    }
    if (sku === 'VS-2X10FORD-2009-UP-SUPERCREWCAB-V-AR') {
      productTitle = productTitle?.replace(' undefined', ' 10"');
    }
  }

  if (isLoaded) {
    const trimExists = propProps?.Trim ? true : false;
    let modelPlusTrim = propProps?.Model;
    if (trimExists) {
      modelPlusTrim = propProps?.Model + ' ' + propProps?.Trim;
    }
    productTitle =
      startYear +
      '-' +
      endYearForTitle +
      ' ' +
      propProps?.Make +
      ' ' +
      modelPlusTrim +
      ` Compatible ${subwooferHoleQtyText} ${size}` +
      ' Loaded Subwoofer Enclosure';

    // Check if the title contains the specific string and replace it
    const targetString = 'Chevrolet/GMC Silverado 1500|Sierra 1500';
    if (productTitle.includes(targetString)) {
      productTitle = productTitle.replace(targetString, 'Chevrolet Silverado/GMC Sierra 1500');
    }
  }

  let bulletTopStyle = '29.5%';
  let bulletRightStyle = '3.5%';

  let imageFromBottom = '-45px';
  let imageWidth = '200px';
  let imageHeight = '200px';
  let imageFromLeft = '0px';

  if (productTitle?.length > 98) {
    bulletTopStyle = '33.5%';
    bulletRightStyle = '2.5%';
  }

  let skuFontSize = '10px';
  if (sku?.length > 38) {
    skuFontSize = '9px';
  }
  if (sku?.length > 40) {
    skuFontSize = '9px';
  }
  if (sku?.length > 42 || sku?.includes('L-VD')) {
    skuFontSize = '8.75px';
  }
  if (sku?.length > 44 || sku?.includes('L-VD')) {
    skuFontSize = '8px';
  }

  if (productTitle?.includes('Dodge Charger')) {
    bulletTopStyle = '26.5%';
    bulletRightStyle = '2.5%';
    imageFromBottom = '-55px';
    imageWidth = '165px';
    imageHeight = '165px';
  }

  if (productTitle?.includes('Dodge Durango')) {
    bulletTopStyle = '26.5%';
    bulletRightStyle = '2.5%';
    imageFromBottom = '-50px';
    imageWidth = '150px';
    imageHeight = '150px';
  }

  if (productTitle?.includes('Ram 1500 (5th Gen)')) {
    bulletTopStyle = '26.5%';
    bulletRightStyle = '2.5%';
    imageFromBottom = '-40px';
  }

  if (productTitle?.includes('Ram Quad/Crew Cab')) {
    imageFromBottom = '-15px';
    imageWidth = '230px';
    imageHeight = '230px';
  }

  if (productTitle?.includes('Honda')) {
    bulletTopStyle = '26.5%';
    bulletRightStyle = '2.5%';
    imageFromBottom = '-47.5px';
    imageWidth = '165px';
    imageHeight = '165px';
    if (productTitle?.includes('CR-V')) {
      imageFromBottom = '-60px';
      imageWidth = '150px';
      imageHeight = '150px';
    }
    if (productTitle?.includes('Civic')) {
      imageFromBottom = '-50px';
      imageWidth = '175px';
      imageHeight = '175px';
    }
    if (productTitle?.includes('Civic Sedan')) {
      imageFromBottom = '-50px';
      imageWidth = '160px';
      imageHeight = '160px';
    }
  }

  if (productTitle?.includes('Tesla')) {
    imageWidth = '165px';
    imageHeight = '165px';
    if (productTitle?.includes('Model Y')) {
      imageFromBottom = '-50px';
      imageWidth = '150px';
      imageHeight = '150px';
    }
    if (productTitle?.includes('Model 3')) {
      imageFromBottom = '-40px';
      imageWidth = '165px';
      imageHeight = '165px';
    }
  }

  if (productTitle?.includes('2007-2013 Chevrolet Silverado')) {
    bulletTopStyle = '35.0%';
    bulletRightStyle = '1.5%';
  }

  if (productTitle?.includes('2007-2013 Chevrolet Silverado')) {
    bulletTopStyle = '35.0%';
    bulletRightStyle = '1.5%';
  }

  if (productTitle?.includes('2007-2013 Chevrolet Silverado')) {
    imageFromBottom = '-55px';
  }

  if (productTitle?.includes('2001-2006 Chevrolet Silverado')) {
    imageFromBottom = '-50px';
  }

  if (productTitle?.includes('2014-2018 Chevrolet Silverado')) {
    imageFromBottom = '-55px';
  }

  if (productTitle?.includes('2019') && productTitle?.includes('Chevrolet Silverado')) {
    imageFromBottom = '-52.5px';
    imageWidth = '180px';
    imageHeight = '180px';
  }

  if (productTitle?.includes('Toyota')) {
    imageFromBottom = '-35px';
    imageWidth = '190px';
    imageHeight = '190px';
    if (productTitle?.includes('Camry')) {
      imageFromBottom = '-60px';
      imageWidth = '150px';
      imageHeight = '150px';
    }
    if (productTitle?.includes('RAV4')) {
      imageFromBottom = '-60px';
      imageWidth = '155px';
      imageHeight = '155px';
    }
    if (productTitle?.includes('Toyota Tacoma Double Cab')) {
      imageFromBottom = '-62px';
      imageWidth = '165px';
      imageHeight = '165px';
    }
  }

  if (productTitle?.includes('Nissan')) {
    if (productTitle?.includes('Altima')) {
      imageFromBottom = '-55px';
      imageWidth = '157px';
      imageHeight = '157px';
    }
    if (productTitle?.includes('Titan Crew Cab Compatible Single')) {
      imageFromBottom = '-30px';
    }
    if (productTitle?.includes('Titan Crew Cab Compatible Dual')) {
      imageFromBottom = '-25px';
      imageWidth = '225px';
      imageHeight = '225px';
    }
  }

  if (productTitle?.includes('Jeep')) {
    if (productTitle?.includes('Wrangler Unlimited (JK) 4-Door')) {
      imageFromBottom = '-50px';
      imageWidth = '180px';
      imageHeight = '180px';
    }

    if (productTitle?.includes('Wrangler JL 4-Door')) {
      imageFromLeft = '15px';
      imageFromBottom = '-55px';
      imageWidth = '145px';
      imageHeight = '145px';

      if (sku?.endsWith('-L')) {
        productTitle = '2018-2024 Jeep Wrangler JL Compatible Single 10" Loaded Subwoofer Enclosure (Left Side)';
      }
      if (sku?.endsWith('-R')) {
        productTitle = '2018-2024 Jeep Wrangler JL Compatible Single 10" Loaded Subwoofer Enclosure (Right Side)';
      }
    }
    if (productTitle?.includes('Grand Cherokee')) {
      imageFromBottom = '-40px';
    }
  }

  productTitle = productTitle.replaceAll('  ', ' ');
  productTitle = productTitle.replaceAll('-Up', '-UP ');

  const imageStyling = {
    width: imageWidth,
    height: imageHeight,
    objectFit: 'fill',
    position: 'relative',
    bottom: imageFromBottom,
    zIndex: '0',
    borderRadius: '10px',
    left: imageFromLeft
  };

  return (
    <div
      id="product-row-wrapper"
      className="h-56 grid grid-rows-3 col-span-3 gap-3 overflow-hidden relative shadow-lg p-2"
      style={{
        backgroundColor: '#FFFFFF',
        borderRadius: '4px',
        boxShadow: '0px 4px 10px rgba(0,0,0,0.1)',
        position: 'relative'
      }}>
      <div className="row-start-1 row-span-2 relative">
        <div
          style={{
            position: 'absolute',
            padding: '4px',
            zIndex: '1'
          }}>
          <h2
            style={{
              fontWeight: '500',
              fontSize: '15.75px'
            }}>
            <Link href={`/products/${product?.site_handle}`}>{productTitle}</Link>
          </h2>

          <div
            style={{
              position: 'absolute',
              top: 'calc(85% + 1px)',
              left: '0%',
              backgroundColor: '#FFFFFF',
              fontSize: '8px',
              padding: '3px',
              zIndex: '2'
            }}></div>

          <div
            style={{
              position: 'absolute',
              top: 'calc(95% + 1px)',
              left: '1px',
              zIndex: '5'
            }}>
            {totalReviewCount > 0 && (
              <ReviewStarsWithCountMobile
                stars={averageReviewRating}
                size={16}
                count={totalReviewCount}
              />
            )}
          </div>
        </div>

        <Link href={`/products/${product?.site_handle}`}>
          <div style={imageStyling}>
            <Image
              className=""
              fill={true}
              src={imageSourceUrl}
              alt={product?.name || 'Skar Audio Collection List Product Image'}
              // loading='eager'
            />
          </div>
        </Link>

        <div
          style={{
            position: 'absolute',
            bottom: '-60%',
            backgroundColor: 'transparent',
            zIndex: '2',
            marginLeft: '-5px'
          }}>
          <strong className="font-bold text-[10px]">SKU:</strong>
          <span style={{fontSize: skuFontSize}}>&nbsp;{mpn}</span>
        </div>
      </div>

      <div
        id="right-side-top"
        style={{
          display: 'flex',
          position: 'absolute',
          right: bulletRightStyle,
          top: bulletTopStyle
        }}>
        <ul
          className="list-disc"
          style={{
            fontWeight: '400',
            fontSize: '14px'
          }}>
          {isLoaded && <li>Peak Power: {peakPowerRating}</li>}
          {isPorted && (
            <li>
              Type:&nbsp;
              <strong
                style={{
                  color: 'blue',
                  fontFamily: 'oswald',
                  letterSpacing: '0.25px'
                }}>
                Ported
              </strong>
            </li>
          )}
          {isSealed && <li>Type: Sealed</li>}
          {isEmpty && <li>Finish: {isCarpeted ? 'Carpet' : 'Armor Coated'}</li>}
        </ul>
      </div>

      <div
        id="right-side-bottom"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          position: 'absolute',
          right: 0,
          top: '70%',
          transform: 'translateY(-42%)',
          padding: '4px',
          marginRight: '2px'
        }}>
        <div
          style={{
            fontWeight: '400',
            fontSize: '14px',
            textDecoration: 'line-through',
            textDecorationColor: '#DC2626',
            zIndex: '2'
          }}>
          MAP: ${highPrice}
        </div>

        <div
          style={{
            fontWeight: '700',
            fontSize: '17px',
            marginTop: '-4px',
            zIndex: '2',
            color: 'rgb(3, 105, 161)',
            marginBottom: '12.5px'
          }}>
          Sale: ${lowPrice}
        </div>

        <div style={{marginTop: '-10.5px', marginRight: '3px'}}>
          <InStockIndicatorNoUseHook
            isInStock={isInStock}
            style={{
              fontWeight: 'bolder',
              fontSize: '13px',
              verticalAlign: 'bottom'
            }}
          />
        </div>

        <div className="mt-1">
          <CollectionProductsPageProductCardAddToCartSection
            product={product}
            mobileProductImageSaleOverlay={mobileProductImageSaleOverlay}
          />
        </div>
      </div>
    </div>
  );
};

const removeYearFromTitle = (title) => {
  if (!title) {
    return;
  }
  // Regular expression pattern that matches either 'YYYY-YYYY ' or 'YYYY-Up '
  const yearPattern = /\b\d{4}-(\d{4}|Up)\s/;
  // Replacing the pattern with an empty string to remove the year portion
  const updatedTitle = title.replace(yearPattern, '');
  return updatedTitle;
};
