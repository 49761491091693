import Head from 'next/head';
import {
  GenerateOpenGraphPageMetaData,
  GenerateStaticPageMetaData
} from '@components/seo/MetaDataDefaults';

export const CustomCollectionSEO_JsonLd = ({collectionPageInfo}) => {
  // console.log('[CustomCollectionSEO_JsonLd][collectionPageInfo]:', collectionPageInfo);

  const collectionSlug = collectionPageInfo?.handle;
  const collectionPageTitle = collectionPageInfo?.collection_page_title;
  const collectionKeywords = collectionPageInfo?.collection_page_keywords;
  const canonicalUrl = `https://www.skaraudio.com/collections${collectionSlug}`;

  let seoDescription = collectionPageInfo?.collection_seo_description;
  seoDescription = seoDescription ?? collectionPageInfo?.collection_description ?? '';

  let collectionImagePath = collectionPageInfo?.collection_page_image ?? null;
  if (collectionImagePath) {
    collectionImagePath = `https://www.skaraudio.com/image${collectionImagePath}`;
  }

  const breadcrumbListJsonLd = generateCustomCollectionPageBreadcrumbsJsonLD(collectionPageInfo);
  // console.log({collectionKeywords, collectionSlug, seoDescription})

  return (
    <>
      <Head>
        <title>{collectionPageTitle}</title>
        <meta charSet='UTF-8' />
        <meta name='keywords' content={collectionKeywords} />
        <meta name='description' content={seoDescription} />
        <link rel='canonical' href={canonicalUrl} />

        <GenerateStaticPageMetaData />
        <GenerateOpenGraphPageMetaData
          title={collectionPageTitle}
          url={canonicalUrl}
          description={seoDescription}
          image={collectionImagePath}
        />

        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{__html: JSON.stringify(breadcrumbListJsonLd)}}
          key='collection-breadcrumbs-jsonld'
        />
      </Head>
    </>
  );
};

const generateCustomCollectionPageBreadcrumbsJsonLD = (collectionPageInfo) => {
  if (!collectionPageInfo) return null;

  const hasParentMenu = collectionPageInfo?.parentMenuName;

  let breadcrumbs = [{label: 'Home', link: '/'}];

  if (hasParentMenu) {
    breadcrumbs = [
      ...breadcrumbs,
      {
        label: collectionPageInfo?.parentMenuName,
        link: `/collections${collectionPageInfo?.parentMenuHandle}`
      },
      {
        label: collectionPageInfo?.collection_page_title,
        link: `/collections${collectionPageInfo?.handle}`
      }
    ];
  } else {
    breadcrumbs = [
      ...breadcrumbs,
      {
        label: collectionPageInfo?.collection_page_title,
        link: `/collections${collectionPageInfo?.handle}`
      }
    ];
  }

  const breadcrumbList = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbs.map((item, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      name: item?.label,
      ...(item?.link ? {item: `https://www.skaraudio.com${item?.link}`} : {})
    }))
  };

  return breadcrumbList;
};
