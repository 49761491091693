import styles from './review-photos.module.css';
import * as Dialog from '@radix-ui/react-dialog';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import ReviewIndividualV2 from '@components/product/reviews/review-photos/review-individual-v2';
import {useEffect} from 'react';
import {useRouter} from 'next/router';
import {ReviewAllPhotos} from './review-all-photos';
import {ChevronLeft} from '@components/widgets/Icons';

export default function ReviewPopupV2({
  reviews,
  currentReview,
  currentReviewImageIndex,
  prevReview,
  nextReview,
  show,
  close,
  handleViewAll,
  onClickReviewPhoto,
  nextPage,
  isLoadingMore,
  isFirst,
  isLast,
  isReachingEnd
}) {
  const router = useRouter();

  useEffect(() => {
    const isDialogHashPresent = () => window.location.hash === '#reviewPopup';

    const closeDialog = () => {
      if (isDialogHashPresent()) {
        // console.log('[ReviewPopupV2] closeDialog');
        const newPath = router.asPath.split('#')[0];
        router.push(newPath, undefined, {shallow: true});
      }
      close();
    };

    const handlePopState = () => {
      if (!isDialogHashPresent()) {
        closeDialog();
      }
    };

    if (show && !isDialogHashPresent()) {
      window.history.pushState(null, null, '#reviewPopup');
    } else if (!show && isDialogHashPresent()) {
      // console.log('[ReviewPopupV2] remove hash from url');
      const newPath = router.asPath.split('#')[0];
      router.push(newPath, undefined, {shallow: true});
    }

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
      if (isDialogHashPresent()) {
        const newPath = router.asPath.split('#')[0];
        router.push(newPath, undefined, {shallow: true});
      }
    };
  }, [show]);

  const isShowAllPhotos = !currentReview;

  const handleClose = () => {
    const newPath = router.asPath.split('#')[0];
    router.push(newPath, undefined, {shallow: true});
    close();
  };

  const dialogTitle = isShowAllPhotos ? 'Review Photos' : 'Review Photo';

  return (
    <Dialog.Root open={show} onOpenChange={handleClose}>
      <Dialog.Portal>
        <div className="fixed flex h-full items-center justify-center inset-0 z-50 text-black">
          <Dialog.Overlay className="modal-overlay fixed z-0 inset-0 md:bg-black md:bg-opacity-50" />

          <Dialog.Content
            className={`modal-content ${styles.popup} ${styles['popup-delay-mobile']} fade-in relative text-white md:bg-white md:text-black flex flex-col w-full h-full md:pb-6 md:rounded-md text-left align-middle md:shadow-xl z-50 lg:overflow-hidden`}>
            <VisuallyHidden.Root>{dialogTitle}</VisuallyHidden.Root>

            <div className="md:mb-4 pt-1 pl-2 pr-0 md:px-6 md:py-2 border-b flex items-center justify-between sticky top-0 z-100">
              <div className="flex gap-4 items-center">
                {isShowAllPhotos && (
                  <h3 className="font-oswald text-white md:text-black text-lg md:text-2xl uppercase text-left">
                    Review Photos
                  </h3>
                )}
                {!isShowAllPhotos && (
                  <button
                    className="text-white md:text-black text-base underline underline-offset-2 cursor-pointer flex gap-0.5 translate-y-0.5"
                    onClick={handleViewAll}
                    aria-label="View All Photos">
                    <ChevronLeft className="h-7 w-5" />
                    View All Photos
                  </button>
                )}
              </div>
              <div className="p-2 cursor-pointer" onClick={handleClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={'h-7 w-7'}
                  viewBox="0 0 20 20"
                  fill="currentColor">
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>

            {currentReview && (
              <ReviewIndividualV2
                review={currentReview}
                currentReviewImageIndex={currentReviewImageIndex}
                prevReview={prevReview}
                nextReview={nextReview}
                isFirst={isFirst}
                isLast={isLast}
              />
            )}

            {!currentReview && (
              <div className={`${styles['all-photos']} md:px-6 pb-6 overflow-y-auto`}>
                <ReviewAllPhotos
                  reviews={reviews}
                  onClickReviewPhoto={onClickReviewPhoto}
                  nextPage={nextPage}
                  isReachingEnd={isReachingEnd}
                  isLoadingMore={isLoadingMore}
                />
              </div>
            )}
          </Dialog.Content>
        </div>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
