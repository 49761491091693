export const FooterSocialMediaIcons = () => {
  return (
    <>
      <a
        target='_blank'
        href='https://www.instagram.com/skaraudioofficial'
        rel='noreferrer'
        aria-label='Instagram (opens in a new window)'
        aria-describedby='new-window-desc'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='28'
          height='28'
          fill='none'
          viewBox='0 0 16 16'>
          <path
            fill='#fff'
            d='M8 0H4.7c-.9 0-1.4.2-2 .4-.5.2-1 .5-1.4 1a4 4 0 0 0-.9 1.4L0 4.7a57.9 57.9 0 0 0 .4 8.5c.2.6.5 1 1 1.5.4.4.8.7 1.4.9l1.9.4a57.8 57.8 0 0 0 8.5-.4 4 4 0 0 0 1.5-1 4 4 0 0 0 .9-1.4l.4-1.9a57.7 57.7 0 0 0-.4-8.5 4 4 0 0 0-1-1.5 4 4 0 0 0-1.4-.9L11.3 0H8Zm-.7 1.4H8a55.2 55.2 0 0 1 4.7.4c.4.1.7.3 1 .6.2.2.4.5.5.9.1.3.3.7.3 1.5a54.8 54.8 0 0 1-.3 8 2.5 2.5 0 0 1-.6.8c-.2.3-.5.5-.9.6-.3.1-.7.3-1.5.3a55.8 55.8 0 0 1-8-.3 2.5 2.5 0 0 1-.8-.6 2.5 2.5 0 0 1-.6-.9c-.1-.3-.3-.7-.3-1.5a56.2 56.2 0 0 1 .3-8c.1-.3.3-.6.6-.8.2-.3.5-.5.9-.6.3-.1.7-.3 1.5-.3h2.5Zm5 1.4a1 1 0 1 0 0 1.9 1 1 0 0 0 0-2ZM8 3.8a4.1 4.1 0 1 0-.1 8.3A4.1 4.1 0 0 0 8 4Zm0 1.5a2.7 2.7 0 1 1 0 5.4 2.7 2.7 0 0 1 0-5.4Z'
          />
        </svg>
      </a>
      <a
        className='mx-2'
        target='_blank'
        href='https://www.facebook.com/SkarAudio'
        rel='noreferrer'
        aria-label='Facebook (opens in a new window)'
        aria-describedby='new-window-desc'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='28'
          height='28'
          fill='none'
          viewBox='0 0 16 16'>
          <path
            fill='#fff'
            d='m11.7 9 .5-2.9H9.4V4.2c0-.8.4-1.5 1.6-1.5h1.3V.2L10 0C7.8 0 6.3 1.4 6.3 3.9v2.2H3.7V9h2.6v7h3V9h2.4Z'
          />
        </svg>
      </a>
      <a
        target='_blank'
        href='https://www.youtube.com/skaraudio'
        rel='noreferrer'
        aria-label='YouTube (opens in a new window)'
        aria-describedby='new-window-desc'
        style={{marginTop: 6}}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='38'
          height='34'
          fill='none'
          viewBox='0 0 22 20'>
          <path
            fill='#fff'
            d='M11 .7h.2c1.1 0 6.9 0 8.4.5a2.8 2.8 0 0 1 2 2l.2 1.9v.5l.1.1.1 2.7v.1a44.1 44.1 0 0 1-.1 2.9v.2l-.4 2.2a2.8 2.8 0 0 1-2 2c-1.5.4-7.6.4-8.4.4H6.3L6 16c-1.6 0-3-.2-3.7-.4a2.8 2.8 0 0 1-2-2l-.3-2v-.3a43.2 43.2 0 0 1 0-2.7V5.9h.1V5l.4-2a2.8 2.8 0 0 1 1.9-1.9L6.1 1h.8a137.2 137.2 0 0 1 3.9-.1h.3ZM8.9 5.2v6.6l5.7-3.3-5.7-3.3Z'
          />
        </svg>
      </a>

      <span id='new-window-desc' className='visually-hidden'>
        Opens in a new window
      </span>
    </>
  );
};
