import {isofetch} from '@helpers/common/fetch.mjs';

export const useAccountMarketing = (updateSession) => {
  const handleEmail = async (newEmailConsentValue) => {
    try {
      const payload = {
        type: 'email',
        value: newEmailConsentValue
      };

      await isofetch.post('/api/accounts/marketing', payload);
      updateSession();
    } catch (error) {
      console.error(error);
      throw 'There was a problem updating your email marketing preferences. Please try again.';
    }
  };

  const handleSms = async (newSmsConsentValue) => {
    try {
      const payload = {
        value: newSmsConsentValue,
        type: 'sms'
      };
      await isofetch.post('/api/accounts/marketing', payload);
      updateSession();
    } catch (error) {
      console.error(error);
      throw 'There was a problem updating your SMS marketing preferences. Please try again.';
    }
  };

  return {
    handleEmail,
    handleSms
  };
};
