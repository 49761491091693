import {useEffect, useState} from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import {CloseXButton, Popup} from './popup.jsx';
import {useRouter} from 'next/router';
import {MobilePopup} from '@components/marketing/popup/mobile-popup.jsx';
import {DesktopPopup} from '@components/marketing/popup/desktop-popup.jsx';
import {useForm} from 'react-hook-form';
import {readCookie, setCookie} from '@components/widgets/Cookies';
import {
  saveNewPopupSubscriber,
  updateExisitingSubscriberConsentsEmailOnly
} from '@components/marketing/SavePopupSubscribers';
import {checkEmailForAccountAndAccountConsents} from '@components/marketing/CheckSubscriberExists';
import {formatValidateAndCorrectEmailV3} from '@helpers/contact-us/validators.mjs';
import {useMobileOrTablet} from '@helpers/hooks/useWindow';
import {consoleLogPopUpSubscriber} from '@helpers/logging/provider-logging.mjs';
import {useSession} from 'next-auth/react';
import {useUI} from '@components/widgets/UiProvider';

export const popup_cookie_id = 'sa_popup';

export const SubscriberPopup = ({timeout = 6000, forceOpen = false}) => {
  const router = useRouter();
  const path = router?.asPath;

  const {data: session, status} = useSession();
  const sessionIsLoading = status === 'loading';
  const sessionIsLoaded = sessionIsLoading !== true;

  const userSession = session?.user;
  const hasAccount = userSession?.account_id;
  const emailConsentStatus = userSession?.email_consent;

  const [isOpen, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [existsMessage, setExistsMessage] = useState('');
  const [formError, setFormError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const {displayMobileNav} = useUI();
  const isMobileNavOpen = displayMobileNav === true;

  const {register, handleSubmit, watch, formState} = useForm({email: null});
  const {errors} = formState;
  const email = watch('email', null);

  const isMobile = useMobileOrTablet();
  const popupCookie = readCookie(popup_cookie_id);

  const resetForm = () => {
    setFormError('');
    setExistsMessage('');
    setIsDisabled(false);
    setIsSubmitting(false);
    setSuccess(false);
  };

  const resetViewport = () => {
    const viewport = document.querySelector('meta[name=viewport]');
    if (viewport) {
      viewport.parentNode.removeChild(viewport);
    }
    const newViewport = document.createElement('meta');
    newViewport.name = 'viewport';
    newViewport.content = 'width=device-width, initial-scale=1';
    document.getElementsByTagName('head')[0].appendChild(newViewport);
  };

  useEffect(() => {
    if (sessionIsLoading) {
      return;
    }
    if (sessionIsLoaded) {
      if (emailConsentStatus === true) {
        // console.log('[sessionIsLoaded][userIsAlreadyConsentedToEmail]:', 'Will Not Open Popup');
        return;
      }
    }
  }, [sessionIsLoading, sessionIsLoaded]);

  useEffect(() => {
    if (!success) {
      const validationResponse = formatValidateAndCorrectEmailV3(email);
      const isValidEmail = validationResponse?.valid;
      setIsDisabled(!email?.length || !isValidEmail);
    }
  }, [email, success]);

  const pathsArray = [
    '/account',
    '/checkout',
    '/subwoofer-wiring-diagrams',
    '/returns',
    '/reviews',
    '/search',
    '/pages/frequently-asked-questions',
    '/pages/return-policy',
    '/pages/privacy-policy',
    '/pages/warranty-policy',
    '/pages/contact-us'
  ];

  useEffect(() => {
    if (!hasAccount) {
      if (
        pathsArray.some((p) => {
          return path.includes(p);
        }) ||
        hasAccount ||
        emailConsentStatus ||
        popupCookie ||
        isMobileNavOpen
      ) {
        // consoleLogOrangeFE('[POPUP] Should not open modal', {
        //   shouldOpen: false,
        //   why: {
        //     hasAccount,
        //     emailConsentStatus,
        //     popupCookie,
        //   }
        // });
        return;
      }
    }

    const timeoutRef = setTimeout(() => {
      // console.log('[POPUP] Opening Modal..', {popupCookie});
      if (popupCookie !== 'closed' && popupCookie !== 'subscribed') {
        openModal();
      }
    }, timeout);
    return () => clearTimeout(timeoutRef);
  }, [emailConsentStatus, path, popupCookie, timeout, pathsArray]);

  useEffect(() => {
    if (forceOpen) {
      openModal();
    }
  }, [forceOpen]);

  const closeModal = () => {
    setOpen(false);
    setCookie(popup_cookie_id, 'closed', 3);
    resetForm();
    resetViewport();
  };

  const openModal = () => {
    resetForm();
    setOpen(true);
  };

  const handleError = (e) => {
    const errMsg = e?.response?.data?.message ?? e?.message ?? e?.response?.data;
    setFormError(errMsg);
    console.error('[Form Error] Message:', errMsg);
    console.error('[Form Error] Stack:', e?.stack);
  };

  const setModalFulfilled = () => {
    setCookie(popup_cookie_id, 'subscribed');
    setOpen(false);
  };

  const submitForm = async () => {
    try {
      resetForm();
      setIsSubmitting(true);

      const validationResponse = await checkEmailForAccountAndAccountConsents(email);
      consoleLogPopUpSubscriber(validationResponse);
      const accountDoesExist = validationResponse?.accountExists;
      const accountDoesNotExist = !accountDoesExist;

      const consentStatuses = validationResponse?.consentInfo;
      const emailConsentIsTrue = consentStatuses?.email_consent === 1;
      const emailIsOptedIn = consentStatuses?.email_consent_ts;
      const emailOptedOutTs = consentStatuses?.email_opted_out_ts;
      // console.log("[submitForm] emailConsentIsTrue:", emailConsentIsTrue);
      // console.log("[submitForm] emailIsOptedIn:", emailIsOptedIn);
      // console.log("[submitForm] emailOptedOutTs:", emailOptedOutTs);

      const accountExistsWithValidEmailOptInAndConsent =
        accountDoesExist && emailConsentIsTrue && emailIsOptedIn && emailOptedOutTs === null;
      // consoleLogPopUpSubscriber({accountExistsWithValidEmailOptInAndConsent});

      if (accountDoesExist === true) {
        if (accountExistsWithValidEmailOptInAndConsent) {
          setExistsMessage("You're already a member!");
          setIsSubmitting(false);
          setSuccess(true);
          setTimeout(() => {
            setModalFulfilled();
          }, 1500);
        } else {
          const wasSuccess = await updateExisitingSubscriberConsentsEmailOnly(email, isMobile);
          console.log('[subscriberPopup] wasSuccess:', wasSuccess);
          if (wasSuccess) {
            setExistsMessage("You're already a member!");
            setIsSubmitting(false);
            setSuccess(true);
            setTimeout(() => {
              setModalFulfilled();
            }, 1500);
          } else {
            throw new Error('Failed to update subscriber');
          }
        }
      }

      if (accountDoesNotExist) {
        const wasSuccess = await saveNewPopupSubscriber(email, isMobile);
        console.log('[subscriberPopup][accountDoesNotExist] wasSuccess:', wasSuccess);
        setSuccess(wasSuccess);
        setIsSubmitting(false);
        setTimeout(() => {
          setModalFulfilled();
        }, 1750);
      }
    } catch (e) {
      handleError(e);
      setIsSubmitting(false);
      setExistsMessage(null);
      setSuccess(false);
    }
  };

  const disablePopUpIfAlreadyConsentingUser = emailConsentStatus === true;
  if (disablePopUpIfAlreadyConsentingUser) {
    return;
  }

  if (!sessionIsLoading) {
    return (
      <Dialog.Root open={isOpen} onOpenChange={setOpen}>
        <Popup isOpen={isOpen} closeModal={closeModal}>
          {!success && <CloseXButton closeModal={closeModal} />}

          {!isMobile && (
            <DesktopPopup
              handleSubmit={handleSubmit}
              submitForm={submitForm}
              success={success}
              existsMessage={existsMessage}
              errors={errors}
              register={register}
              isSubmitting={isSubmitting}
              formError={formError}
              isDisabled={isDisabled}
              email={email}
            />
          )}

          {isMobile && (
            <MobilePopup
              handleSubmit={handleSubmit}
              submitForm={submitForm}
              success={success}
              existsMessage={existsMessage}
              errors={errors}
              register={register}
              isSubmitting={isSubmitting}
              formError={formError}
              isDisabled={isDisabled}
              email={email}
            />
          )}

          <div></div>
        </Popup>
      </Dialog.Root>
    );
  }
};
