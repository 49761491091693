import React, {useEffect, useState} from 'react';

export function CheckMarkAnimation({render, size = 32}) {
	const [visible, setVisible] = useState(true);

	useEffect(() => {
		setVisible(true);
		// const timer = setTimeout(() => setVisible(false), 2000)
		// return () => clearTimeout(timer)
	}, [render]);

	return (
		<div>
			<svg x='0px' y='0px' width={`${size}px`} height={`${size}px`} viewBox={`0 0 32 32`} className='checkmark gtm-sa-add-to-cart checkmark-active'>
				<path
					fill='none'
					strokeWidth='2'
					strokeLinecap='square'
					strokeMiterlimit='10'
					d='M9,17l3.9,3.9c0.1,0.1,0.2,0.1,0.3,0L23,11'
					style={{
						strokeDashArray: '19.79 19.79',
						strokeDashOffset: '19.79',
						stroke: '#ffffff',
						opacity: visible ? 1 : 0,
						animation: visible ? 'drawCheckmark 0.5s linear alternate forwards' : ''
					}}></path>
			</svg>
			<style
				dangerouslySetInnerHTML={{
					__html: `
@keyframes drawCheckmark {
        from {
        stroke - dashoffset: 19.79;
      opacity: 0;
  }
      to {
        stroke - dashoffset: 0;
      opacity: 1;
  }
}`
				}}
			/>
		</div>
	);
}
