export const splitRowsIntoBatches = (rows, batchSize = 200, loggingEnabled = true) => {
  // Will contain batches of N elements
  const arrays = [];
  const batchCount = Math.ceil(rows?.length / batchSize);

  // Iterate with step = N, create batches
  for (let i = 0; i < batchCount; i++) {
    const from = i * batchSize;
    const to = (i + 1) * batchSize;
    const batch = rows.slice(from, to);
    arrays.push(batch);
  }

  let maxSize = Math.min(batchSize, arrays?.[0]?.length);
  if (isNaN(maxSize)) {
    console.log(' \n [splitRowsIntoBatches]: No transactions to process.');
    return arrays;
  }
  if (loggingEnabled) {
    console.log(`\n > Created ${arrays?.length} batches of ${maxSize} elements each.\n`);
  }
  return arrays;
};

export const customSortFSXProducts = (products) => {
  if (!products) return [];
  const skuOrder = ['FSX65-4', 'FSX65-8', 'FSX8-4', 'FSX8-8', 'FSX10-4', 'FSX10-8'];
  products = products?.sort((a, b) => {
    const indexA = skuOrder.indexOf(a.sku);
    const indexB = skuOrder.indexOf(b.sku);

    if (indexA < indexB) return -1;
    if (indexA > indexB) return 1;
    return 0;
  });
  return products;
};

export const sortVariantOptions = (options) => {
  if (!options) return;

  const extractNumber = (str) => {
    const match = str?.match(/(\d+)/);
    return match ? parseInt(match[1]) : 0;
  };

  // Helper function to extract category from the string
  const extractCategory = (str) => {
    const match = str?.match(/(single|dual|triple)/);
    return match ? match[0] : '';
  };

  const order = ['single', 'dual', 'triple'];

  return options.sort((a, b) => {
    // Special case for "Ported" vs "Ported (Ultra SPL)"
    if (a.includes('Ported') && b.includes('Ported (Ultra SPL)')) {
      return -1;
    }
    if (a.includes('Ported (Ultra SPL)') && b.includes('Ported')) {
      return 1;
    }

    const categoryA = extractCategory(a);
    const categoryB = extractCategory(b);
    const numA = extractNumber(a);
    const numB = extractNumber(b);

    const categoryOrder = order.indexOf(categoryA) - order.indexOf(categoryB);

    if (categoryOrder !== 0) {
      return categoryOrder;
    }

    return numA - numB;
  });
};


export const sortListOfSkusIntelligently = (variants) => {
  // console.log('[sortListOfSkusIntelligently]: variants', variants);
  if (!variants?.length) return;

  return variants?.sort((a, b) => {
    const parseSku = (sku) => {
      try {
        const matchCommon = sku.match(/^([A-Za-z]+-?)(\d+)([A-Za-z\d]*?)\sD(\d+)$/);
        if (matchCommon) {
          return {
            type: 'common',
            prefix: matchCommon[1],
            size: parseInt(matchCommon[2], 10),
            version: matchCommon[3],
            impedance: parseInt(matchCommon[4], 10)
          };
        }

        const matchBattery = sku.match(/^(SK-BATT)(\d+)(AH)$/);
        if (matchBattery) {
          return {
            type: 'battery',
            prefix: matchBattery[1],
            capacity: parseInt(matchBattery[2], 10),
            unit: matchBattery[3]
          };
        }

        const matchBundle = sku.match(/^(BNDLE-[A-Z]+)-(\d+X\d+[A-Z]\d+)-(RP-\d+\.\d+D)-(SKAR\d+[A-Z]+-\w+)$/);
        if (matchBundle) {
          return {
            type: 'bundle',
            bundleType: matchBundle[1],
            configuration: matchBundle[2],
            amplifier: matchBundle[3],
            wireKit: matchBundle[4]
          };
        }

        const matchVehicle = sku.match(/^L-([A-Za-z]+-VS)-(\d+X\d+[A-Za-z]+?)-(\d{4}-UP|\d{4}-\d{4})-?([A-Za-z\-]*)$/);
        if (matchVehicle) {
          return {
            type: 'vehicle',
            series: matchVehicle[1],
            configuration: matchVehicle[2],
            yearRange: matchVehicle[3],
            details: matchVehicle[4]
          };
        }

        const matchOther = sku.match(/^([A-Za-z\d\-]+)$/);
        if (matchOther) {
          return {
            type: 'other',
            sku: matchOther[1]
          };
        }

        return {type: 'unknown', sku: sku};
      } catch (error) {
        console.error(`Error parsing SKU: ${sku}`, error);
        return {type: 'unknown', sku: sku};
      }
    };

    const skuA = parseSku(a.sku);
    const skuB = parseSku(b.sku);

    if (skuA?.type !== skuB?.type) {
      return skuA.type.localeCompare(skuB.type);
    }

    if (skuA?.type === 'common' && skuB?.type === 'common') {
      if (skuA?.prefix !== skuB?.prefix) {
        return skuA?.prefix?.localeCompare(skuB?.prefix);
      }
      if (skuA?.size !== skuB?.size) {
        return skuA?.size - skuB?.size;
      }
      if (skuA.version !== skuB.version) {
        return skuA.version.localeCompare(skuB.version);
      }
      return skuA.impedance - skuB.impedance;
    }

    if (skuA.type === 'battery' && skuB.type === 'battery') {
      return skuA.capacity - skuB.capacity;
    }

    if (skuA.type === 'bundle' && skuB.type === 'bundle') {
      if (skuA.bundleType !== skuB.bundleType) {
        return skuA.bundleType.localeCompare(skuB.bundleType);
      }
      if (skuA.configuration !== skuB.configuration) {
        return skuA.configuration.localeCompare(skuB.configuration);
      }
      if (skuA.amplifier !== skuB.amplifier) {
        return skuA.amplifier.localeCompare(skuB.amplifier);
      }
      return skuA.wireKit.localeCompare(skuB.wireKit);
    }

    if (skuA.type === 'vehicle' && skuB.type === 'vehicle') {
      if (skuA.series !== skuB.series) {
        return skuA.series.localeCompare(skuB.series);
      }
      if (skuA.configuration !== skuB.configuration) {
        return skuA.configuration.localeCompare(skuB.configuration);
      }
      if (skuA.yearRange !== skuB.yearRange) {
        return skuA.yearRange.localeCompare(skuB.yearRange);
      }
      return skuA.details.localeCompare(skuB.details);
    }

    if (skuA.type === 'other' && skuB.type === 'other') {
      return skuA.sku.localeCompare(skuB.sku);
    }

    return 0;
  });
};

export const customSortEnclosuresBySingleDualTriple = (data) => {
  if (!data) return [];
  // console.log('[customSortEnclosuresBySingleDualTriple]: data', data);
  // Filter function to check if a row is eligible for sorting
  const isEligibleForSorting = (row) => {
    const name = row?.[0];
    return name && (name?.includes('Single') || name?.includes('Dual') || name?.includes('Quad') || name?.includes('Triple'));
  };

  // Check if any data are eligible for sorting
  const hasEligibleRows = data.some(isEligibleForSorting);

  // If no data are eligible, return the original array
  if (!hasEligibleRows) {
    return data;
  }

  return data.sort((a, b) => {
    const getPrefix = (name) => {
      if (name?.includes('Single')) return 1;
      if (name?.includes('Dual')) return 2;
      if (name?.includes('Triple')) return 3;
      if (name?.includes('Quad')) return 4;
      return 4; // For any other case
    };

    const getSize = (name) => {
      try {
        const match = name?.match(/(\d+(?:\.\d+)?)/);
        return match ? parseFloat(match[0]) : 0;
      } catch (e) {
        console.log('[customSortEnclosuresBySingleDualTriple]: Error parsing size', e);
        return 0;
      }
    };

    const prefixA = getPrefix(a?.[0]);
    const prefixB = getPrefix(b?.[0]);

    if (prefixA !== prefixB) {
      return prefixA - prefixB;
    }

    const sizeA = getSize(a?.[0]);
    const sizeB = getSize(b?.[0]);

    return sizeA - sizeB;
  });
};

export const customSortByEmptyThenLoaded = (data) => {
  if (!data) return [];
  // Filter function to check if a row is eligible for sorting
  const isEligibleForSorting = (name) => {
    return name && (name?.includes('Empty') || name?.includes('Loaded'));
  };

  // Check if any data are eligible for sorting
  const hasEligibleRows = data?.some(isEligibleForSorting);

  // If no data are eligible, return the original array
  if (!hasEligibleRows) {
    return data;
  }

  return data.sort((a, b) => {
    const getPrefix = (name) => {
      if (name === 'Empty') return 1;
      if (name === 'Loaded') return 2;
      return 3; // For any other case
    };

    const prefixA = getPrefix(a);
    const prefixB = getPrefix(b);

    if (prefixA !== prefixB) {
      return prefixA - prefixB;
    }
    // If prefixes are the same, maintain original order
    return 0;
  });
};

export function arraySum(list = [], selector) {
  return list.reduce((a, x) => a + selector(x), 0);
}

export function arrayMin(items, selector) {
  return items.reduce((a, x) => (a ? Math.min(a, selector(x)) : selector(x)), null);
}

export function arrayMax(items, selector) {
  return items.reduce((a, x) => (a ? Math.max(a, selector(x)) : selector(x)), null);
}


export function arraySort(items, selector) {
  items = items?.sort(sortBy(selector));
  return items;
}


export function arraySortBToA(items, selector) {
  if (!items?.length) return;
  items = items?.sort(sortBy(selector));
  items = items?.reverse();
  return items;
}

export const arraySortByTwo = (items, selector1, selector2, options = {
  numeric: true,
  reverse: false
}) => {
  items = items.sort((a, b) => {
    let a1 = selector1(a);
    let b1 = selector1(b);
    if (a1 !== b1) {
      return String(a1).localeCompare(String(b1), undefined, options);
    }
    let a2 = selector2(a);
    let b2 = selector2(b);
    return String(a2).localeCompare(String(b2), undefined, options);
  });

  if (options?.reverse) {
    items = items.reverse();
  }
  return items;
};

export function sortBy(selector) {
  return (a, b) => {
    a = selector(a);
    b = selector(b);
    if (typeof a === 'number' && typeof b === 'number') {
      return a - b;
    }
    if (a instanceof Date && b instanceof Date) {
      return a - b;
    }
    a = String(a);
    b = String(b);
    return a.localeCompare(b);
  };
}

export function arrayGroupBySelector(arr, selector, groupByKey = 'groupKey') {
  const grouped = arr.reduce((acc, item) => {
    const key = selector(item);
    if (!acc[key]) {
      acc[key] = [];
    }
    // we add the index based on the current length of the array, +1 because indexing starts from 1 in your case
    acc[key].push({index: acc[key].length + 1, ...item});
    return acc;
  }, {});

  return Object.entries(grouped).map(([key, value]) => ({
    [groupByKey]: key,
    items: value
  }));
}


export function appendObjectOrReplace(objList = [], appendItem, selector) {
  let newKey = selector(appendItem);
  let keys = [...new Set([...objList.map(selector), newKey])];
  return keys.map((key) => {
    return key === newKey ? appendItem : objList.find((x) => selector(x) === key);
  });
}

export function arraysOverlap(arr1, arr2) {
  // console.log({arraysOverlap: arr1})
  return arr1.some((x) => arr2?.includes(x));
}

export const findDuplicatesItemsOfArray = (arr) => {
  let sorted_arr = arr?.slice().sort(); // You can define the comparing function here.
  let results = [];
  for (let i = 0; i < sorted_arr?.length - 1; i++) {
    if (sorted_arr[i + 1] === sorted_arr?.[i]) {
      results?.push(sorted_arr?.[i]);
    }
  }
  return results;
};


export function arrayUnique(array, selector) {
  if (!selector) {
    throw new Error('Must provide Selector. Fix Provided Params of arrayUnique');
  }
  const uniqSet = [...new Set(array.map(selector))];
  return uniqSet.map(uniqItem => {
    return array.find(x => selector(x) === uniqItem);
  });
}
