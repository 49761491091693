import StrikeThroughPrice from '@imageTheme/mobile/BlackFriday2023/BF2023-X.webp';
import ComingSoonIcon from '@image/icons/png-icons/ComingSoonIcon.png';
import Image from 'next/image';
import Link from 'next/link';
import {CART_BUTTON_PRIMARY_CLASSES} from '@components/cart/CartButtonClasses.jsx';
import {priceHelper, returnHighestAndLowestPriceValues} from '@helpers/config-master.mjs';
import cn from 'clsx';
import {niceMoney} from '@helpers/common/number.mjs';
import {toHandleFormat} from '@helpers/common/string.mjs';
import {ProductProvider} from '@components/product/ProductProvider.jsx';
import {AddToCartButton} from '@components/product/global/AddToCart.jsx';
import {HStack} from '@components/widgets/Common';
import {InStockText} from '@components/dealer/dealer-product-card';
import {Debug} from '@components/widgets/Debug';
import {useSession} from 'next-auth/react';
import {useState} from 'react';
import {ReviewStarsFromDb} from '@components/product/reviews/review-stars-from-db';

const placeholderImg = priceHelper.placeholder.desktop.product;

export const CollectionProdPageMobileProdCard = ({
                                                   product,
                                                   mobileProductImageSaleOverlay,
                                                   largeThumbnails = false
                                                 }) => {
  // console.log('[CollectionProdPageMobileProdCard][Product]:', product);
  const [activeVariant, setActiveVariant] = useState(product?.childSkus?.[0]?.sku);
  const [imageSrc, setImageSrc] = useState(product?.imageUrl ?? product?.images?.[0]?.url ?? product?.image_1 ?? placeholderImg);
  const {data: session} = useSession();

  const isLoggedIn = session && !!session?.user?.email ? true : false;
  const isDealer = isLoggedIn && session?.user?.role === 2;

  const childSkus = product?.childSkus;

  const isParentSku = product?.isParentSku ? true : false;

  const reviewRating = product?.reviewRating ?? product?.rating;
  const [highPrice, lowPrice] = returnHighestAndLowestPriceValues(product, {isDealer});

  if (product == null) return null;

  const productTitle = product?.name ?? product?.site_name;
  const productSku = product?.sku;
  const isSale = priceHelper.saleEnabled;
  const isInStock = product?.currentlyInStock === true || product?.isInStock === true;

  // if (product?.sku === 'RP-150.4ABM') {
  //   console.log('[CollectionProdPageMobileProdCard][product]:', product);
  // }

  const preorderInfo = product?.preorderInfo ?? product?.pre_order_info;
  const isPreorderEligible = preorderInfo?.is_pre_order_eligible === true;
  const isPreOrderEligibleAndActive = !isInStock && isPreorderEligible;

  const productSlug = product?.slug ?? product?.site_handle;
  let href = product?.slug?.includes('/products') ? productSlug : `/products/${productSlug}`;
  href = href ? href.replaceAll('//', '/') : null;

  // console.log("[CollectionProdPageMobileProdCard][siteHandle]:", product?.site_handle)
  const variantNames = product?.variantNames;

  if (isParentSku) {
    const handleChangeVariant = (childSku) => {
      const newImage = childSku?.image;
      setImageSrc(newImage);
      setActiveVariant(childSku?.sku);
    };

    let href = product?.slug?.includes('/products') ? productSlug : `/products/${productSlug}`;
    href = href ? href.replaceAll('//', '/') : null;

    return (
      <div className="relative overflow-hidden shadow-md bg-white">
        <div className="flex gap-4 items-start h-full pr-2">
          {imageSrc && (
            <Link href={href} className="relative w-4/12 mt-2">
              <div>
                <Image
                  quality="85"
                  src={imageSrc}
                  alt={productTitle || 'Product Image'}
                  height={250}
                  width={250}
                  loading="eager"
                />

                {isSale && (
                  <ProductSaleOverlayMobile
                    mobileProductImageSaleOverlay={mobileProductImageSaleOverlay}
                  />
                )}

                {imageSrc === placeholderImg && (
                  <div className="absolute p-8">
                    <Image src={ComingSoonIcon} alt="Image coming soon" />
                  </div>
                )}
              </div>
            </Link>
          )}

          <div className="py-1 w-8/12">
            {productTitle && (
              <div className="mb-2 font-oswald">
                <Link href={href}>{productTitle}</Link>
              </div>
            )}

            <div className="font-oswald">
              <>
                {reviewRating && (
                  <ReviewStarsFromDb
                    avgScore={reviewRating?.avg_rating}
                    count={reviewRating?.count}
                    size={12}
                    small
                  />
                )}

                <InStockText
                  inStockTogether={isInStock}
                  className="text-xs mt-1"
                  isPreorder={isPreOrderEligibleAndActive}
                />
              </>
            </div>

            {childSkus && (
              <div className={'mt-2 mb-2 flex flex-wrap gap-2'}>
                {childSkus?.map((childSku, index) => {
                  const isSelected = activeVariant === childSku?.sku;
                  return (
                    <div
                      key={`${childSku?.sku}-${index}`}
                      onClick={() => handleChangeVariant(childSku)}
                      className={cn({
                        'border-2 text-sm px-2 py-1 hover:bg-gray-100 cursor-pointer': true,
                        'border-neutral-400 bg-neutral-200 hover:bg-neutral-200': isSelected
                      })}>
                      {childSku?.variant_option_one_value}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="relative overflow-hidden shadow-md bg-white">
      <div className="flex gap-2 items-start h-full pr-2">
        {imageSrc && (
          <Link
            href={href}
            className={`relative overflow-hidden ${largeThumbnails ? 'w-5/12' : 'w-4/12'}  mt-2`}>
            <Image
              quality="85"
              src={imageSrc}
              alt={productTitle || 'Product Image'}
              height={250}
              width={250}
              loading="eager"
              className={`img-sku-${toHandleFormat(productSku)}`}
            />

            {isSale && (
              <ProductSaleOverlayMobile
                mobileProductImageSaleOverlay={mobileProductImageSaleOverlay}
              />
            )}

            {imageSrc === placeholderImg && (
              <div className="absolute p-8">
                <Image src={ComingSoonIcon} alt="Image coming soon" />
              </div>
            )}
          </Link>
        )}

        <div className={`py-1  ${largeThumbnails ? 'w-7/12' : 'w-8/12'}`}>
          <div className="mb-2 font-oswald">
            <Link href={href}>
              {productTitle || (productSku && <MobileProductCardTitle product={product} />)}
            </Link>
          </div>

          <div className="flex justify-between items-start font-oswald">
            <div>
              {reviewRating && (
                <ReviewStarsFromDb
                  avgScore={reviewRating?.avg_rating}
                  count={reviewRating?.count}
                  size={12}
                  small
                />
              )}

              <InStockText
                inStockTogether={isInStock}
                className="text-xs mt-1"
                isPreorder={isPreOrderEligibleAndActive}
              />
            </div>

            <div className="text-[14px] flex flex-col items-end justify-end text-right">
              <div className="tracking-wider text-xs relative">
                {isDealer ? 'MAP:' : isSale ? 'MAP' : 'MSRP:'}
                {niceMoney(highPrice)}

                <div
                  className={
                    'absolute top-0 right-0 w-full h-full flex items-center justify-start'
                  }>
                  <div className={'opacity-80'}>
                    <Image src={StrikeThroughPrice} alt="Strike Through Price" />
                  </div>
                </div>
              </div>

              {isInStock && (
                <div
                  className={`align-end text-base`}
                  style={{
                    color: 'rgba(4,43,138, 1)',
                    fontWeight: '500'
                  }}>
                  <span>{isDealer ? 'Dealer:' : isSale ? 'Sale Price:' : 'Price'}</span>{' '}
                  {niceMoney(lowPrice)}
                </div>
              )}

              {!isInStock && isPreOrderEligibleAndActive && (
                <div
                  className={`align-end text-base whitespace-pre`}
                  style={{
                    color: 'rgba(4,43,138, 1)',
                    fontWeight: '500'
                  }}>
                  <span>{isDealer ? 'Dealer:' : isSale ? 'Sale Price:' : 'Price'}</span>{' '}
                  {niceMoney(lowPrice)}
                </div>
              )}
            </div>
          </div>

          <HStack start gap={1} className={'items-end justify-between'}>
            <div className="flex flex-col gap-1 items-start justify-start">
              {variantNames ? (
                <>
                  <strong className={'text-xs'}>{'Configurations:'}</strong>
                  <span className={'flex gap-1'}>
                    {variantNames?.map((name) => (
                      <ProductVariantNameMobile key={`variants-${name}`} name={name} />
                    ))}
                  </span>
                </>
              ) : null}
            </div>

            <CollectionProductsPageProductCardAddToCartSection product={product} />
          </HStack>
        </div>
      </div>
    </div>
  );
};

export const CollectionProductsPageProductCardAddToCartSection = ({product}) => {
  // console.log('[ProductCardMobile][CollectionProductsPageProductCardAddToCartSection][product]:', {product});
  const isInStock = product?.currentlyInStock === true || product?.isInStock === true;
  const preorderInfo = product?.preorderInfo ?? product?.pre_order_info;
  const isPreorderEligible = preorderInfo?.is_pre_order_eligible === true;
  const isPreOrderEligibleAndActive = !isInStock && isPreorderEligible;
  // console.log('[CollectionProductsPageProductCardAddToCartSection][isInStock]:', isInStock);
  // console.log('[CollectionProductsPageProductCardAddToCartSection][isPreorderEligible]:', isPreOrderEligibleAndActive);

  const productSlug = product?.slug ?? product?.site_handle;
  let href = product?.slug?.includes('/products') ? productSlug : `/products/${productSlug}`;
  href = href ? href.replaceAll('//', '/') : null;

  const selectedVariant = product?.selectedVariant ?? product;

  if (product?.product_type === 'variant') {
    return (
      <Link href={href}>
        <HStack className="mt-1">
          <div className="self-stretch" />
          <div>
            <div
              className={cn(
                'block m-0 w-full items-center justify-center uppercase',
                'font-medium text-center px-5 py-1 rounded',
                'disabled:border-gray-300 disabled:bg-gray-300',
                'disabled:cursor-not-allowed',
                'text-white bg-gray-900 hover:bg-gray-800 active:bg-gray-700'
              )}>
              More details
            </div>
          </div>
        </HStack>
      </Link>
    );
  }

  return (
    <ProductProvider data={product}>
      <div className="flex justify-end">
        {isInStock && (
          <div className="max-w-[130px] text-xs">
            <AddToCartButton
              selectedVariant={selectedVariant}
              className={CART_BUTTON_PRIMARY_CLASSES}
              disabled={!isInStock}
              spinnerSize={16}
              addToCartButtonSource={'mobile-collection-button'}
              icon={'🛒'}>
              Add to Cart
            </AddToCartButton>
          </div>
        )}
        {!isInStock && (
          <div>
            {isPreOrderEligibleAndActive && (
              <div className="max-w-[10em]">
                <AddToCartButton
                  selectedVariant={selectedVariant}
                  className={cn(
                    'block m-0 w-full items-center justify-center uppercase',
                    'font-medium text-center px-6 py-2 rounded text-xs',
                    'disabled:border-gray-300 disabled:bg-gray-300',
                    'disabled:cursor-not-allowed bg-sky-700 text-white',
                    'hover:bg-gray-800 active:bg-gray-700'
                  )}
                  addToCartButtonSource={'mobile-collection-pre-order-button'}
                  spinnerSize={16}>
                  🛒 Pre-order
                </AddToCartButton>
              </div>
            )}

            {!isPreOrderEligibleAndActive && (
              <div
                className="block m-0 w-full items-center justify-center uppercase font-medium text-center px-6 py-2 rounded text-xs text-black bg-gray-300">
                Out of Stock
              </div>
            )}
          </div>
        )}
      </div>
    </ProductProvider>
  );
};

const MobileProductCardTitle = ({product}) => {
  // console.log('[ProductTile][product]:', {product});
  const sku = product?.sku;
  let name = product?.name ?? product?.site_name;

  let collectionPageSku = product?.collectionPageSku ?? product?.variant_parent_sku;

  const productTypeIsVariant = product?.product_type === 'variant';
  const productTypeIsNotVariant = !productTypeIsVariant;
  // console.log({productTypeIsVariant})

  const nameContainsPipe = name?.includes('|');
  if (productTypeIsVariant) {
    const nameContainsPipe = name?.includes('|');
    name = nameContainsPipe ? name?.split(' | ')?.[1] : name;
  }

  const hideVariantSkuForCatTypes = ['subwoofers', 'speakers', 'marine-subwoofers'];

  const isSpeakerWireSku = sku.startsWith('SKSPK') ? true : false;
  const isMarineSubwooferSku = sku?.startsWith('SKM') ? true : false;

  const doesNotMatchAnySpecialRules =
    !nameContainsPipe && !hideVariantSkuForCatTypes && !isSpeakerWireSku;
  const pipeTitleSecondHalf = nameContainsPipe ? name?.split(' | ')?.[1] : null;

  if (collectionPageSku) {
    if (isSpeakerWireSku) {
      const sizesAvailable = sku.includes('10GA') ? '30 FT' : '30 FT | 100 FT';
      return (
        <div className="pt-1 -mb-1">
          <span className="collection-page-title-text text-black">{name}</span>
          <span className="text-[12.5px]">
            <span style={{fontWeight: 'bold'}}>{'Available Sizes: '}</span>
            <span style={{fontWeight: 'normal'}}>{sizesAvailable}</span>
          </span>
        </div>
      );
    }

    if (isMarineSubwooferSku) {
      return (
        <>
          <div className={`mt-0.5 pt-1 text-gray-700 text-xs`}>
            <strong>SKU</strong>: {sku}
          </div>
          <div className="pt-1 -mb-1">
            <span className="collection-page-title-text text-black">{name}</span>
          </div>
        </>
      );
    }

    // otherwise all speaker/subwoofer, etc. type variants
    return (
      <div className="pt-1 text-black -mb-1">
        <h2 className="collection-page-title-text text-xl">{name}</h2>
        <p className="text-[15px] overflow-hidden">{pipeTitleSecondHalf}</p>
      </div>
    );
  }

  if (productTypeIsNotVariant) {
    const nonVariantTitle = pipeTitleSecondHalf ?? name;
    return (
      <div className="pt-1 -mb-1">
        <strong className="text-[12px]">SKU</strong>:
        <span className="text-[12px]">&nbsp;{sku}</span>
        <p className="text-[15px] overflow-hidden">{nonVariantTitle}</p>
      </div>
    );
  }

  if (!doesNotMatchAnySpecialRules) {
    return (
      <>
        <Debug show={isDebugging}>
          <div style={{color: 'blue'}}>{'!doesNotMatchAnySpecialRules'}</div>
        </Debug>
        <h2 className="collection-page-title-text">{name}</h2>
      </>
    );
  }

  return (
    <>
      <Debug show={false}>
        <div style={{color: 'blue'}}>{'doesNotMatchAnySpecialRules'}</div>
      </Debug>
      <h2 className="collection-page-title-text text-xl">{productTypeIsVariant}</h2>
      <p className="text-[14px] overflow-hidden">{pipeTitleSecondHalf}</p>
    </>
  );
};

const ProductVariantNameMobile = ({name}) => {
  return <span className={'px-1 py-1 border text-[10.5px]'}>{name}</span>;
};

const ProductSaleOverlayMobile = ({mobileProductImageSaleOverlay}) => {
  return (
    <>
      <div className="absolute inset-0 pointer-events-none text-white overflow-hidden">
        {mobileProductImageSaleOverlay && (
          <Image
            src={mobileProductImageSaleOverlay}
            alt="Discount"
            width={400}
            height={400}
            priority
            style={{objectFit: 'fill'}}
          />
        )}
      </div>
    </>
  );
};
