import PaymentMethodLogos from '@image/site/payment-methods-new.png';
import Image from 'next/image';
import Link from 'next/link';
import {FooterMobile} from './FooterMobile';
import {MdOutlineEmail, MdPhone} from '@assets/react-icons/md';
import {FooterSocialMediaIcons} from '@components/footer/FooterSocialMediaIcons';

export default function Footer() {
  const infoItems = [
    {
      title: 'Reviews',
      path: '/pages/reviews'
    },
    {
      title: 'About Us | Team',
      path: '/pages/about-us'
    },
    {
      title: 'Careers',
      path: '/jobs'
    },
    {
      title: 'Privacy Policy',
      path: '/pages/privacy-policy'
    },
    {
      title: 'Terms & Conditions',
      path: '/pages/terms-and-conditions'
    },
    {
      title: 'Become a Dealer',
      path: '/pages/become-a-dealer'
    },
    {
      title: 'Dealer Locator',
      path: '/pages/dealer-locator'
    },
    {
      title: 'Dealer Portal',
      path: '/dealers'
    }
  ];

  const helpItems = [
    {
      title: 'Help Center',
      path: 'https://help.skaraudio.com/'
    },
    {
      title: 'Contact Us',
      path: '/pages/contact-us'
    },
    {
      title: 'My Account',
      path: '/account'
    },
    {
      title: 'Return My Order',
      path: '/returns'
    },
    {
      title: 'Return Policy',
      path: '/pages/return-policy'
    }
  ];

  const knowledgeItems = [
    {
      title: "FAQ's",
      path: '/pages/frequently-asked-questions'
    },
    {
      title: 'Warranty',
      path: '/pages/warranty-policy'
    },
    {
      title: 'Sub Box Designer',
      path: '/box-design-calculator'
    },
    {
      title: 'Subwoofer Wiring Diagrams',
      path: '/subwoofer-wiring-diagrams'
    },
    {
      title: 'Product Videos',
      path: 'https://www.youtube.com/user/skaraudio'
    },
    {
      title: 'Financing with Affirm',
      path: '/pages/financing'
    },
    {
      title: 'Prop 65 Warning',
      path: '/pages/california-prop65-warning'
    }
  ];

  const desktopMenus = [
    {
      title: 'COMPANY INFORMATION',
      items: infoItems
    },
    {
      title: 'KNOWLEDGE BASE',
      items: knowledgeItems
    }
  ];

  const mobileMenus = [
    {
      title: 'LET US HELP',
      items: [...helpItems]
    },
    {
      title: 'COMPANY INFORMATION',
      items: infoItems
    },
    {
      title: 'KNOWLEDGE BASE',
      items: knowledgeItems
    }
  ];

  return (
    <footer role='contentinfo'>
      <div className='px-4 py-4 text-white  bg-gray-900'>
        <div className='hidden md:block mt-6'>
          <div className='mx-auto max-w-7xl grid grid-cols-12 underline-offset-2'>
            <div className='col-span-4 space-y-4'>
              <div className='flex items-center'>
                <FooterSocialMediaIcons />
              </div>

              <div className='max-w-[340px]'>
                <Image
                  src={PaymentMethodLogos}
                  alt={
                    'We accept American Express, Diners Club, Discover, Mastercard, Visa, Paypal, Amazon Pay, Apple Pay and Google Pay'
                  }
                  quality={90}
                  sizes={'480px'}
                />
              </div>

              <p className='text-white text-xs leading-5 tracking-wide pr-14'>
                All trademarks, including names, makes, models, and symbols of vehicle manufacturers
                mentioned on this site, belong to their respective owners. These trademarks are
                utilized solely to identify specific vehicle makes and models compatible with the
                products offered on this site. Skar Audio is not associated with, affiliated with,
                nor endorsed by any of these vehicle manufacturers.
              </p>
            </div>

            {desktopMenus?.map((menu, index) => (
              <div className={`col-span-2 font-oswald tracking-wider`} key={menu?.title}>
                <h2 className='text-lg uppercase mb-4'>{menu?.title}</h2>
                <div className='space-y-1.5'>
                  {menu?.items?.map((item) => (
                    <div key={item?.path}>
                      <Link href={item?.path} className={'hover:underline block'}>
                        {item?.title}
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            ))}

            <div className={'col-span-1'}></div>

            <div className='col-span-3 space-y-1.5'>
              <h2 className='text-lg font-oswald uppercase mb-4'>Let Us Help</h2>

              <Link
                href={'/pages/contact-us?screen=contact-form'}
                className='flex items-center gap-4 font-oswald hover:underline'>
                <div>
                  <MdOutlineEmail size={'24'} />
                </div>
                <div>Send Us An Email</div>
              </Link>
              <a href='tel:888-501-7527' className='flex gap-4 font-oswald hover:underline'>
                <div>
                  <MdPhone size={'24'} />
                </div>
                <div className={'flex gap-2'}>
                  <div>Call Us</div>
                  <div>(888) 501-7527</div>
                </div>
              </a>

              {helpItems?.map((item) => {
                return (
                  <div key={item?.path} className={'font-oswald block hover:underline'}>
                    <Link href={item?.path}>{item?.title}</Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className='md:hidden'>
          <FooterMobile menus={mobileMenus} />
        </div>
      </div>
    </footer>
  );
}
