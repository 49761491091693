import 'yet-another-react-lightbox/styles.css';
import Image from 'next/image';
import DelayComponentVisible from '@components/widgets/DelayComponentVisible';
import cn from 'clsx';
import Lightbox, {createModule, useController, useLightboxState} from 'yet-another-react-lightbox';
import {
  ReviewHeader,
  ReviewPagination,
  ReviewTextWithShowMoreButton
} from '@components/product/reviews/common/ui';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {ReviewThumbsUp} from '@components/product/reviews/thumbs-up';
import {Spinner} from '@components/widgets/Common';
import {InfiniteScroll} from '@components/widgets/InfiniteScroll';
import {ChevronLeft} from '@components/widgets/Icons';

export const ReviewsLightboxCarouselMobile = ({
                                                show,
                                                close,
                                                reviewId,
                                                initialImageIndex = 0,
                                                allReviews,
                                                handleLoadMore,
                                                showAll,
                                                setShowAll,
                                                withPhotoGrid,
                                                isReachingEnd
                                              }) => {
  const justReviewsWithImages = useMemo(() => {
    return allReviews?.filter((review) => review?.images?.length > 0)?.flatMap((review) => {
        return review?.images?.map((image, index) => ({
          ...review,
          image,
          imageIndex: index,
          imageCount: review?.images?.length
        }));
      }
    );
  }, [allReviews]);

  const initialReviewIndex = useMemo(() => {
    return justReviewsWithImages.findIndex(
      (review) => review?.id === reviewId && review?.imageIndex === initialImageIndex
    );
  }, [justReviewsWithImages, reviewId, initialImageIndex]);

  const [currentReviewIndex, setCurrentReviewIndex] = useState(initialReviewIndex ?? 0);

  const shouldShowModal = useMemo(() => {
    return showAll || (show && currentReviewIndex !== null && currentReviewIndex > -1);
  }, [show, showAll, currentReviewIndex]);

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const handleSlideRender = useCallback(
    ({slide}) => <ReviewIndividualSlideImage review={slide} />,
    []
  );

  const handleOnView = useCallback(
    ({index}) => {
      setCurrentReviewIndex((prevIndex) => {
        if (prevIndex !== index) {
          return index;
        }
        return prevIndex;
      });

      const totalReviews = justReviewsWithImages.length;
      const distanceFromEnd = totalReviews - index;
      const threshold = 4;

      if (distanceFromEnd < threshold && !isReachingEnd) {
        handleLoadMore();
      }
    },
    [handleLoadMore, justReviewsWithImages.length]
  );

  return (
    <>
      <Lightbox
        open={shouldShowModal}
        close={handleClose}
        index={currentReviewIndex}
        slides={justReviewsWithImages}
        plugins={[
          ReviewCarouselContentPlugin,
          ...(withPhotoGrid ? [ReviewCarouselPhotoGridPlugin] : [])
        ]}
        on={{
          view: handleOnView
        }}
        controller={{
          showAll,
          setShowAll,
          handleLoadMore,
          setCurrentReviewIndex,
          withPhotoGrid
        }}
        render={{
          slide: handleSlideRender
        }}
        carousel={{
          preload: 3,
          padding: 0,
          finite: true
        }}
        {...(withPhotoGrid ? {toolbar: {buttons: []}} : {})}
      />
    </>
  );
};

const ReviewIndividualSlideImage = ({review}) => {
  const [loading, setLoading] = useState(true);

  const imageSrc = review.image;

  return (
    <>
      {loading && (
        <div className="absolute top-0 left-0 z-20  flex items-center justify-center w-full h-full">
          <DelayComponentVisible delay={400}>
            <Spinner color="text-white" size="48" />
          </DelayComponentVisible>
        </div>
      )}

      <div className="relative h-full w-full">
        <Image
          src={imageSrc}
          alt={review?.body ?? 'photo review'}
          className="object-contain object-center snap-center w-full h-auto"
          fill
          sizes="640px"
          priority
          onLoad={(img) => setLoading(false)}
        />
      </div>
    </>
  );
};

const ReviewLightboxCarouselContent = ({children, ...props}) => {
  // console.log('ReviewLightboxCarouselContent', {props});
  const [showMoreReviewText, setShowMoreReviewText] = useState(false);
  const [showOnlyReviewImages, setShowOnlyReviewImages] = useState(false);
  const {currentSlide: review} = useLightboxState();
  const controller = props?.controller;
  const withPhotoGrid = controller?.withPhotoGrid;

  useEffect(() => {
    if (review?.id) {
      setShowOnlyReviewImages(false);
      setShowMoreReviewText(false);
    }
  }, [review?.id]);

  const imageIndex = review?.imageIndex;
  const imageCount = review?.imageCount;

  const reviewText = review?.body;
  const thumbsUpCount = review?.thumbs_up || 0;

  const handleToggleReviewText = (e) => {
    e.stopPropagation();
    setShowOnlyReviewImages(!showOnlyReviewImages);
  };

  return (
    <>
      <div
        onClick={handleToggleReviewText}
        className={cn('absolute left-0 right-0 bottom-0 z-[100] w-full h-full p-4 text-white', {
          'fade-bg-black-to-transparent-mobile': !showMoreReviewText,
          'fade-bg-nearly-transparent-to-black-mobile': showMoreReviewText,
          'top-0': !withPhotoGrid,
          'top-12': withPhotoGrid
        })}>
        {/*<ReviewCloseButton close={handleClose} />*/}

        <div className="w-full pb-3 relative z-30 top-0">
          <ReviewPagination currentSlide={imageIndex} totalSlides={imageCount} />
        </div>

        <div
          className={`space-y-1 relative z-10 ${showOnlyReviewImages ? 'fade-out-mobile' : 'fade-in-opacity-mobile'}`}>
          <ReviewHeader review={review} showTitleWithReviewOnMobile={true} />

          <ReviewTextWithShowMoreButton
            reviewText={reviewText}
            setShowMoreReviewText={setShowMoreReviewText}
            showMoreReviewText={showMoreReviewText}
          />
        </div>

        <div className="pt-2 pb-8 text-black hidden md:block">
          <ReviewThumbsUp reviewId={review?.id} thumbsUp={thumbsUpCount} />
        </div>
      </div>

      {children}
    </>
  );
};

const ReviewCarouselContentPlugin = ({addModule}) => {
  addModule(reviewCarouselContentModule);
};

const reviewCarouselContentModule = createModule('ReviewContent', ReviewLightboxCarouselContent);

const ReviewLightboxCarouselPhotoGrid = ({children, ...props}) => {
  // console.log('ReviewLightboxCarouselPhotoGrid', {props});
  const {slides: reviews, currentSlide: review} = useLightboxState();
  const controller = props?.controller;
  const showAll = controller?.showAll;
  const setShowAll = controller?.setShowAll;
  const handleLoadMore = controller?.handleLoadMore;
  const setCurrentReviewIndex = controller?.setCurrentReviewIndex;

  const {close} = useController();

  const handleImageGridClick = (index) => {
    setCurrentReviewIndex(index);
    setShowAll(false);
  };

  return (
    <>
      <div className="absolute top-0 w-full z-[102] bg-black h-12">
        <div
          className="md:mb-4 pt-1 pl-2 pr-0 md:px-6 md:py-2 border-b flex items-center justify-between h-12">
          <div className="flex gap-4 items-center">
            {showAll && (
              <h3
                className="font-oswald text-white md:text-black text-lg md:text-2xl uppercase text-left">
                Review Photos
              </h3>
            )}
            {!showAll && (
              <button
                className="text-white md:text-black text-base underline underline-offset-2 cursor-pointer flex gap-0.5 translate-y-0.5"
                onClick={() => setShowAll(true)}
                aria-label="View All Photos">
                <ChevronLeft className="h-7 w-5" />
                View All Photos
              </button>
            )}
          </div>
          <div className="p-2 cursor-pointer text-white" onClick={close}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={'h-7 w-7'}
              viewBox="0 0 20 20"
              fill="currentColor">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </div>
      </div>

      {showAll && (
        <div
          className={cn(
            'absolute top-12 left-0 bottom-0 right-0 w-full h-full bg-black z-[102] overflow-y-scroll',
            {
              hidden: !showAll
            }
          )}>
          <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
            {reviews?.map((review, index) => {
              const isLastImage = index === reviews?.length - 1;

              return (
                <div
                  key={`review-image-${review?.id}-${review?.imageIndex}`}
                  className="relative aspect-square cursor-pointer bg-[#c4c4c4] bg-spinner rounded-md"
                  onClick={() => handleImageGridClick(index)}>
                  <Image
                    src={review?.image}
                    alt={'photo review'}
                    className="rounded-md object-cover object-center snap-center relative z-10"
                    onLoad={(e) => {
                      e.target?.parentElement?.classList?.remove('bg-spinner');
                    }}
                    fill
                    sizes="300px"
                  />

                  {isLastImage && <InfiniteScroll loadMore={handleLoadMore} />}
                </div>
              );
            })}
          </div>
        </div>
      )}
      {children}
    </>
  );
};

const ReviewCarouselPhotoGridPlugin = ({addModule}) => {
  addModule(reviewCarouselPhotoGridModule);
};

const reviewCarouselPhotoGridModule = createModule(
  'ReviewPhotoGrid',
  ReviewLightboxCarouselPhotoGrid
);

const ReviewCarouselHeader = () => {
  return (
    <div className="flex items-center gap-4 mb-2">
      <h3 className="font-oswald text-lg md:text-2xl uppercase text-left">Customer Photos</h3>
    </div>
  );
};
