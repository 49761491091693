export const CC_TYPE_AMEX = 'American Express'
export const CC_TYPE_VISA = 'Visa'
export const CC_TYPE_MASTERCARD = 'Mastercard'
export const CC_TYPE_DISCOVER = 'Discover'

export const detectCreditCardBrandV3 = (cardNumber) => {
  cardNumber = String(cardNumber)
  let cardBrand
  if (cardNumber?.startsWith('3')) {
    cardBrand = CC_TYPE_AMEX
  } else if (cardNumber?.startsWith('4')) {
    cardBrand = CC_TYPE_VISA
  } else if (cardNumber?.startsWith('5')) {
    cardBrand = CC_TYPE_MASTERCARD
  } else if (cardNumber?.startsWith('6')) {
    cardBrand = CC_TYPE_DISCOVER
  } else {
    cardBrand = null
  }
  return cardBrand
}
