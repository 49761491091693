import Image from 'next/image';
import {defaultThemeImagePaths, priceHelper} from '@helpers/config-master.mjs';

export const DesktopPreOrderOrSaleProductImageOverlay = ({
                                                           isInStock,
                                                           preOrderInfo,
                                                           isSourceCollectionPage,
                                                           desktopProductImageSaleOverlay
                                                         }) => {
  const saleIsEnabled = priceHelper.saleEnabled === true;

  if (isInStock) {
    return <DesktopSaleProductImageOverlay saleIsEnabled={saleIsEnabled}
                                           desktopProductImageSaleOverlay={desktopProductImageSaleOverlay}/>;
  }

  return <DesktopPreOrderProductImageOverlay
    isInStock={isInStock}
    preOrderInfo={preOrderInfo}
    isSourceCollectionPage={isSourceCollectionPage}
  />;
}

export const DesktopPreOrderProductImageOverlay = ({
                                                     isInStock,
                                                     preOrderInfo
                                                   }) => {
  // const preOrderImageOverlay = usePreorderImageOverlay(isSourceCollectionPage);
  const desktopProductImagePreOrderOverlay = defaultThemeImagePaths?.desktopProductImagePreOrderOverlay;
  const hasPreOrderInfo = preOrderInfo?.id;
  const isPreOrderEligible = preOrderInfo?.is_pre_order_eligible ?? false;

  if (isInStock || !hasPreOrderInfo) {
    return null
  }

  return <>
    {isPreOrderEligible && (
      <div className="absolute inset-0 pointer-events-none">
        {desktopProductImagePreOrderOverlay && (
          <Image
            src={desktopProductImagePreOrderOverlay}
            alt="Pre-order image overlay"
            style={{objectFit: 'contain', objectPosition: 'top right'}}
            priority
            width={400}
            height={400}
          />
        )}
      </div>
    )}
  </>
}

export const DesktopSaleProductImageOverlay = ({saleIsEnabled, desktopProductImageSaleOverlay}) => {
  return (
    <>
      {saleIsEnabled && (
        <div className='absolute inset-0 pointer-events-none'>
          {desktopProductImageSaleOverlay && (
            <Image
              src={desktopProductImageSaleOverlay}
              alt='Sale-Overlay-Image'
              style={{objectFit: 'fill'}}
              priority
              width={800}
              height={800}
            />
          )}
        </div>
      )}
    </>
  );
};
