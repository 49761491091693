// import '@assets/main.scss';
import '../assets/main.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ErrorBoundary from '../components/ErrorBoundary';
import {priceHelper} from '@helpers/config-master.mjs';
import localFont from 'next/font/local';
import CartSideBarUiProvider from '@components/cart/CartUiProvider';
import SeoRouter from '@components/seo/SeoRouter';
import Script from 'next/script';
import {Open_Sans} from 'next/font/google';
import {useEffect, useRef, useState} from 'react';
import {
  readCookie,
  sa_visitor_cookie,
  setCookie,
  setSessionCookie
} from '@components/widgets/Cookies';
import {GA4_CONFIG} from '@helpers/google/google-config.mjs';
import {ManagedUIContext} from '@components/widgets/UiProvider';
import {RegisterServiceWorker} from '@components/service-worker/register';
import {useRouter} from 'next/router';
import {GlobalJsonLD} from '@components/json-ld/organization';
import {
  setUserDataWithUserSessionForGTagEvent,
  TriggerTrackerPageViews
} from '@components/datalayer/global';
import {SessionProvider, useSession} from 'next-auth/react';
import {DatabaseCartProviderV3} from '@components/cart/CartProvider.jsx';
import {nanoid} from 'nanoid';
import {
  deleteScrollPosition,
  restoreScrollPosition,
  saveScrollPosition
} from '@helpers/common/scroll-position';
// import {SpeedInsights} from '@vercel/speed-insights/next'; // <-- reinstall package if turned on
// import {onLCP, onFID, onCLS} from 'web-vitals';

const oswald = localFont({
  src: '../public/fonts/Oswald-Regular.ttf',
  weight: '400',
  display: 'swap',
  adjustFontFallback: false,
  variable: '--font-oswald'
});
const oswaldLight = localFont({
  src: '../public/fonts/Oswald-ExtraLight.ttf',
  weight: '200',
  display: 'swap',
  adjustFontFallback: false,
  variable: '--font-oswald-lightest'
});
const bebas = localFont({
  src: '../public/fonts/BebasNeue-Regular.ttf',
  weight: '400',
  display: 'swap',
  adjustFontFallback: false,
  variable: '--font-bebas'
});
const openSans = Open_Sans({
  subsets: ['latin'],
  display: 'swap',
  variable: '--font-openSans'
});

export const sa_session_cookie = 'sa_session';
export const sa_pcs_id = 'sa_pcs_id';

const Noop = ({children}) => <>{children}</>;

export default function MyApp({Component, pageProps: {session, ...pageProps}}) {
  // console.log("[MyApp][PageProps]:", pageProps)
  const Layout = Component.Layout || Noop;
  const env = process.env.NODE_ENV;
  const router = useRouter();

  const useServiceWorkerCache = priceHelper?.performance?.useServiceWorkerCache;

  const shouldRestoreRef = useRef(false);

  // useEffect(() => {
  // Update the document title using the browser API
  // onCLS(console.log);
  // onFID(console.log);
  // onLCP(console.log);
  // });

  useEffect(() => {
    const originalScrollRestoration = window.history.scrollRestoration;
    window.history.scrollRestoration = 'manual';

    const handleBeforeUnload = (event) => {
      saveScrollPosition(router.asPath);
      delete event['returnValue'];
    };

    const handleRouteChangeStart = () => {
      saveScrollPosition(router.asPath);
    };

    const handleRouteChangeComplete = (url) => {
      if (shouldRestoreRef.current) {
        shouldRestoreRef.current = false;

        restoreScrollPosition(url);
        deleteScrollPosition(url);
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);
    router.beforePopState(() => {
      shouldRestoreRef.current = true;
      return true;
    });

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
      router.beforePopState(() => true);

      // Restore the original scroll restoration behavior
      window.history.scrollRestoration = originalScrollRestoration;
    };
  }, []);

  useEffect(() => {
    setSessionCookieForVisitor();
    setSaVisitorCookie();
    logServerSource();
    if (router?.query?.pn_cs_id) {
      setPushCookie(router?.query?.pn_cs_id);
    }
  }, [router]);

  const setSessionCookieForVisitor = () => {
    let sessionCookie = readCookie(sa_session_cookie);
    if (!sessionCookie && router) {
      let path = '/';
      sessionCookie = nanoid(21);
      // console.log({path, sessionCookie});
      setSessionCookie(sessionCookie, path);
    }
  };

  const setSaVisitorCookie = () => {
    const visitorCookie = readCookie(sa_visitor_cookie);
    if (!visitorCookie) {
      const value = nanoid(15).toLowerCase();
      setCookie(sa_visitor_cookie, value, 730);
    }
  };

  const logServerSource = () => {
    const serverSourceCookie = readCookie('sa_server_source') || null;
    if (serverSourceCookie) {
      console.log('Server Source:', serverSourceCookie);
    }
  };

  const setPushCookie = (id) => {
    if (router) setCookie(sa_pcs_id, id, 400);
  };

  return (
    <>
      <style jsx global>{`
        .font-oswald {
          font-family: ${oswald?.style?.fontFamily}, sans-serif;
        }

        .font-oswald-lightest {
          font-family: ${oswaldLight?.style?.fontFamily}, sans-serif;
        }

        .font-bebas {
          font-family: ${bebas?.style?.fontFamily}, sans-serif;
        }

        .font-opensans {
          font-family: ${openSans?.style?.fontFamily}, sans-serif;
        }

        @media (min-width: 640px) {
          .font-oswald-desktop-only {
            font-family: ${oswald?.style?.fontFamily}, sans-serif;
          }
        }

        html {
          --font-opensans: ${openSans?.style?.fontFamily};
        }
      `}</style>

      <div
        className={`${oswald?.variable} ${oswaldLight?.variable} ${bebas?.variable} ${openSans?.variable}`}>
        <div suppressHydrationWarning={true}></div>
        {/*<SpeedInsights />/!* Disabled for time being - costs $$$ with Vercel*!/*/}
        <SeoRouter props={pageProps} router={router} />
        <GlobalJsonLD />
        {env === 'production' && useServiceWorkerCache && <RegisterServiceWorker />}
        <SessionProvider session={session}>
          <ErrorBoundary>
            <CartSideBarUiProvider>
              <DatabaseCartProviderV3>
                <ManagedUIContext>
                  <FacebookPixel />
                  <LoadGoogleTrackingTags router={router} />
                  <TriggerTrackerPageViews />
                  <Layout pageProps={pageProps} session={session}>
                    <Component {...pageProps} session={session} />
                  </Layout>
                </ManagedUIContext>
              </DatabaseCartProviderV3>
            </CartSideBarUiProvider>
          </ErrorBoundary>
        </SessionProvider>
      </div>
    </>
  );
}

const hasImportantQueryParams = () => {
  const importantParams = ['variant']; // add any other important params you need to retain
  return Object.keys(routerQ).some((param) => importantParams?.includes(param));
};

const LoadGoogleTrackingTags = ({router, children}) => {
  const routerQ = router?.query;
  const {data: session, status} = useSession();
  const sessionIsLoading = status === 'loading';

  const {GA4_MEASUREMENT_ID} = GA4_CONFIG('PROD');
  const ADWORDS_ID = 'AW-976071580';
  const [userDataSet, setUserDataSet] = useState(false);

  const setGtagUserData = () => {
    if (session && !userDataSet) {
      const userDataForGtagEvent = setUserDataWithUserSessionForGTagEvent(session);
      if (typeof window !== 'undefined') {
        window.gtag('set', 'user_data', userDataForGtagEvent);
        const user = session?.user;
        if (user?.account_id) {
          window?.dataLayer.push({
            user_id: user.account_id
          });
        }
        setUserDataSet(true);
      }
    }
  };

  useEffect(() => {
    if (!userDataSet) {
      setGtagUserData();
    }
  }, [session, userDataSet]);

  const generateGtagScript = () => {
    return `window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${GA4_MEASUREMENT_ID}', {
        'allow_enhanced_conversions':true,
        'allow_google_signals':true
      });
      gtag('config', '${ADWORDS_ID}', {
        'allow_enhanced_conversions':true,
        'allow_google_signals':true
      });
    `;
  };

  const hasImportantQueryParams = () => {
    try {
      const importantParams = ['variant', 'sku']; // add any other important params you need to retain
      return Object.keys(router.query).some((param) => importantParams.includes(param));
    } catch (error) {
      console.log(`Error in hasImportantQueryParams: ${error.message}`);
      return false; // or handle the error as needed
    }
  };

  useEffect(() => {
    const {asPath} = router;
    const cleanPath = asPath?.split('?')[0];
    if (cleanPath?.includes('vehicle-specific')) {
      return;
    }
    const shouldRunEffect = cleanPath?.includes('/collections') || cleanPath?.includes('/products');

    if (shouldRunEffect && !hasImportantQueryParams() && asPath !== cleanPath) {
      router.replace(cleanPath, undefined, {shallow: true});
    }
  }, [router]);

  return (
    <>
      <Script
        id="ga41"
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${GA4_MEASUREMENT_ID}`}
        onLoad={setGtagUserData}
      />
      <Script
        id="ga42"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{__html: generateGtagScript()}}
      />
      {children}
    </>
  );
};

export const FacebookPixel = () => {
  const pixelId = '341205390019842';

  // Check if Facebook Pixel script is already loaded
  useEffect(() => {
    if (!window?.fbq) {
      (function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
      window.fbq('init', pixelId);
    } else {
      window.fbq('init', pixelId);
    }
  }, [pixelId]);
  return null;
};

// export function reportWebVitals(metric) {
//   const body = JSON.stringify(metric)
//   const url = '/api/analytics/performance'
//   if (navigator.sendBeacon) {
//     navigator.sendBeacon(url, body)
//   } else {
//     fetch(url, {body, method: 'POST', keepalive: true})
//   }
// }
