export const VehicleSpecificBreadcrumbs = ({selections}) => {
  // console.log("[VehicleSpecificBreadcrumbs] selections:", selections);
  let vehicleMake = selections?.make;
  if (vehicleMake) {
    vehicleMake = vehicleMake.replace('%2F', '/');
  }
  // console.log({vehicleMake})
  const breadcrumb = [
    {label: 'Home', link: '/'},
    {
      label: 'Vehicle Specific Enclosures',
      link: '/collections/vehicle-specific-subwoofer-enclosures'
    },
  ];

  let breadCrumbLink = `/collections/vehicle-specific-subwoofer-enclosures?vehicle_make=${encodeURI(vehicleMake)}`;
  // console.log({breadCrumbLink})

  if (vehicleMake) {
    breadcrumb.push({
      label: vehicleMake,
      link: `${breadCrumbLink}`,
    });
  }

  return (
    <div id='breadcrumb'>
      <ul className='breadcrumb font-aileron'>
        {breadcrumb?.map((crumb, i) => {
          // console.log('[crumb]', crumb);
          return (
            <li key={i}>
              {i !== breadcrumb?.length - 1 ?
                <a href={crumb?.link}>{crumb?.label}</a> : crumb?.label}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

