import {useEffect, useState} from 'react';

export const useCountdown = (timerExpiresAt, isSaleEnabled = false) => {
  const [countDown, setCountDown] = useState(null);

  useEffect(() => {
    let countDownDate;
    let effectiveTimerExpiresAt = timerExpiresAt;

    if (!effectiveTimerExpiresAt && isSaleEnabled) {
      effectiveTimerExpiresAt = getEndOfDay();
    }

    if (effectiveTimerExpiresAt) {
      countDownDate = new Date(effectiveTimerExpiresAt).getTime();
      const currentTs = new Date().getTime();
      // console.log({ effectiveTimerExpiresAt, currentTs, countDownDate });

      if (countDownDate < currentTs) {
        console.log("Countdown date is in the past.");
        setCountDown(0);
        return;
      }

      setCountDown(countDownDate - currentTs);
    }

    const interval = setInterval(() => {
      const newCountDown = countDownDate - new Date().getTime();
      if (newCountDown < 0) {
        clearInterval(interval);
        setCountDown(0);
      } else {
        setCountDown(newCountDown);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [timerExpiresAt, isSaleEnabled]);

  return countDown === null ? [0, 0, 0, 0] : getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);
  return [days, hours, minutes, seconds];
};

export const getEndOfDay = () => {
  const now = new Date();
  const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
  endOfDay.setHours(0, 0, 0, 0);
  endOfDay.setMilliseconds(endOfDay.getMilliseconds() - 1);
  // console.log({endOfDay})
  return endOfDay;
}
