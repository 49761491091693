import cn from 'clsx';
import {
  FormElementWithFloatingLabel,
  InputWithFloatingLabel
} from '../../form-fields/FloatingLabel.jsx';
import {forwardRef} from 'react';

export const FormField = forwardRef(({
                                       type,
                                       name,
                                       value,
                                       onChange,
                                       placeholder,
                                       optional,
                                       minLength,
                                       pattern,
                                       className,
                                       ...otherProps
                                     },
                                     ref
  ) => {
    return (
      <div className={className}>
        <InputWithFloatingLabel
          id={(type ?? 'text') + '-' + name}
          inputProps={{
            type: type ?? 'text',
            name: name,
            value: value ?? '',
            onChange: onChange,
            required: !optional,
            minLength,
            pattern,
            ...otherProps
          }}
          placeholder={placeholder}
        />
      </div>
    );
  }
);

export function FormFieldSelect({
                                  id,
                                  name,
                                  value,
                                  onChange,
                                  placeholder,
                                  options,
                                  className,
                                  selectStyling
                                }) {
  return (
    <div className={className}>
      <FormElementWithFloatingLabel id={id} placeholder={placeholder}>
        <select
          className="form-select bg-white w-full active:border-0 active:outline-0 h-[34px]
					 md:h-[2.6rem] outline-0 text-lg autofill:bg-transparent"
          name={name}
          value={value ?? '-'}
          onChange={onChange}
          style={{WebkitAppearance: 'none', ...selectStyling}}>
          <SelectOptions options={options} />
        </select>
      </FormElementWithFloatingLabel>
    </div>
  );
}

export function SelectOptions({options}) {
  return options?.map(({key, val, text, disabled}) => (
    <option key={key} value={val} disabled={disabled} style={disabled ? {textColor: '#eee'} : {}}>
      {text}
    </option>
  ));
}

export function FormCheckbox({name, children, checked, onChange, ...otherProps}) {
  return (
    <div className="flex flex-col items-start">
      <label className="p-0 m-0">
        <input
          type="checkbox"
          name={name}
          className={cn(
            'h-3.5 w-3.5 md:w-4 md:h-4',
            'border border-gray-300 rounded bg-white',
            'focus:ring-3 focus:ring-blue-300 mr-2 align-middle',
            'disabled:bg-gray-200'
          )}
          checked={checked}
          value={1}
          onChange={(e) => {
            let event = {
              ...e,
              target: {
                ...e.target,
                name: e.target.name,
                value: e.target.checked
              }
            };
            onChange && onChange(event);
          }}
          {...otherProps}
        />
        {children}
      </label>
    </div>
  );
}

export const FormCheckboxV2 = ({name, children, checked, onChange, ...otherProps}) => {
  return (
    <div className="flex flex-col items-start">
      <label className="ml-2 custom-checkbox">
        {children}
        <input
          type="checkbox"
          name={name}
          checked={checked}
          value={1}
          onChange={(e) => {
            let event = {
              ...e,
              target: {
                ...e.target,
                name: e.target.name,
                value: e.target.checked
              }
            };
            onChange && onChange(event);
          }}
          {...otherProps}
        />
        <span className="checkmark"></span>
      </label>
    </div>
  );
};

export function FormRadio({name, value, children, checked, onChange}) {
  return (
    <div className="my-2">
      <label>
        <input
          type="radio"
          name={name}
          className="w-4 h-4 border border-gray-300 rounded bg-white focus:ring-3 focus:ring-blue-300 mr-2 align-middle"
          checked={checked}
          value={value || 1}
          onChange={(e) => {
            let event = {
              ...e,
              target: {
                ...e.target,
                name: e.target.name,
                value: e.target.checked
              }
            };
            // console.log(event)
            onChange && onChange(event);
          }}
        />
        {children}
      </label>
    </div>
  );
}
