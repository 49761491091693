export const formatPriceNumeric = (price) => {
  let amount = price ?? price?.amount;
  if (typeof amount === 'string') {
    amount = convertDollarStringToNumericValue(amount);
  }
  return amount;
};

export const convertDollarStringToNumericValue = (stringAmount) => {
  if (typeof stringAmount !== 'string') {
    stringAmount = String(stringAmount);
  }
  let numericValue = stringAmount.replace(/[$,]/g, '');
  numericValue = parseFloat(numericValue);
  return numericValue;
};
