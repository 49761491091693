import {hashSHA256} from '../../helpers/common/sha-256.mjs';
import {cleanPhoneAndReformatToE164} from '../../helpers/common/format/format-phone.mjs';
import {consoleLogPink} from '../../helpers/common/console-log-colors.mjs';
import {skuCleaner} from '../../helpers/common/sku.mjs';

export const filterForTestSkuInRows = (rows, options = {}) => {
  const sku = options?.sku;
  if (!sku) return rows;
  return rows?.filter(row => row?.sku === sku);
};

export const cleanAndRemapSkuList = (skuList) => {
  if (!skuList?.length) return;
  skuList = skuList?.map(sku => {
    if (sku?.includes('FORD-2009-UP')) {
      sku?.replace('2009-UP', '2015-UP');
    }
    sku = skuCleaner(sku);
    return sku;
  });
  return skuList;
};


export const makeObjValuesLowercase = (obj) => {
  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'string') {
      obj[key] = obj[key].toLowerCase();
    }
  });
  return obj;
};

export const prepareAndHashEmail = (email) => {
  const preparedEmail = email.trim().toLowerCase();
  const hashedEmail = hashSHA256(preparedEmail);
  // console.log(`Hashed Email: ${hashedEmail}`);
  return hashedEmail;
};

export const isObjectEmpty = (obj) => {
  if (!obj) return;
  return Object.keys(obj)?.length === 0;
};

export const prepareAndHashPhoneNumberE164 = (phoneNumber) => {
  let preparedPhoneNumber = cleanPhoneAndReformatToE164(phoneNumber);
  preparedPhoneNumber = '+' + preparedPhoneNumber
  // console.log(`Prepared PhoneNumber: ${preparedPhoneNumber}`);
  const hashedPhoneNumber = hashSHA256(preparedPhoneNumber);
  // console.log(`Hashed PhoneNumber: ${hashedPhoneNumber}`);
  return hashedPhoneNumber;
}

export const convertIntAmountToDecimalAmount = (amount) => {
  let total = Number(amount) / 100;
  total = Number(total.toFixed(2));
  return Number(total);
}

export const convertOneRowsValueToNumbers = (row) => {
  if (!row) {
    return null;
  }
  const keys = Object.keys(row);
  keys.forEach((key) => {
    if (!key.includes('address') && !key.includes('zip') && typeof row[key] === 'string') {
      const stringValue = row[key];
      // Check if the string is a valid number with possible comma as thousand separator
      if (/^(\d{1,3}(,\d{3})*|\d+)(\.\d+)?$/.test(stringValue)) {
        // Remove commas before parsing
        const cleanString = stringValue.replace(/,/g, '');
        const intValue = parseInt(cleanString);
        const floatValue = parseFloat(cleanString);
        row[key] = intValue === floatValue ? intValue : floatValue;
      }
    }
  });
  return row;
};

export const capitalizeAndCleanString = (text) => {
  if (!text) {
    consoleLogYellowBright('No input str supplied - returning null');
    return null;
  }
  text = text.toLowerCase();
  text = text?.replace(/\./g, '');
  text = text?.replace(/,/g, '');
  text = text?.replaceAll('  ', ' ');
  text = text?.replace(/[`~!,@#$%^&*()_|+\-=?;:'".<>{}[]\/]/gi, '');
  const temp = text
    ?.split(' ')
    ?.filter((x) => x !== '.')
    ?.map((word) => (word.length > 0 ? word?.[0]?.toUpperCase() + word?.substring(1)?.toLowerCase() : word))
    ?.join(' ')
    ?.trim();
  return temp?.replace(/\./g, ' ');
};

export const combineAndCleanFirstAndLastNames = (firstName, lastName) => {
  if (!firstName && !lastName) {
    consoleLogPink('Cannot Continue - No First Name OR Last Name Was Supplied');
    return null;
  }

  let fullName;
  if (firstName && !lastName) {
    fullName = firstName;
    return fullName;
  }

  if (firstName && lastName) {
    firstName = firstName?.replaceAll(' ', '') ?? '';
    if (firstName?.length > 1) {
      firstName = firstName?.replace(/,/g, '');
      firstName = firstName?.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[]\/]/gi, '');
      firstName = capitalizeAndCleanString(firstName);
    }
    lastName = lastName?.replaceAll(' ', '') ?? '';
    if (lastName?.length > 1) {
      lastName = lastName?.replace(/,/g, '');
      lastName = lastName?.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[]\/]/gi, '');
      lastName = capitalizeAndCleanString(lastName);
    }
    fullName = [firstName, lastName]?.filter(Boolean)?.join(' ');
    return fullName;
  }
};

export const convertManyRowsValuesToNumbers = (rows) => {
  if (!rows?.length || rows?.length < 1) return null;
  const excludedKeys = ['collection_id', 'upc', 'legacy_item_id', 'line_item_id', 'txn_id', 'transaction_id', 'order_id', 'orderNumber', 'order_number', 'associated_qb_order_number', 'address', 'zip'];
  const numericTest = /^(\d{1,3}(,\d{3})*(\.\d+)?|\d+(\.\d+)?)$/; // Regular expression to test if string contains only numeric characters and commas

  return rows.map((row) => {
    const keys = Object.keys(row);
    keys.forEach((key) => {
      if (!excludedKeys.includes(key) && typeof row[key] === 'string') {
        if (numericTest.test(row[key])) {
          let replaced = row[key].replace(/,/g, '');
          const numberValue = Number(replaced);
          if (!isNaN(numberValue)) {
            row[key] = numberValue;
          }
        }
      }
    });
    return row;
  });
};

export const extractCleanPagePathFromURL = (str) => {
  if (typeof str !== 'string') {
    console.warn('trimBeforeFirstSingleSlash expects a string input, but got ' + typeof str);
    return str;
  }
  const firstSingleSlashIndex = str.search(/[^/]\/[^/]/);
  return firstSingleSlashIndex !== -1 ? str.slice(firstSingleSlashIndex + 1) : str;
}


export function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const sortByField = (a, b, sortBy) => {
  let v1 = a[sortBy]
  let v2 = b[sortBy]
  if (typeof v1 === 'object') {
    v1 = v1.value
  }
  if (typeof v2 === 'object') {
    v2 = v2.value
  }
  if (typeof v1 === 'number' && typeof v2 === 'number') {
    return v1 - v2
  }
  v1 = String(v1)
  v2 = String(v2)
  return v1.localeCompare(v2)
}

export const generateUUID = () => {
  let d = new Date().getTime(),
    d2 = (performance && performance.now && performance.now() * 1000) || 0
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let r = Math.random() * 16
    if (d > 0) {
      r = (d + r) % 16 | 0
      d = Math.floor(d / 16)
    } else {
      r = (d2 + r) % 16 | 0
      d2 = Math.floor(d2 / 16)
    }
    return (c === 'x' ? r : (r & 0x7) | 0x8).toString(16)
  });
}

export const replaceUndefinedWithNull = (obj) => {
  if (obj === null || obj === undefined) {
    return null;
  }

  if (typeof obj !== 'object') {
    return obj;
  }

  Object.keys(obj).forEach(key => {
    if (obj[key] === undefined) {
      obj[key] = null;
    } else if (typeof obj[key] === 'object') {
      replaceUndefinedWithNull(obj[key]);
    }
  });
  return obj;
};

export const formatAndCleanPhoneToTenDigits = (inputPhoneNumber) => {
  if (!inputPhoneNumber) return null;
  try {
    let phoneNumberStr = inputPhoneNumber.replace(/\D/g, '');
    if (phoneNumberStr?.length === 11 && phoneNumberStr[0] === '1') {
      phoneNumberStr = phoneNumberStr?.substring(1);
    }
    if (phoneNumberStr?.length > 10) {
      return null;
    } else if (phoneNumberStr?.length < 10) {
      return null;
    }
    return phoneNumberStr;
  } catch (e) {
    console.log("Error in formatting phone number:", e);
    return null;
  }
};

export const generateYearsForCCExp = () => {
  const startYear = new Date().getFullYear();
  const endYear = startYear + 12;
  const yearOptions = {};

  for (let year = startYear; year <= endYear; year++) {
    yearOptions[year] = year.toString();
  }

  return yearOptions;
};
