export const AiOutlineArrowRight = ({size = '1em', color = 'currentColor', onClick}) => {
  return (
    <div onClick={onClick}>
      <svg
        stroke={color}
        fill={color}
        strokeWidth='0'
        viewBox='0 0 1024 1024'
        height={size}
        width={size}
        xmlns='http://www.w3.org/2000/svg'>
        <path d='M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-.7 5.2-2L869 536.2a32.07 32.07 0 0 0 0-48.4z' />
      </svg>
    </div>
  );
};

export const AiOutlineDownload = ({size = '1em', color = 'currentColor', onClick}) => {
  return (
    <div onClick={onClick}>
      <svg
        stroke={color}
        fill={color}
        height={size}
        width={size}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1024 1024">
        <path
          d="M505.7 661a8 8 0 0 0 12.6 0l112-141.7c4.1-5.2.4-12.9-6.3-12.9h-74.1V168c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v338.3H400c-6.7 0-10.4 7.7-6.3 12.9l112 141.8zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z" />
      </svg>
    </div>
  );
};


export const AiOutlinePlus = ({size = '1em', color = 'currentColor', onClick}) => {
  return (
    <div onClick={onClick}>
      <svg
        stroke={color}
        fill={color}
        strokeWidth='0'
        t='1551322312294'
        viewBox='0 0 1024 1024'
        version='1.1'
        height={size}
        width={size}
        xmlns='http://www.w3.org/2000/svg'>
        <defs></defs>
        <path d='M474 152m8 0l60 0q8 0 8 8l0 704q0 8-8 8l-60 0q-8 0-8-8l0-704q0-8 8-8Z'></path>
        <path d='M168 474m8 0l672 0q8 0 8 8l0 60q0 8-8 8l-672 0q-8 0-8-8l0-60q0-8 8-8Z'></path>
      </svg>
    </div>
  );
};

export const AiOutlineMinus = ({size = '1em', color = 'currentColor', onClick}) => {
  return (
    <div onClick={onClick}>
      <svg
        stroke={color}
        fill={color}
        strokeWidth='0'
        t='1551322312294'
        viewBox='0 0 1024 1024'
        version='1.1'
        height={size}
        width={size}
        xmlns='http://www.w3.org/2000/svg'>
        <defs></defs>
        <path d='M168 474m8 0l672 0q8 0 8 8l0 60q0 8-8 8l-672 0q-8 0-8-8l0-60q0-8 8-8Z'></path>
      </svg>
    </div>
  );
};
