export const vehicleSpecificFilterByCheckmarks = (products, checkedFilters) => {
  const filterTypes = checkedFilters?.filterTypes;
  // console.log("[FilterByChecked][Filters]", filterTypes)

  const typeFilter = filterTypes?.find(filter => filter?.name?.includes('Type'));
  const sizesFilter = filterTypes?.find(filter => filter?.name === 'Sizes');

  const EmptyFilterChecked = typeFilter?.options?.find(option => option.name === 'Empty')?.value;
  const loadedFilterChecked = typeFilter?.options?.find(option => option.name === 'Loaded')?.value;

  const sizesChecked = sizesFilter?.options?.filter(option => option.value === true).map(option => option.name);

  if (loadedFilterChecked) {
    products = products?.filter(product => product?.category === 'loaded-subwoofer-enclosures');
  }

  if (EmptyFilterChecked) {
    products = products?.filter(product => product?.category === 'empty-subwoofer-enclosures');
  }

  if (sizesChecked?.length > 0) {
    products = products?.filter(product => {
      const productHasSizeProperty = product.properties?.hasOwnProperty('Subwoofer Size');
      if (productHasSizeProperty) {
        return sizesChecked.includes(product?.properties?.['Subwoofer Size']);
      } else {
        return false;
      }
    });
  }

  return products;
};



