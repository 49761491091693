import {useEffect, useRef} from 'react';
import {useRouter} from 'next/router';

export const usePreviousRoute = () => {
  const router = useRouter();
  const routePath = router.asPath;
  const prevRouteRef = useRef(undefined);
  useEffect(() => {
    prevRouteRef.current = routePath;
  }, [routePath]);

  return prevRouteRef.current;
};
