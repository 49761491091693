import SkarLogo from '/public/image/logos/skar-audio-logo-black-small.png';
import Image from 'next/image';
import {useEffect, useState} from 'react';
import {addDays} from '../../helpers/common/date.mjs';
import {useUI} from '../../components/widgets/UiProvider';
import {
  promptForPushSubscription,
  registerServiceWorker,
  urlB64ToUint8Array
} from '../../helpers/marketing/push/helpers.mjs';
import * as Dialog from '@radix-ui/react-dialog';

const vapid_key = process.env.NEXT_PUBLIC_VAPID_PUBLIC_KEY;

const DAYS_BEFORE_ASKING_AGAIN = 2;

export const PushPopup = () => {
  const {displayPushModal, closePushModal, displayMobileNav} = useUI();
  const [showPushNotificationPopup, setShowPushNotificationPopup] = useState(false);

  const isMobileNavOpen = displayMobileNav === true;

  useEffect(() => {
    const checkForSubscription = async () => {
      try {
        const registration = await registerServiceWorker();
        if (
          !registration ||
          !registration?.pushManager ||
          typeof registration?.pushManager?.permissionState !== 'function'
        ) {
          // console.error('PushManager or permissionState method is not available')
          return;
        }
        const subscription_state = await registration?.pushManager?.permissionState({
          userVisibleOnly: true,
          applicationServerKey: urlB64ToUint8Array(vapid_key)
        });

        if (subscription_state !== 'denied' && subscription_state !== 'granted') {
          setShowPushNotificationPopup(true);
        }
      } catch (error) {
        console.error('Error checking for subscription:', error);
      }
    };
    checkForSubscription();
  }, []);

  const allow = async () => {
    try {
      localStorage.setItem('push_remind_after', addDays(new Date(), DAYS_BEFORE_ASKING_AGAIN));
      await promptForPushSubscription();
    } catch (e) {
      console.error('[allow (PushNotificationSystem)][Error Message]:', e?.message);
    } finally {
      closePushModal();
    }
  };

  const deny = () => {
    try {
      localStorage.setItem('push_remind_after', addDays(new Date(), DAYS_BEFORE_ASKING_AGAIN));
      closePushModal();
    } catch (e) {
      console.error('[deny (PushNotificationSystem)][Error Message]:', e?.message);
    }
  };

  // console.log({displayPushModal, showPushNotificationPopup, isMobileNavOpen});
  if (!displayPushModal || !showPushNotificationPopup || isMobileNavOpen) return null;

  return (
    <Dialog.Root open={displayPushModal} onOpenChange={closePushModal}>
      <Dialog.Portal>
        <Dialog.Overlay className="modal-overlay fixed inset-0 bg-black bg-opacity-50" />
        <Dialog.Content
          aria-describedby={undefined}
          className="animate-[slideDown_0.42s_ease_1] fixed top-4 left-4 overflow-y-auto z-50 flex min-h-full items-start justify-start p-4 text-center">
          <div className="w-full max-w-md transform overflow-hidden rounded-md bg-white p-6 text-left align-middle shadow-xl">
            <div className="flex gap-2 justify-between items-start">
              <div className="w-24 pt-2">
                <Image src={SkarLogo} alt="Skar Audio logo" />
              </div>
              <div>
                <Dialog.Title>Become a Skar Audio VIP</Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    We will notify you about new product launches and sales first!
                  </p>
                </div>

                <div className="mt-4 flex justify-start flex-row-reverse gap-4">
                  <button
                    type="button"
                    className="flex gap-2 items-center py-2 px-4 text-sm font-medium text-white rounded-md border bg-neutral-700 hover:bg-neutral-800"
                    onClick={allow}>
                    Allow
                  </button>
                  <button
                    type="button"
                    className="py-2 px-4 text-sm font-medium text-black hover:underline"
                    onClick={deny}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
