import useSWR from 'swr';
import {useEffect, useState} from 'react';
import {fetcher, isofetch} from '@helpers/common/fetch.mjs';

export const useAccountOrders = (data, currentOrderNumber) => {
  const serverOrders = data?.orders ?? [];

  const [ordersPage, setOrdersPage] = useState(1);
  const [ordersPageSize] = useState(5);
  const [hasPrevOrders, setHasPrevOrders] = useState(false);
  const [hasNextOrders, setHasNextOrders] = useState(false);
  const [order, setOrder] = useState();
  const [isLoadingOrder, setIsLoadingOrder] = useState(true);

  const {
    data: ordersData,
    mutate: ordersMutate,
    isLoading: isLoadingOrders,
    isValidating
  } = useSWR(`/api/accounts/orders?page=${ordersPage}&size=${ordersPageSize}`, fetcher, {
    revalidateOnFocus: false,
    revalidateOnMount: false,
    shouldRetryOnError: false,
    fallbackData: {data: serverOrders},
    keepPreviousData: true
  });

  const orders = ordersData?.data ?? [];
  const ordersLoadedOnce = !isLoadingOrders && typeof ordersData !== 'undefined';

  const getAndSetOrder = async () => {
    let orderData;
    try {
      setIsLoadingOrder(true);
      const matchingOrder = orders?.find((order) => order?.orderNumber === currentOrderNumber);
      if (matchingOrder) {
        orderData = matchingOrder;
      } else {
        const response = await isofetch.get(
          `/api/accounts/order-with-details?id=${currentOrderNumber}`
        );
        if (response?.data) {
          orderData = response?.data;

          // Add the newly fetched order to the orders array
          ordersMutate((prevOrdersData) => {
            if (!prevOrdersData) return {data: [orderData]};
            const existingOrderIndex = prevOrdersData.data.findIndex(
              (order) => order.orderNumber === orderData.orderNumber
            );
            if (existingOrderIndex === -1) {
              return {...prevOrdersData, data: [...prevOrdersData.data, orderData]};
            }
            return prevOrdersData; // Order already exists
          }, false);
        } else {
          throw new Error('No order data found');
        }
      }

      setOrder(orderData);
    } catch (e) {
      console.error('[useAccountOrders][getAndSetOrder] Error', e);
    } finally {
      setIsLoadingOrder(false);
    }
  };

  useEffect(() => {
    if (orders?.length > 0) {
      setHasNextOrders(orders?.length >= ordersPageSize);
      setHasPrevOrders(ordersPage > 1);

      if (currentOrderNumber) {
        getAndSetOrder();
      }
    }
  }, [orders, currentOrderNumber]);

  const ordersPrev = async () => {
    setOrdersPage(ordersPage - 1);
    await ordersMutate();
  };

  const ordersNext = async () => {
    setOrdersPage(ordersPage + 1);
    await ordersMutate();
  };

  return {
    orders,
    order: order ?? {},
    isLoadingOrder,
    ordersLoading: !ordersLoadedOnce,
    ordersValidating: isValidating,
    ordersPrev,
    ordersNext,
    hasNextOrders,
    hasPrevOrders,
    ordersMutate
  };
};
