import {isofetch} from '@helpers/common/fetch.mjs';

const vapid_key = process.env.NEXT_PUBLIC_VAPID_PUBLIC_KEY;

export function shouldTriggerPushPopup() {
  let remindAfter = localStorage.getItem('push_remind_after')
  if (remindAfter) {
    remindAfter = new Date(remindAfter)
    if (new Date().getTime() < remindAfter.getTime()) {
      return false
    }
  }
  return true
}

export async function promptForPushSubscription() {
  if (!('serviceWorker' in navigator)) {
    throw new Error('Service workers are not supported by this browser');
  }

  if (!('PushManager' in window)) {
    throw new Error('Push notifications are not supported by this browser');
  }

  const push_manager_options = {
    userVisibleOnly: true,
    applicationServerKey: urlB64ToUint8Array(vapid_key),
  };

  try {
    const registration = await navigator.serviceWorker.getRegistration();
    if (!registration || !registration?.pushManager) {
      throw new Error('Service worker registration failed');
    }
    const subscription = await registration?.pushManager?.subscribe(push_manager_options);
    const json_subscription = subscription.toJSON();
    // console.log({json_subscription})

    const data = await isofetch.post(`/api/marketing/push/subscriber/add`, {
      endpoint: json_subscription.endpoint,
      p256dh: json_subscription.keys.p256dh,
      auth: json_subscription.keys.auth,
    });

    console.log("[promptForPushSubscription][response]", data);
    return true
  }
  catch(e) {
    console.error('[promptForPushSubscription][Error Message]:', e?.message);
    throw e;
    // console.log('user cancelled request for push subscription')
    // we could log this in a database or localStorage to try again later after a certain number of days
  }
}

export async function registerServiceWorker() {
  let registration = await navigator?.serviceWorker?.getRegistration();
  if (!registration && 'serviceWorker' in navigator) {
    registration = navigator?.serviceWorker?.register("/sw-push.js");
  }

  return registration;
}

export async function updatePushServiceWorker() {
  const registration = await navigator?.serviceWorker?.getRegistration();
  if (registration) {
    registration.update();
  }
}

export function urlB64ToUint8Array(base64String) {
  if (!base64String){
    return null;
  }
	const padding = "=".repeat((4 - base64String?.length % 4) % 4);
	const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/");
	const rawData = window.atob(base64);
	const outputArray = new Uint8Array(rawData.length);
	for (let i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
}
