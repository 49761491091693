import Image from 'next/image';
import DelayComponentVisible from '@components/widgets/DelayComponentVisible';
import {InfiniteScroll} from '@components/widgets/InfiniteScroll';
import {SpinnerV3} from '@components/widgets/Spinners/LoadingCircle';

export const ReviewAllPhotos = ({
                                  reviews,
                                  onClickReviewPhoto,
                                  nextPage,
                                  isLoadingMore,
                                  isReachingEnd
                                }) => {
  const reviewImages = reviews?.flatMap((review) => {
      return review?.images?.slice(0, 2)?.map((image, index) => ({
        image,
        image_index: index,
        review_id: review.id
      }));
    }
  );

  return (
    <div className="pb-6 lg:overflow-y-auto">
      <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
        {reviewImages?.map((review, index) => {
          const isLastImage = index === reviewImages?.length - 1;

          return (
            <div
              key={`review-image-${review?.review_id}-${review?.image_index}`}
              className="relative aspect-square cursor-pointer bg-[#c4c4c4] bg-spinner rounded-md"
              onClick={() => onClickReviewPhoto(review?.review_id, review?.image_index)}>
              <Image
                src={review?.image}
                alt={'photo review'}
                className="rounded-md object-cover object-center snap-center relative z-10"
                onLoad={(e) => {
                  e.target?.parentElement?.classList?.remove('bg-spinner');
                }}
                fill
                sizes="300px"
              />
              {isLastImage && !isReachingEnd && !isLoadingMore && (
                <InfiniteScroll loadMore={nextPage} />
              )}
            </div>
          );
        })}
      </div>

      {isLoadingMore && (
        <DelayComponentVisible delay={200}>
          <div className="flex items-center justify-center pt-6">
            <div>
              <SpinnerV3 />
            </div>
          </div>
        </DelayComponentVisible>
      )}

      <div className="flex justify-center pt-6">
        {!isReachingEnd && !isLoadingMore && (
          <>
            <div
              className="inline-block m-0 items-center justify-center uppercase md:font-medium text-center text-sm tracking-wide px-8 py-2 md:py-2 rounded cursor-pointer disabled:border-gray-300 disabled:bg-gray-300 mt-4 disabled:cursor-not-allowed text-white bg-neutral-800 hover:bg-neutral-700 active:bg-neutral-800 transition ease-in-out duration-150 align-center gap-3"
              onClick={() => nextPage()}>
              Load More
            </div>
          </>
        )}
      </div>
    </div>
  );
};
