import Head from 'next/head';
import {
  defaultSeoDescription,
  defaultSeoImage,
  defaultSeoTitle,
  GenerateOpenGraphPageMetaData,
  GenerateStaticPageMetaData
} from '@components/seo/MetaDataDefaults';

export const DefaultPageSeo = ({url}) => {
  return (
    <>
      <Head>
        <title>{defaultSeoTitle}</title>
        <meta charSet='UTF-8' />
        <meta name='description' content={defaultSeoDescription} />
        <link rel='canonical' href={url} />
        <GenerateStaticPageMetaData />
        <GenerateOpenGraphPageMetaData
          title={defaultSeoTitle}
          description={defaultSeoDescription}
          image={defaultSeoImage}
          url={url}
        />
      </Head>
    </>
  );
};
