import {useEffect, useState} from 'react';

export const useProductFilter = (initialProducts, filterOptions) => {
  const [filteredProducts, setFilteredProducts] = useState(initialProducts);
  const [checkedFilters, setCheckedFilters] = useState(filterOptions);

  useEffect(() => {
    const newFilteredProducts = filterCollectionProducts(initialProducts, checkedFilters);
    setFilteredProducts(newFilteredProducts);
  }, [checkedFilters, initialProducts]);

  return [filteredProducts, checkedFilters, setCheckedFilters];
};

export const handleCollectionPageFilterChange = (e, typeIndex, optionIndex, setCheckedFilters) => {
  const isChecked = e.target.checked;

  setCheckedFilters((prevState) => {
    // Create a deep copy of the filterTypes array to avoid mutating the state directly
    const newFilterTypes = prevState.filterTypes.map((filterType, index) => {
      if (index === typeIndex) {
        const newOptions = filterType.options.map((option, optIndex) => {
          if (optIndex === optionIndex) {
            return {...option, value: isChecked};
          }
          return option;
        });
        return {...filterType, options: newOptions};
      }
      return filterType;
    });

    return {filterTypes: newFilterTypes};
  });
};


export const filterCollectionProducts = (products, checkedFilters) => {
  return products?.filter((product) => {
    return checkedFilters.filterTypes.every((type) => {
      const productProperty = product?.properties?.[type.name];
      const selectedOption = type?.options?.find((option) => option?.value)?.name;
      return !selectedOption || productProperty === selectedOption;
    });
  });
};

export const SidebarFilterDesktop = ({checkedFilters, setCheckedFilters, handleFilterChange}) => {
  const filterTypes = checkedFilters?.filterTypes;

  const handleOnChange = (e, typeIndex, optionIndex) => {
    handleFilterChange(e, typeIndex, optionIndex, setCheckedFilters);
  };

  const styles = {
    heading: {
      padding: '0 0 4px 4px',
      fontFamily: `var(--font-bebas)`,
      fontSize: '28px',
      color: '#333'
    },
    label: {
      display: 'block',
      marginLeft: '8px',
      marginBottom: '7px',
      position: 'relative',
      paddingLeft: '30px',
      cursor: 'pointer',
      fontSize: '18px',
      lineHeight: '22px',
      color: '#333'
    },
    input: {
      position: 'absolute',
      opacity: 0,
      cursor: 'pointer',
      height: 0,
      width: 0
    },
    span: (isChecked) => ({
      position: 'absolute',
      top: 0,
      left: 0,
      height: '20px',
      width: '20px',
      backgroundColor: isChecked ? '#2196F3' : '#eee',
      border: '1px solid #ddd',
      display: 'block',
      transition: 'background-color 0.3s',
      borderRadius: '4px'
    })
  };

  return (
    <aside>
      <div style={styles.heading}>Filters</div>
      {filterTypes.map((type, typeIndex) => (
        <div key={type.name} className='bg-neutral-100 border py-2 px-4 mb-4 rounded mr-5'>
          <div className='mb-2 text-lg font-bold'>{type.name}</div>
          {type?.options.map((option, optionIndex) => (
            <label key={option.name} style={styles.label}>
              <input
                type='checkbox'
                name={option.name}
                checked={option.value}
                onChange={(e) => handleOnChange(e, typeIndex, optionIndex)}
                style={styles.input}
              />

              <span style={styles.span(option.value)} />
              {option.label}
            </label>
          ))}
        </div>
      ))}
    </aside>
  );
};

export const TopFilterMobile = ({checkedFilters, setCheckedFilters, handleFilterChange}) => {
  const filterTypes = checkedFilters?.filterTypes;

  const handleOnChange = (e, typeIndex, optionIndex) => {
    handleFilterChange(e, typeIndex, optionIndex, setCheckedFilters);
  };

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'no-wrap',
      gap: '16px'
    },
    heading: {
      paddingBottom: '8px',
      fontSize: '22px',
      letterSpacing: '0.8px',
      fontFamily: `var(--font-bebas)`,
      color: '#333'
    },
    label: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '5px',
      marginBottom: '5px',
      cursor: 'pointer',
      fontSize: '16px',
      lineHeight: '18px',
      color: '#333'
    },
    input: {
      display: 'none'
    },
    span: (isChecked) => ({
      height: '18px',
      width: '18px',
      backgroundColor: isChecked ? '#0d6be9' : '#eee',
      border: '1px solid #ccc',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'background-color 0.2s',
      borderRadius: '3px',
      marginRight: '5px'
    }),
    checkmark: {
      color: 'white',
      fontSize: '12px'
    }
  };

  return (
    <aside style={styles.container}>
      {filterTypes?.map((type, typeIndex) => (
        <div key={type?.name} className='bg-neutral-100 border py-1 px-3 mb-3 rounded'>
          <div className="mb-1 text-md font-semibold" style={styles.heading}>
            {type?.name}
          </div>
          <div className='flex flex-wrap gap-2'>
            {type?.options.map((option, optionIndex) => (
              <label key={option?.name} style={styles.label}>
                <input
                  type='checkbox'
                  name={option?.name}
                  checked={option?.value}
                  onChange={(e) => handleOnChange(e, typeIndex, optionIndex)}
                  style={styles.input}
                />
                <span style={styles.span(option?.value)}>
                  {option?.value && <span style={styles.checkmark}>&#10003;</span>}
                </span>
                {option?.label}
              </label>
            ))}
          </div>
        </div>
      ))}
    </aside>
  );
};
