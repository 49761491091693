import {forwardRef} from 'react';
import * as Dialog from '@radix-ui/react-dialog';

export const Popup = forwardRef(({children}, forwardedRef) => {
  return (
    <>
      <Dialog.Portal>
        <Dialog.Overlay className="modal-overlay fixed inset-0 bg-black bg-opacity-50 z-20" />
        <Dialog.Content
          ref={forwardedRef}
          aria-describedby={undefined}
          className="modal-content fixed bottom-3 justify-center p-4 text-center w-full md:max-w-md lg:max-w-lg overflow-hidden
                align-middle shadow-xl transition-all duration-300 ease md:px-0 md:left-1/2 md:-translate-x-1/2 z-30">
          <div className="p-3 relative bg-white rounded-2xl">{children}</div>
        </Dialog.Content>
      </Dialog.Portal>
    </>
  );
});

export const CloseXButton = ({closeModal}) => {
  return (
    <div onClick={closeModal} className="absolute top-4 right-4 cursor-pointer text-gray-600">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={'h-7 w-7'}
        viewBox="0 0 20 20"
        fill="currentColor">
        <path
          fillRule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clipRule="evenodd"
        />
      </svg>
    </div>
  );
};
