import {useCartUI} from './CartSideCartUiProvider';
import CartIconWithItems from './CartIconWithItems';

export default function CartToggle({handleClick}) {
	const {openCart} = useCartUI();

	return (
		<button
			onMouseEnter={() => {
				openCart();
			}}
			onClick={(e) => {
				e.preventDefault();
				handleClick();
			}}>

			<CartIconWithItems />

			<span className='sr-only text-white'>Open cart</span>
		</button>
	);
}
