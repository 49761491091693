export const DeterminePageType = () => {
	const { pathname } = window.location
	const urlTypeMap = {
		'/collections/': 'Collection Page',
		'/products/': 'Product Page',
		'/pages/contact-us': 'Contact Page',
		'/pages/return-policy': 'Return Policy Page',
		'/pages/career-opportunities': 'Career Opportunities Page',
		'/pages/about-us': 'About Us Page',
		'/pages/california-prop65-warning': 'Prop65Warning Page',
		'/pages/frequently-asked-questions': 'FAQ Page',
		'/pages/privacy-policy': 'Privacy Policy Page',
		'/pages/warranty-policy': 'Warranty Policy Page',
		'/pages/financing': 'Financing Page',
		'/reviews/sku': 'Review Product Form Page',
		'/community/dealer-locator': 'Dealer Locator Page',
		'/cart': 'Cart Page',
		'/account/login': 'Account Login Page',
		'/account': 'Account Page',
	}
	for (let [key, val] of Object.entries(urlTypeMap)) {
		if (pathname.includes(key)) {
			return val
		}
	}
	if (pathname === '/') {
		return 'Home Page'
	}
	return 'Unknown Page Type'
}
