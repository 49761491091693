import CartIconItemSmall from '@image/icons/png-icons/CartItemsInCartIcon.png';
import CartIconNoItemSmall from '@image/icons/png-icons/CartNoItemsInCart.png';
import {useEffect, useState} from 'react';
import {useDatabaseCartProviderV3} from '@components/cart/CartProvider.jsx';
import {CartIcon} from '@components/widgets/Ui';

const CartIconWrapper = ({totalQuantity}) => {
  const cartImageSrc = totalQuantity > 0 ? CartIconItemSmall : CartIconNoItemSmall;

  return (
    <div className={`w-[24px] lg:w-[32px] hover:opacity-70 relative text-white`}>
      {/*<Image alt='Cart' src={cartImageSrc} loading='eager' />*/}
      <CartIcon/>

      {totalQuantity > 0 && (
        <div
          className={`flex mb-1 sm:mb-0.5 justify-center pointer-events-none select-none w-full
					text-gray-800 font-bold absolute z-50 top-0 text-[10px] lg:text-[12px]`}
          aria-hidden>
          {totalQuantity}
        </div>
      )}
      <span className='sr-only text-white'>{`Cart, ${totalQuantity} items`}</span>
    </div>
  );
};

export default function CartIconWithItems() {
  const [totalQuantity, setTotalQuantity] = useState(0);
  const {lineItems} = useDatabaseCartProviderV3();

  useEffect(() => {
    setTotalQuantity(lineItems?.reduce((acc, item) => acc + item?.quantity, 0) || 0);
  }, [lineItems]);

  return (
    <>
      <CartIconWrapper totalQuantity={totalQuantity}/>
    </>
  );
}
