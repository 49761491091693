import {siteSettingsV2} from '@helpers/config-master.mjs';
import {getEndOfDay, useCountdown} from '@components/widgets/CountdownTimer/useCountdownTimer';

export const CountdownTimer = () => {
  const saleAttributes = siteSettingsV2.getSaleSettings();
  const saleIsActive = saleAttributes.saleIsActive;
  const showCountdownTimer = saleAttributes?.layoutSettings?.showCountdownTimer;
  // console.log({saleAttributes})
  const saleExpiresAt = getEndOfDay();

  const [days, hours, minutes, seconds] = useCountdown(saleExpiresAt, saleIsActive);

  if (saleIsActive && showCountdownTimer) {
    return <ShowCounter days={days} hours={hours} minutes={minutes} seconds={seconds} />;
  } else {
    return null;
  }
};

const ShowCounter = ({days, hours, minutes, seconds}) => {
  const daysIsDanger = days <= 2 && days > 1;

  const hourText = hours <= 1 ? 'Hour' : 'Hours';

  const isValidCountdownTime = days + hours + minutes + seconds > 0;
  return (
    <div className='sale-countdown-timer h-[80px] lg:h-[100px]'>
      {isValidCountdownTime ? (
        <>
          <div className='sale-label'>Sale Ends:</div>
          {/* Updated text */}
          {/* Your existing countdown timer */}
          <div className='countdown-link shadow-xl'>
            <DateTimeDisplay value={days} type={'Days'} isDanger={daysIsDanger} />
            <DateTimeDisplay value={hours} type={hourText} isDanger={hours <= 24} />
            <DateTimeDisplay value={minutes} type={'Mins'} isDanger={false} />
            <DateTimeDisplay value={seconds} type={'Seconds'} isDanger={false} />
          </div>
        </>
      ) : null}
    </div>
  );
};

const DateTimeDisplay = ({value, type, isDanger}) => {
  const zeroStyle = value === 0 && type === 'Days' ? {color: 'rgba(238,237,237,0.73)'} : {};
  return (
    <div className={isDanger ? 'countdown danger' : 'countdown'}>
      <div style={zeroStyle}>{value}</div>
      <span style={zeroStyle}>{type}</span>
    </div>
  );
};

const ExpiredNotice = () => {
  return (
    <div className='expired-notice'>
      <span>Sorry, our sale has ended!</span>
      <p>Check back soon for new savings & deals.</p>
    </div>
  );
};
