import Image from 'next/image';
import Link from 'next/link';
import {RenderDropDownMenus} from './DropdownComponents.jsx';
import SkarAudioLogoWhiteMedium from '@image/logos/skar-audio-logo-white-medium.webp';

// NavigationMenu @ADA via ChatGPT ✔️
export default function NavigationMenu({menu, showNav}) {
  if (!menu || !menu?.left) {
    return null;
  }

  return (
    <nav className='flex flex-row justify-around items-center text-white' aria-label='Skar Audio'>
      {showNav && (
        <ul className='flex flex-row justify-end flex-wrap basis-2/5' role='menu'>
          <RenderDropDownMenus categories={menu?.left?.categories} />
        </ul>
      )}

      <div>
        <Link prefetch={false} href='/' title={'Click to go to homepage'}>
          <div className='block w-[205px] h-[63px] transition-all ease-in-out duration-500 mx-auto'>
            <Image
              src={SkarAudioLogoWhiteMedium}
              alt='Skar Audio Website Header Logo'
              style={{objectFit: 'contain'}}
              loading='eager'
            />
          </div>
        </Link>
      </div>

      {showNav && (
        <ul className='flex flex-row justify-start flex-wrap basis-2/5' role='menu'>
          <RenderDropDownMenus categories={menu?.right?.categories} right={true} />
        </ul>
      )}
    </nav>
  );
}
