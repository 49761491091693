import AccountIcon from '@image/icons/png-icons/CustomerAccountLoginHumanIcon.png';
import SupportIcon from '@image/icons/png-icons/CustomerSupportQuestionMarkIcon.png';
import {Fragment, useEffect, useRef, useState} from 'react';
import Image from 'next/image';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import {useUI} from '@components/widgets/UiProvider';
import {useRouter} from 'next/router';
import {useSession} from 'next-auth/react';
import cn from 'clsx';
// import {MobileSearchV2} from '@components/search-v2/MobileSearch-V2';

const MobileSearchV2 = dynamic(
  () => import('@components/search-v2/MobileSearch-V2').then((mod) => mod.MobileSearchV2),
  {
    ssr: false
  }
);

export default function DrawerMenu({menus}) {
  return <DrawerMenuInside menus={menus} />;
}

function DrawerMenuInside({menus}) {
  // const {results, showSearchBoxResults, setShowSearchBoxResults, onResultClick, onViewAllResults} =
  //   useSearch();
  //
  const [showSearchBoxResults, setShowSearchBoxResults] = useState(false);
  const {displayMobileNav, openMobileNav, closeMobileNav} = useUI();
  const [level2Menu, setLevel2Menu] = useState();
  const [level3Menu, setLevel3Menu] = useState();
  const [openLevel, setOpenLevel] = useState(null);

  const setIsOpen = (x) => (x ? openMobileNav() : closeMobileNav());

  useEffect(() => {
    if (!displayMobileNav) {
      setLevel2Menu(null);
      setLevel3Menu(null);
      setOpenLevel(1);
    }
  }, [displayMobileNav]);

  return (
    <div>
      <div
        className={cn(
          'fixed inset-0 top-[50px] z-40 nav-height w-full overflow-hidden',
          'transition-transform  mobile-nav-bg duration-500 overflow-x-hidden',
          {
            hidden: !displayMobileNav,
            'transform-none': displayMobileNav
          }
        )}>
        <MobileSearchV2
          setIsOpen={setIsOpen}
          showSearchBoxResults={showSearchBoxResults}
          setShowSearchBoxResults={setShowSearchBoxResults}
        />

        {/*<MobileSearchBarInsideDrawer*/}
        {/*  setIsOpen={setIsOpen}*/}
        {/*  hideResults={() => setShowSearchBoxResults(false)}*/}
        {/*/>*/}

        {/*{showSearchBoxResults && (*/}
        {/*  <SearchResultsBoxMobileV2*/}
        {/*    results={results}*/}
        {/*    closeMobileNav={closeMobileNav}*/}
        {/*    onViewAllResults={async (sku) => {*/}
        {/*      setIsOpen(false);*/}
        {/*      await onViewAllResults(sku, {*/}
        {/*        searchBoxSource: 'mobile-menu'*/}
        {/*      });*/}
        {/*    }}*/}
        {/*    onResultClick={async (sku, {clickedSkuPath}) => {*/}
        {/*      // console.log('[DrawerMenu] onResultClick:', sku, clickedSkuPath);*/}
        {/*      setIsOpen(false);*/}
        {/*      await onResultClick(sku, {*/}
        {/*        searchBoxSource: 'mobile-menu',*/}
        {/*        clickedSkuPath: clickedSkuPath*/}
        {/*      });*/}
        {/*    }}*/}
        {/*  />*/}
        {/*)}*/}

        {/*{!showSearchBoxResults && (*/}
        <div
          className={`w-full flex flex-col justify-between absolute inset-0 top-[60px] z-10 ${
            openLevel === 1 ? 'h-[calc(100dvh-60px)] ' : 'h-full '
          }`}>
          <div className="h-[calc(100%-60px)] relative">
            <MenuItems
              items={menus}
              setIsOpen={setIsOpen}
              level2Menu={level2Menu}
              setLevel2Menu={setLevel2Menu}
              level3Menu={level3Menu}
              setLevel3Menu={setLevel3Menu}
              openLevel={openLevel}
              setOpenLevel={setOpenLevel}
            />
          </div>

          {openLevel === 1 && (
            <div className="fixed bottom-0 left-0 w-full">
              <Footer onClose={() => setIsOpen(false)} />
            </div>
          )}
        </div>
        {/*)}*/}
      </div>
    </div>
  );
}

const MenuItems = ({
  items,
  setIsOpen,
  level2Menu,
  setLevel2Menu,
  level3Menu,
  setLevel3Menu,
  openLevel,
  setOpenLevel
}) => {
  const menuRef = useRef(null);
  useEffect(() => {
    if (openLevel) {
      menuRef.current.dataset.level = openLevel.toString();
      // scroll to top
    }
  }, [openLevel]);

  // console.log("[Mobile Menu Items]:", items);

  const dampeningMenuItem = {
    id: '100800001',
    slug: '/products/SK2MM-car-sound-deadening',
    title: 'Sound Deadening',
    prefetch: false
  };

  const batteriesMenuItem = {
    id: '100900000',
    slug: '/collections/car-audio-batteries',
    title: 'Car Audio Batteries',
    prefetch: false
  };

  const proAudioMenuItem = {
    id: '100900005',
    slug: '/collections/professional-audio',
    title: 'Pro Audio & Live Sound',
    prefetch: false
  };

  items = [...items, dampeningMenuItem, batteriesMenuItem, proAudioMenuItem];

  return (
    <div ref={menuRef} className="w-full h-full overflow-hidden transform" id="drawer-main-menu">
      <div className="w-[300vw] h-full transform transition-transform duration-500 grid grid-cols-3">
        <div data-item-level="1">
          <div className="h-[calc(100dvh-60px)] overflow-hidden overflow-y-auto pb-40">
            {items.map((level1, index) => {
              const hasChildren = level1?.items && level1?.items?.length > 0;
              return (
                <Item
                  id={`mobile-${level1.id}`}
                  key={`level1-${level1?.id}-${index}`}
                  title={level1?.title}
                  subtitle={level1?.subtitle}
                  arrow={level1?.items && level1?.items?.length > 0}
                  slug={level1?.slug}
                  setIsOpen={setIsOpen}
                  handle={level1?.handle}
                  onClick={() => {
                    setLevel2Menu(level1);
                    setOpenLevel(2);
                  }}
                  {...(level1?.prefetch === false ? {prefetch: false} : {})}
                />
              );
            })}
          </div>
        </div>

        <div data-item-level="2">
          {level2Menu && (
            <div>
              <Title
                id={`mobile-parent-${level2Menu?.id}`}
                onClick={() => {
                  setOpenLevel(1);
                }}
                title={level2Menu?.title}
                subtitle={level2Menu?.subtitle}
              />

              <div className="h-[calc(100dvh-210px)] overflow-hidden overflow-y-auto pb-40">
                {level2Menu?.items?.map((level2) => {
                  return (
                    <div key={`level2-${level2.id}`} data-title={level2?.title}>
                      <Item
                        id={`mobile-${level2.id}`}
                        title={level2?.title}
                        subtitle={level2?.subtitle}
                        arrow={level2?.items && level2?.items?.length > 0}
                        slug={level2?.slug}
                        setIsOpen={setIsOpen}
                        handle={level2?.handle}
                        onClick={() => {
                          setLevel3Menu(level2);
                          setOpenLevel(3);
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>

        <div data-item-level="3">
          {level3Menu && (
            <div>
              <Title
                id={`mobile-parent-${level3Menu?.id}`}
                onClick={() => {
                  setOpenLevel(2);
                }}
                title={level3Menu?.title}
                subtitle={level3Menu?.subtitle}
              />
              <div className="h-[calc(100vh-210px)] overflow-hidden overflow-y-auto pb-40">
                {level3Menu?.items?.map((level3) => {
                  return (
                    <Fragment key={`level3-${level3?.sku ?? level3?.id}`}>
                      <Item
                        id={`mobile-${level3?.id}`}
                        title={level3?.title}
                        subtitle={level3?.subtitle}
                        arrow={false}
                        slug={level3.slug}
                        setIsOpen={setIsOpen}
                        handle={level3.handle}
                        level={3}
                      />
                    </Fragment>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const Item = ({
  id,
  onClick,
  title,
  subtitle,
  arrow,
  slug,
  setIsOpen,
  handle,
  level,
  prefetch = true
}) => {
  const router = useRouter();

  const isLevel3 = level === 3;
  const url = slug ?? '/collections/' + handle;

  const handleClick = async () => {
    if (isLevel3) {
      console.log(' >> Clicked inside level 3 mobile menu..', url);
      setIsOpen(false);
      await router.push(url);
    }
  };

  return (
    <div
      className="mobile-menu-item"
      // id={id}
      key={id}
      data-handle={handle}
      title={title}
      data-slug={slug}
      onClick={async (e) => {
        await handleClick();
      }}>
      <div className="mobile-menu-item-wrapper">
        <Link
          href={url}
          onClick={() => (isLevel3 ? null : setIsOpen(false))}
          className="mobile-menu-item-link"
          {...(prefetch === false ? {prefetch: false} : {})}>
          <span className="">{title}</span>
          {subtitle ? (
            <span className="text-gray-300 text-sm uppercase font-oswald">{`(${subtitle})`}</span>
          ) : null}
        </Link>

        {!isLevel3 && arrow && (
          <div className="mobile-menu-item-arrow" onClick={onClick}>
            <button aria-label={title}>{arrow && <ArrowRightIcon />}</button>
          </div>
        )}
      </div>
    </div>
  );
};

const Title = ({id, onClick, title, subtitle}) => {
  return (
    <div
      className="border-b-2 border-neutral-900 relative cursor-pointer w-[100vw]"
      key={id}
      onClick={onClick}>
      <div className="absolute left-[17px] top-[24px] rotate-180 ">
        <ArrowRightIcon />
      </div>
      <button
        style={{marginBottom: 2}}
        className="group h-[60px] px-4 text-white text-xl uppercase flex flex-col items-center justify-center font-oswald w-full">
        {title}
        {subtitle ? (
          <span className=" text-gray-300 text-sm uppercase font-oswald pl-2">{` (${subtitle})`}</span>
        ) : null}
      </button>
    </div>
  );
};

const Footer = ({onClose}) => {
  const {data: session} = useSession();
  const isLoggedIn = session && !!session?.user?.email ? true : false;

  return (
    <div className="w-full flex bg-[#707575]">
      <Link
        href={isLoggedIn ? '/account' : '/account/login'}
        onClick={onClose}
        className="flex px-6 py-6 text-white justify-center items-center font-oswald border-r-2 border-black w-8/12">
        <div className="h-7 mr-2">
          <Image alt="Account Icon" src={AccountIcon} width={28} height={28} />
        </div>
        {isLoggedIn ? 'MY ACCOUNT' : 'LOGIN TO ACCOUNT'}
      </Link>
      <Link
        href={'/pages/contact-us'}
        className="flex px-6 py-6 justify-center text-white items-center font-oswald w-5/12">
        <div className="h-7 mr-2 w-7">
          <Image alt="Support Icon" src={SupportIcon} width={36} height={36} />
        </div>
        SUPPORT
      </Link>
    </div>
  );
};

function ArrowRightIcon({className}) {
  return (
    <svg
      className={className}
      width="9"
      height="14"
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.999762 12C0.743762 12 0.487762 11.902 0.292762 11.707C-0.0982383 11.316 -0.0982383 10.684 0.292762 10.293L4.58576 6.00001L0.292762 1.70701C-0.0982383 1.31601 -0.0982383 0.684006 0.292762 0.293006C0.683762 -0.0979941 1.31576 -0.0979941 1.70676 0.293006L6.70676 5.29301C7.09776 5.68401 7.09776 6.31601 6.70676 6.70701L1.70676 11.707C1.51176 11.902 1.25576 12 0.999762 12Z"
        fill="white"
      />
    </svg>
  );
}
