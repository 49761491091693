import {PushPopup} from './PushNotificationPopup';
import {useEffect} from 'react';
import {useUI} from '@components/widgets/UiProvider';
import {registerServiceWorker, shouldTriggerPushPopup} from '@helpers/marketing/push/helpers.mjs';

const PUSH_NOTIFICATIONS_DELAY = process.env.NEXT_PUBLIC_PUSH_NOTIFICATIONS_DELAY || 8000;

export const PushNotificationsWrapper = () => {
  const {openPushModal} = useUI();

  useEffect(() => {
    try {
      if ('serviceWorker' in navigator) {
        // console.log("[PushNotificationWrapper][useEffect] >> Registering New Service Worker..")
        registerServiceWorker().catch((err) => {
          console.error('Failed to register service worker:', err);
        });

        // Check if there is an update to the service worker
        // navigator.serviceWorker.addEventListener('controllerchange', () => {
        //   updatePushServiceWorker()
        //   window.location.reload()
        // })

        // Listen to service worker messages sent via postMessage()
        navigator.serviceWorker.addEventListener('message', (event) => {
          if (!event.data.action) {
            return;
          }

          switch (event.data.action) {
            case 'redirect-from-notificationclick':
              window.location.href = event.data.url;
              break;
          }
        });

        const timer = setTimeout(async () => {
          const should_trigger = shouldTriggerPushPopup();
          if (should_trigger) {
            openPushModal();
          }
        }, PUSH_NOTIFICATIONS_DELAY);

        // Clear timeout if the component is unmounted
        return () => clearTimeout(timer);
      } else {
        console.warn('Push messaging is not supported');
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [openPushModal]);

  return <PushPopup />;
};
