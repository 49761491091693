import {useEffect, useState} from 'react';

export default function DelayComponentVisible({delay = 100, children}) {
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => {
			setIsVisible(true);
		}, delay);

		return () => {
			clearTimeout(timer);
		};
	}, []);

	return isVisible ? children : null;
}
