import {consoleLogRedWhite} from '../common/console-log-colors.mjs';

export const filterOutMarineSubwooferProducts = (products) => {
  products = products?.filter(product => !product?.sku?.startsWith('SKM8'));
  products = products?.filter(product => !product?.sku?.startsWith('SKM10'));
  return products;
}

export const filterOutSingleProductFocusProductPages = (products) => {
  products = products?.filter(product => !product?.sku.startsWith('SK2MM'));

  return products;
}

export const filterForSkuInRows = (products, sku) => {
  return sku ? products?.filter(row => row?.sku === sku) : products;
}

export const filterForSkuInSkus = (skus, sku) => {
  return sku ? skus?.filter(skuFromList => skuFromList === sku) : skus;
}

export const filterProductsWithoutPrice = (products = []) => {
  return products?.filter(product => product?.price ?? product?.price_map > 0) || null;
}

export const filterOutNonEnabledOnSiteProducts = (products) => {
  return products?.filter(product => product?.enabled_on_site === 1);
};

export const filterProductsWithoutMainImage = (products = []) => {
  return products?.filter(product => !!product?.image_1) || null;
}

export const filterProductsWithoutImages = (products = []) => {
  return products?.filter(product => (product?.images?.length > 0 || !!product?.imageUrl)) || null;
}

export const filterProductsWithoutHandle = (products = []) => {
  return products?.filter(product => product?.site_handle) || null;
}

export const filterProductsWithoutQuantity = (products = []) => {
  return products?.filter(product => product?.currentlyInStock) || null;
}

export const filterOutProductsThatAreDiscontinued = (products = []) => {
  return products?.filter(product => {
    const discontinuedIsNull = product?.is_discontinued === null;
    const discontinuedIsFalse = product?.is_discontinued === 0;
    const isNotDiscontinued = discontinuedIsNull || discontinuedIsFalse

    const discontinuedIsTrue = product?.is_discontinued === 1;
    return isNotDiscontinued && !discontinuedIsTrue;
  })
}

export const filterOutProductsThatAreDiscontinuedAndNotEnabledOnSite = (products = []) => {
  return products?.filter(product => {
    const sku = product?.sku;
    const isDiscontinuedSetToTrue = product?.is_discontinued === 1;
    const isDisabledOnSiteSetToTrue = product?.enabled_on_site === 0;
    const areBothSetToDisabled = isDiscontinuedSetToTrue && isDisabledOnSiteSetToTrue;
    if (areBothSetToDisabled) {
      // consoleLogRedWhite(' > [Discontinued & Disabled On Site]:', sku);
    }
    return !areBothSetToDisabled;
  });
};

export const filterOutProductsThatAreNotEnabledOnSite = (products = []) => {
  return products?.filter(product => product?.enabled_on_site === 1) || null;
}

export const filterOutHiddenCartItemsSkus = (lineItems) => {
  const hiddenSkus = ['DONATION'];
  return lineItems?.filter((item) => !hiddenSkus.includes(item.sku));
};

export const filterProducts = (products = [], filterConfig = {}, filterState = {}) => {
  // console.log('[filterProducts][filterState]:', {filterState});
  const customFilters = ['vehicle_make', 'vehicle_model', 'vehicle_year'];
  try {
    return products?.filter(product => {
      if (!product ||
        // !product.sku.includes('TOY') || // @TODO - TEST ONLY
        !product?.price ||
        !(product?.images?.length > 0) ||
        !product?.slug ||
        !(product.currentlyInStock === true)) {
        return false;
      }

      if (filterState?.vehicle_make && !filterByVehicleMake(filterState, product)) return false;
      if (filterState?.vehicle_model && !filterByVehicleModel(filterState, product)) return false;
      if (filterState?.vehicle_year && !filterByVehicleYear(filterState, product)) return false;

      for (const filterSection of filterConfig?.filters || []) {
        if (!filterState[filterSection.dbField] || customFilters.includes(filterSection.dbField)) continue;
        if (!filterProductsUsing(product, filterSection, filterState)) return false;
      }
      return true;
    }) || null;
  } catch (e) {
    consoleLogRedWhite('[Error][Vehicle Specific Filters]:', e?.message);
    console.trace(e.request);
  }
};

export const filterProductsUsing = (product, filterSection = {}, filterState = {}) => {
  if (!filterSection?.propName || !product?.properties) return true;

  const oneFilterState = filterState[filterSection.dbField]
  if (!oneFilterState) return true;

  const selectedKeys = Object.keys(oneFilterState).filter(key => oneFilterState[key]);
  if (selectedKeys?.length === 0) return true;

  if (filterSection.filterProduct) {
    return filterSection.filterProduct(product, oneFilterState, filterSection);
  } else {
    return filterByPropName(filterSection, selectedKeys, product);
  }
}

export const filterByPropName = (filterSection = {}, selectedKeys = [], prod = {}) => {
  // console.log("[Product Properties]:", prod.properties);
  const productPropertyValues = String(prod.properties[filterSection.propName]).split('/')
  return productPropertyValues.some(value => selectedKeys.includes(value))
}

export const filterByVehicleMake = (filterState, prod) => {
  const Make = Object.entries(filterState.vehicle_make ?? {}).filter(([key, val]) => val).map(([key]) => key)
  return Make.length ? String(prod.properties.Make).split('/').includes(Make[0]) : true
}

export const filterByVehicleModel = (filterState, prod) => {
  let filterStateModel = Object.entries(filterState.vehicle_model ?? {})?.[0]?.[0]
  console.log('[filterByVehicleModel][filterStateModel]:', {filterStateModel})

  // const Model = filterStateModel.filter(([key, val]) => val).map(([key]) => key)
  // console.log('[filterByVehicleModel][Model]:', {Model})

  const prodProperties = prod.properties;
  const prodPropModel = String(prodProperties.Model).replace('_', ' ');
  const prodPropTrim = prodProperties?.Trim;

  // console.log('[filterByVehicleModel][prodPropModel]:', {prodPropModel})
  // console.log('[filterByVehicleModel][prodPropTrim]:', {prodPropTrim})
  const prodModelAndTrim = `${prodPropModel} ${prodPropTrim}`
  console.log('[filterByVehicleModel][prodModelAndTrim]:', {prodModelAndTrim})
  const areProdAndFilterSelectionMatch = prodModelAndTrim === filterStateModel;
  console.log('[filterByVehicleModel][areProdAndFilterSelectionMatch]:', areProdAndFilterSelectionMatch)
  return areProdAndFilterSelectionMatch;
}

export const filterByVehicleYear = (filterState, prod) => {
  let VehicleYear = typeof filterState.vehicle_year === 'object'
    ? Object.entries(filterState.vehicle_year).find(([key, val]) => val)[0]
    : Number(filterState.vehicle_year)

  if (VehicleYear) {
    let startYear = Number(prod.properties['Start Year'])
    let endYear = Number(prod.properties['End Year'])
    return VehicleYear >= startYear && VehicleYear <= endYear
  }
  return true
}

// export const enableTestFilteringBySkuLike = (products) => {
//   if (!skuLike){
//     return products
//   } else {
//     products = products.filter(x => x.sku.includes(skuLike))
//     console.log((chalk.yellowBright('Total SKU\'s after Test Filter: ')),
//       products.length)
//     return products
//   }
// }

export function sortProducts(filteredProducts, filterConfig) {
  // console.log({filterConfig});
  const filters = filterConfig?.filters ?? [];
  filteredProducts.sort((a, b) => {
    for (let {dbField, options} of filters) {
      options = typeof options === 'object' ? Object.keys(options) : [];
      // console.log(options);
      const aa = a?.[dbField]
      const bb = b?.[dbField]
      const aIndex = options.findIndex(x => x === aa);
      const bIndex = options.findIndex(x => x === bb);
      if (aIndex >= 0 && bIndex >= 0) {
        const sortResult = aIndex - bIndex;
        if (sortResult !== 0) { // otherwise next filter
          return sortResult;
        }
      }
    }
    return 0;
  });
  return filteredProducts;
}
