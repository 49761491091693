import {useEffect, useRef} from 'react';
import {useInView} from 'react-intersection-observer';

export const InfiniteScroll = ({loadMore}) => {
  const [ref, inView] = useInView({
    triggerOnce: true
  });

  const loadMoreCalled = useRef(false);

  useEffect(() => {
    if (inView && !loadMoreCalled.current) {
      loadMore();
      loadMoreCalled.current = true;
      setTimeout(() => {
        loadMoreCalled.current = false;
      }, 500);
    }
  }, [inView, loadMore]);

  return <div ref={ref} style={{height: '10px'}} />;
};
