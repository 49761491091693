import React from 'react';
import Head from 'next/head';
import {
  baseUrl,
  GenerateOpenGraphPageMetaData,
  GenerateStaticPageMetaData
} from '@components/seo/MetaDataDefaults';

export const HomePageSeo = () => {
  const title = 'Skar Audio - Car Audio Subwoofers, Amplifiers, Speakers and more'
  const description = 'Skar Audio is a leader in car audio amplifiers, subwoofers, speakers, and more. We focus on the innovation and development of new, superior, car audio products.'
  const url = baseUrl;
  const image = `${baseUrl}/image/logos/skar-audio-logo-black-large.png`

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta charSet="UTF-8"/>
        <meta name="keywords"
              content="car audio, car speakers, car amplifiers, car subwoofers, car stereo"/>
        <meta name="description" content={description}/>
        <link rel="canonical" href={baseUrl}/>

        <GenerateStaticPageMetaData/>
        <GenerateOpenGraphPageMetaData
          title={title}
          url={url}
          description={description}
          image={image}
        />
      </Head>
    </>
  );
}
