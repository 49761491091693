import {Children, cloneElement, forwardRef} from 'react';

const mapChildren = (children, props) => {
  return Children.map(children, (child) => cloneElement(child, props));
};

export const InputWithFloatingLabel = forwardRef(({id, inputProps, placeholder}, ref) => (
  <FormElementWithFloatingLabel id={id} placeholder={placeholder}>
    <input ref={ref} id={id} placeholder={placeholder} {...inputProps} />
  </FormElementWithFloatingLabel>
));

export const WrapFloatingLabel = forwardRef(({id, placeholder, hasError, children}, ref) => (
  <FormElementWithFloatingLabel id={id} placeholder={placeholder} hasError={hasError}>
    {mapChildren(children, {ref, id, placeholder})}
  </FormElementWithFloatingLabel>
));

export function FormElementWithFloatingLabel({
                                               id,
                                               placeholder,
                                               children,
                                               showLabel = true,
                                               style,
                                               hasError = false
                                             }) {
  return (
    <div className='flex flex-col items-start h-[44px] md:h-[42px]' style={style}>
      {mapChildren(children, {
        className: `text-[18px] peer px-2 py-1 md:py-1.5 w-full border border-1
                 placeholder-transparent bg-white dark:bg-white focus:border-1 rounded
                 focus:outline-0 focus:outline-transparent disabled:bg-gray-300
                 ${hasError ? 'border-rose-600' : 'border-slate-600'}
                 `
      })}

      {showLabel && (
        <label
          htmlFor={id}
          className={`text-xs
              ml-1 mt-[-3.2rem] pt-[0.15rem]
              md:mt-[-3.2rem] md:pt-[0.15rem]
              px-2 bg-white
              peer-placeholder-shown:text-gray-500
              peer-placeholder-shown:mt-[-2.2rem]
              peer-placeholder-shown:ml-1
              peer-placeholder-shown:pt-2.2
              peer-placeholder-shown:text-base
              peer-placeholder-shown:bg-transparent
              duration-200 pointer-events-none
              ${hasError ? 'text-rose-600' : 'text-gray-800'}`}>
          <span>{placeholder}</span>
        </label>
      )}
    </div>
  );
}
