import * as EmailValidator from 'email-validator';

export const formatValidateAndCorrectEmailV3 = (email) => {
  if (!email || email === '') {
    return {
      email: null,
      valid: false
    };
  }

  email = email?.replaceAll(' ', '');
  if (email.startsWith('mailto:')) {
    email = email.replace('mailto:', '');
  }
  if (email?.startsWith('.')) {
    email = email.substring(1);
  }

  const commonMistakes = {
    // Gmail variations
    gmial: 'gmail',
    gmlia: 'gmail',
    gmaill: 'gmail',
    gmaii: 'gmail',
    gmall: 'gmail',
    gmaeil: 'gmail',
    gmaul: 'gmail',
    gmiall: 'gmail',
    gemial: 'gmail',
    gmeil: 'gmail',
    gaiml: 'gmail',
    gmiail: 'gmail',
    gmil: 'gmail',
    gmailc: 'gmail',
    gmali: 'gmail',
    gamil: 'gmail',
    gemail: 'gmail',
    gmeail: 'gmail',
    gmael: 'gmail',
    gnmail: 'gmail',
    gamail: 'gmail',

    // Hotmail variations
    hotmale: 'hotmail',
    hotmael: 'hotmail',
    hotmal: 'hotmail',
    hotmil: 'hotmail',
    hotnial: 'hotmail',
    hotmaill: 'hotmail',
    hotmaul: 'hotmail',
    hotmali: 'hotmail',
    hotmaiol: 'hotmail',
    hootmail: 'hotmail',
    hotrmail: 'hotmail',
    hotmmail: 'hotmail',
    hotemail: 'hotmail',
    htoamil: 'hotmail',
    homtail: 'hotmail',
    hotamil: 'hotmail',
    hotmila: 'hotmail',
    hotmial: 'hotmail',

    // Yahoo variations
    yaho: 'yahoo',
    yhaoo: 'yahoo',
    yaoo: 'yahoo',
    yahho: 'yahoo',
    yaoho: 'yahoo',
    yahoio: 'yahoo',
    yauhoo: 'yahoo',
    yaahoo: 'yahoo',
    yahhoo: 'yahoo',
    yahool: 'yahoo',
    yahuoo: 'yahoo',
    yah00: 'yahoo',

    // Comcast variations
    comast: 'comcast',

    // Outlook variations
    outloook: 'outlook',
    outllok: 'outlook',
    outook: 'outlook',
    otlook: 'outlook',
    outlookk: 'outlook',
    ooutlook: 'outlook',

    // AOL variations
    aoll: 'aol',
    aolll: 'aol',
    aool: 'aol',
    alol: 'aol',
    aoil: 'aol',

    // iCloud variations
    iclod: 'icloud',
    iclud: 'icloud',
    icould: 'icloud',
    iclouds: 'icloud',
    icluod: 'icloud',

    // ProtonMail variations
    protonamil: 'protonmail',
    protomail: 'protonmail',
    protonmaill: 'protonmail',
    protonail: 'protonmail',
    prtonmail: 'protonmail',
    protomnail: 'protonmail',

    // Zoho Mail variations
    zohomail: 'zohomail',
    zohoemail: 'zohomail',
    zoohmail: 'zohomail',
    zohomil: 'zohomail',
    zohomale: 'zohomail',
    zohomaill: 'zohomail'
  };

  const commonTLDsMistakes = {
    cm: 'com',
    om: 'com',
    net: 'net',
    org: 'org'
  };

  email = email?.toLowerCase().trim();

  const parts = email?.split('@');
  if (parts?.length !== 2) {
    // console.log('[Validating Email V3][email]:', `[${email}]`, 'Invalid Email');
    return {
      email: email,
      valid: false
    };
  }

  const localPart = parts?.[0];
  let domainParts = parts?.[1]?.split('.');
  // console.log("[commonTLDsMistakes] domainParts 1:", domainParts)
  // Correct the domain if there's a common mistake
  if (domainParts?.length > 1) {
    const domainWithoutTLD = domainParts?.slice(0, -1).join('.');
    if (commonMistakes?.[domainWithoutTLD]) {
      domainParts = [commonMistakes?.[domainWithoutTLD], ...domainParts.slice(-1)];
      // console.log("[commonTLDsMistakes] domainParts 2:", domainParts)
    }

    // Correct TLD if there's a common mistake
    const tld = domainParts?.[domainParts?.length - 1];
    // console.log("[commonTLDsMistakes] tld:", tld)
    if (commonTLDsMistakes?.[tld]) {
      // console.log("[commonTLDsMistakes]:", commonTLDsMistakes?.[tld])
      domainParts[domainParts?.length - 1] = commonTLDsMistakes[tld];
    }
  }

  email = `${localPart}@${domainParts?.join('.')}`;
  // console.log("[commonTLDsMistakes] email:", email)
  // Email pattern to validate the corrected email
  const emailPattern = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
  if (!emailPattern.test(email)) {
    const atSymbolIndex = email.indexOf('@');
    if (atSymbolIndex !== -1) {
      if (email?.charAt(atSymbolIndex + 1) === '.' || email?.charAt(atSymbolIndex - 1) === '.') {
        email = email.replace(/\.@|@\./, '@');
      }
    }
    email = email.replace(/\s/g, '');
  }

  if (email?.includes('@gmail') && !email.endsWith('@gmail.com')) {
    email = email.replace(/@gmail(\.co)?$/, '@gmail.com');
  }
  if (email.endsWith('gmail.c')) {
    return `${email}om`;
  }

  email = makeEmailLowerCase(email);
  const emailValid = EmailValidator.validate(email);
  // console.log({emailValid});
  if (!emailValid) {
    console.log('[Validating Email V3][email]:', `[${email}]`, 'Invalid Email');
    return {
      email: email,
      valid: false
    };
  }

  return {
    email: email,
    valid: true
  };
};


export const formatAndValidateEmailV2 = (email) => {
  if (!email) {
    return {
      email: null,
      valid: false
    };
  }

  const makeEmailLowerCase = (email) => {
    if (!email) {
      return;
    }
    return email?.toLowerCase();
  };

  const commonMistakes = {
    'gnail': 'gmail',
    'gamil': 'gmail',
    'hotnail': 'hotmail'
  };
  email = email.toLowerCase().trim();
  const emailPattern = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;

  const domain = email.split('@')[1];
  if (commonMistakes[domain]) {
    email = email.replace(domain, commonMistakes[domain]);
  }

  if (!emailPattern.test(email)) {
    const atSymbolIndex = email.indexOf('@');
    if (email.charAt(atSymbolIndex + 1) === '.' || email.charAt(atSymbolIndex - 1) === '.') {
      email = email.replace(/\.@|@\./, '@');
    }
    email = email.replace(/\s/g, '');
  }
  email = makeEmailLowerCase(email);
  const emailValid = EmailValidator.validate(email);
  if (!emailValid) {
    console.log('  [Validating Email V2][email]:', `[${email}]`, 'Invalid Email');
    return {
      email: email,
      valid: false
    };
  }
  return {
    email: email,
    valid: true
  };
};


export const makeEmailLowerCase = (email) => {
  if (!email) {
    return;
  }
  return email?.toLowerCase();
};


// const validatePhone = async (e) => {
//   let form = handleFormChange(e);
//   const { phone } = form;
//
//   if (!phone || phone === "") {
//     setFormErrors({ ...formErrors, phone: "Value is required" });
//   } else {
//     let validPhone = await validatePhoneAPI(phone);
//     if (!validPhone) {
//       setFormErrors({ ...formErrors, phone: "Enter a valid phone number" });
//     } else {
//       setForm({ ...form, phone: validPhone }); // update to better phone number
//       setFormErrors({ ...formErrors, phone: null });
//     }
//   }
// };
