import styles from './review-photos.module.css';
import * as Dialog from '@radix-ui/react-dialog';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import ReviewIndividualV2 from '@components/product/reviews/review-photos/review-individual-v2';
import {useEffect} from 'react';
import {useRouter} from 'next/router';
import {useOnClickOutside} from '@helpers/hooks/hooks';
import {longDate} from '@helpers/common/date.mjs';

export const ReviewPopupIndividualV2 = ({currentReview, show, close, initialImageIndex = 0}) => {
  const router = useRouter();

  const ref = useOnClickOutside((e) => {
    close();
  });

  useEffect(() => {
    const isDialogHashPresent = () => window.location.hash === '#reviewPopup';

    const closeDialog = () => {
      if (isDialogHashPresent()) {
        // console.log('dialog onClosed v2');
        const newPath = router.asPath.split('#')[0];
        router.push(newPath, undefined, {shallow: true});
      }
      close();
    };

    if (show && !isDialogHashPresent()) {
      // console.log('dialog onOpened v2');
      window.history.pushState(null, null, '#reviewPopup');
    }

    const handlePopState = () => {
      if (!isDialogHashPresent()) {
        closeDialog();
      }
    };

    // Listen for popstate events (back button or history navigation)
    window.addEventListener('popstate', handlePopState);

    // Cleanup function to revert URL and remove event listener
    return () => {
      window.removeEventListener('popstate', handlePopState);
      // Ensure the dialog is closed and the URL is clean when the component unmounts or the dialog is closed
      closeDialog();
    };
  }, [show]);

  const shouldShowModal = show && currentReview ? true : false;
  // console.log({show, currentReview, shouldShowModal});

  const dateOfReview = currentReview?.review_date ? longDate(currentReview?.review_date) : '';
  const nameOfReviewer = currentReview?.reviewer?.name
    ? `By ${currentReview.reviewer.name.split(' ')[0]} on ${dateOfReview}`
    : '';

  return (
    <Dialog.Root open={shouldShowModal}>
      <Dialog.Portal>
        <Dialog.Overlay className="modal-overlay fixed inset-0 fade-in-white-to-black-mobile md:bg-black md:bg-opacity-25 z-20" />
        <Dialog.Content
          aria-describedby={undefined}
          className="modal-content fixed flex h-full w-full top-0 left-0 items-center justify-center z-30 text-black">
          <VisuallyHidden.Root asChild>
            <Dialog.Title className="hidden">{`Review ${nameOfReviewer}`}</Dialog.Title>
          </VisuallyHidden.Root>
          <div
            className={`${styles.popup} ${styles['popup-delay-mobile']} ${styles['popup-individual']} fade-in relative z-50`}>
            <div
              ref={ref}
              className={`flex flex-col w-full h-full md:py-6 md:rounded-md md:bg-white align-middle
              shadow-xl overflow-y-auto lg:overflow-hidden`}>
              <ReviewIndividualV2
                review={currentReview}
                close={close}
                currentReviewImageIndex={initialImageIndex}
                isIndividual={true}
              />
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
