import cn from 'clsx';
import {useEffect, useRef, useState} from 'react';
import {HStack, Spinner} from '@components/widgets/Common';

export const QuantitySelector = ({
  value,
  handleChange,
  max = 10,
  size = 5,
  textSize = 'text-sm',
  isUpdating
}) => {
  const [typedValue, setTypedValue] = useState(value);

  useEffect(() => {
    setTypedValue(value);
  }, [value]);

  const increase = () => {
    handleChange(value + 1);
  };

  const decrease = () => {
    handleChange(value - 1);
  };

  const inputRef = useRef(undefined);

  return (
    <div className='flex border rounded border-gray-300 items-center overflow-auto mt-2'>
      <button
        type='button'
        onClick={decrease}
        className={`disabled:pointer-events-all disabled:cursor-not-allowed bg-gray-100 h-full w-5 flex justify-center items-center`}
        aria-label={`Decrease quantity by 1`}
        style={{marginLeft: '-1px'}}
        disabled={value <= 1}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          className={`h-${size} w-${size} text-gray-400`}
          viewBox='0 0 20 20'
          fill='currentColor'>
          <path
            fillRule='evenodd'
            d='M5 10a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1z'
            clipRule='evenodd'
          />
        </svg>
      </button>

      <div className={cn('px-2 text-gray-900 text-center select-none w-[50px]', textSize)}>
        {isUpdating && (
          <HStack className='justify-center'>
            <Spinner size={16} />
          </HStack>
        )}
        {!isUpdating && (
          <input
            type='text'
            ref={inputRef}
            value={typedValue > 0 ? String(typedValue) : ''}
            aria-label={`Quantity`}
            onChange={(e) => {
              const newValue = Number(e.target.value);
              setTypedValue(newValue);
            }}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                const newValue = Number(e.target.value);
                if (newValue > 0) {
                  setTypedValue(newValue);
                  inputRef.current.blur();
                  handleChange(newValue);
                }
              }
              if (e.key === 'Escape') {
                setTypedValue(value); // reset
              }
            }}
            onBlur={(e) => {
              const newValue = Number(e.target.value);
              if (newValue > 0) {
                setTypedValue(newValue);
                handleChange(newValue);
              }
            }}
            className='text-center w-[100%] dark:bg-white'
            pattern='[1-9]+'
            style={{backgroundColor: 'white'}}
          />
        )}
      </div>

      <button
        type='button'
        onClick={increase}
        className={`disabled:pointer-events-all disabled:cursor-not-allowed bg-gray-100 h-full w-5 flex justify-center items-center`}
        aria-label={`Increase quantity by 1`}
        disabled={value < 1 || value >= max}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          className={`h-${size} w-${size} text-gray-400`}
          viewBox='0 0 20 20'
          fill='currentColor'>
          <path
            fillRule='evenodd'
            d='M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z'
            clipRule='evenodd'
          />
        </svg>
      </button>
    </div>
  );
};
