import {
  skuIsLoadedOrUnloadedVehicleSpecificEnclosure
} from '../../helpers/products/vehicle-specific.mjs';

export const generateBreadCrumbData = (product, selectedVariant, matchingRelatedVariant) => {
  // console.log('[generateBreadCrumbData] product:', product);
  // console.log('[generateBreadCrumbData] selectedVariant:', selectedVariant);
  // console.log('[generateBreadCrumbData] matchingRelatedVariant:', matchingRelatedVariant);

  const productType = product?.product_type;
  let sku = selectedVariant?.sku;
  if (productType === 'variant') {
    const isSubWithCoil = sku?.includes(' D1') || sku?.includes(' D2') || sku?.includes(' D4');
    if (isSubWithCoil) {
      sku = sku?.replace(' D1', '');
      sku = sku?.replace(' D2', '');
      sku = sku?.replace(' D4', '');
    } else {
      product?.variant_parent_sku;
    }
  }

  const collectionsRow = product?.websiteCollectionsRow;
  const parentRow = collectionsRow?.parentRow;

  const skuIsVsUnloadedOrLoaded = skuIsLoadedOrUnloadedVehicleSpecificEnclosure(sku);

  let breadCrumbLevel1 = parentRow?.handle ? '/collections' + parentRow?.handle : null;
  let breadCrumbLevel1Title = parentRow?.collection_page_title ?? null;

  let breadCrumbLevel2 = collectionsRow?.handle ? '/collections' + collectionsRow?.handle : null;
  let breadCrumbLevel2Title = matchingRelatedVariant?.collection_page_title ?? collectionsRow?.collection_page_title ?? null;

  if (!breadCrumbLevel1) {
    if (skuIsVsUnloadedOrLoaded) {
      const vehicleFitment = product?.vehicleFitment;
      const vehicleMake = vehicleFitment?.vehicleMake;
      breadCrumbLevel1 = '/';
      breadCrumbLevel1Title = 'Main';
      breadCrumbLevel2 = '/collections/vehicle-specific-subwoofer-enclosures?vehicle_make=' + vehicleMake;
      breadCrumbLevel2Title = vehicleMake;
    }
  }

  const breadCrumbLevel3 = product?.path;
  const breadCrumbLevel3Title = sku;

  const breadcrumbs = {
    breadCrumbLevel1,
    breadCrumbLevel1Title,
    breadCrumbLevel2,
    breadCrumbLevel2Title,
    breadCrumbLevel3,
    breadCrumbLevel3Title
  };

  return breadcrumbs;
};
