import useSWR from 'swr';
import {fetcher} from '@helpers/common/fetch.mjs';
import {usePathname} from 'next/navigation';

export const useProductDetails = (sku) => {
  const {data} = useSWR(sku ? '/api/product/' + encodeURIComponent(sku) : null, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  });
  return data;
};

export const useProductInStock = (sku) => {
  const pathName = usePathname();
  const {data, error} = useSWR(`/api/product/stock/${sku}`, (url) => fetcher(url, {}, pathName));
  const isLoadedOnce = typeof data === 'object' && Object?.keys(data)?.length > 0 && data !== 'undefined';
  const isInStock = data?.isInStock === true;

  return {
    isLoading: (!data && !error) || typeof data === 'undefined',
    isLoadedOnce: isLoadedOnce,
    isInStock: isInStock,
    useProductStock: data ?? {}
  };
};

export const useProdInventoryForSkuListV2 = (skuList) => {
  const pathName = usePathname();
  const api = `/api/product/inventory`;
  const apiUrl = createUrlWithSkuListAsQueryParams(api, skuList);
  const {data, error} = useSWR(apiUrl, (url) => fetcher(url, {}, pathName));
  const isLoadedOnce = typeof data === 'object' && Object?.keys(data)?.length > 0 && data !== 'undefined';
  const isInStock = data?.isInStock === true;
  // console.log({isLoadedOnce, isInStock})

  return {
    isLoading: (!data && !error) || typeof data === 'undefined',
    isLoadedOnce: isLoadedOnce,
    isInStock: isInStock,
    useProductStock: data ?? {}
  };
};
