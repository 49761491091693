import DrawerMenu from './DrawerMenu';
import HorizontalSlider from '@components/widgets/HorizontalSlider/HorizontalSlider';
import {CloseIcon, Hamburger} from '@components/widgets/Ui';
import {MenuLink} from '@components/header/Header';

export const MobileMenuHeaderLinksCollections = () => (
  <>
    <div className='py-1 px-1 ml-1 md:hidden'>
      <HorizontalSlider showArrows={false} gap={4}>
        {mobileHeaderCollections?.map((item) => (
          <MenuLink key={item?.path} item={item} />
        ))}
      </HorizontalSlider>
    </div>
    <div className='hidden md:block'>
      <div className='flex justify-center'>
        {mobileHeaderCollections?.map((item) => (
          <MenuLink key={item?.path} item={item} />
        ))}
      </div>
    </div>
  </>
);

export const MobileNavigationMenu = ({menu, isOpen, setIsOpen}) => {
  const buildItem = (item) => {
    // console.log({first: item});
    return {
      id: item.slug || item.id || item.handle,
      key: item.slug || item.id || item.handle,
      title: item?.category,
      handle: item.handle,
      featured: featured[item.id],
      items: item.collections
        ?.filter((i) => !i.isHidden)
        ?.map((second) => {
          // console.log({second});
          return {
            id: second.slug || second.id || second.handle,
            key: second.slug || second.id || second.handle,
            title: second.title,
            subtitle: second.subtitle,
            slug: second.slug,
            handle: second.handle,
            items: (second.products || second['sub-collections'])?.map((third) => ({
              id: third.slug || third.id,
              key: Math.random(),
              title: third.sku,
              subtitle: third?.subtitle ?? '',
              sku: third.sku,
              slug: third.slug
            }))
          };
        })
    };
  };

  if (!menu || !menu.left) {
    return null;
  }

  const menus = [
    ...menu?.left?.categories.map(buildItem),
    ...menu?.right?.categories.map(buildItem)
  ];
  // console.log('[MobileNavigation] menus', menus);

  return (
    <nav role='navigation' aria-label='Main menu'>
      <button
        type='button'
        className=' hover:opacity-70 w-[32px] flex items-center text-white'
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        aria-label='Menu'
        aria-controls='drawer-main-menu'
        aria-expanded={isOpen ? 'true' : 'false'}>
        {isOpen ? <CloseIcon /> : <Hamburger />}
      </button>
      <DrawerMenu menus={menus} isOpen={isOpen} setIsOpen={setIsOpen} />
    </nav>
  );
};

export const mobileHeaderCollections = [
  {
    title: 'Amplifiers',
    path: '/collections/car-amplifiers',
    width: 110
  },
  {
    title: 'Subwoofers',
    path: '/collections/car-subwoofers',
    width: 120
  },
  {
    title: 'Bass Packages',
    path: '/collections/loaded-subwoofer-enclosures',
    width: 140
  },
  {
    title: 'Speakers',
    path: '/collections/car-speakers',
    width: 97
  },
  {
    title: 'Marine Audio',
    path: '/collections/marine-audio',
    width: 115
  },
  {
    title: 'More...',
    label: 'Amp kits, wiring & more',
    path: '/collections/car-audio-installation-accessories-wiring-equipment',
    width: 120
  }
];

export const mobileMenuSettings = {
  dots: false,
  infinite: false,
  variableWidth: true,
  arrows: false,
  accessibility: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  touchThreshold: 500
};

export const featured = {
  amplifiers: [
    {
      title: 'RP-2000.1D',
      subtitle: 'MONOBLOCK AMPLIFIER',
      slug: '/products/rp-2000-1d-car-amplifier',
      image: '/image/featured/Amplifiers/RP-2000.1D-1 (2).jpg'
    },
    {
      title: 'SK-M4004D',
      subtitle: '4-CHANNEL AMPLIFIER',
      slug: '/products/sk-m4004d-car-amplifier',
      image: '/image/featured/Amplifiers/SK-M4004D-1 (1).jpg'
    }
  ],
  subwoofers: [
    {
      title: 'SVR-10',
      subtitle: '1,600 WATT MAX SUBWOOFER',
      slug: '/products/svr-10-inch-car-subwoofer',
      image: '/image/featured/Subwoofers/SVR-10-1 (3).jpg'
    },
    {
      title: 'EVL-15',
      subtitle: '2,500 WATT MAX SUBWOOFER',
      slug: '/products/evl-15-inch-car-subwoofer',
      image: '/image/featured/Subwoofers/EVL-15-1 (4).jpg'
    }
  ],
  speakers: [
    {
      title: 'TX69',
      subtitle: '6"X9" COAXIAL SPEAKERS',
      slug: '/products/tx69-6-9-inch-coaxial-car-speakers',
      image: '/image/featured/Speakers/TX69-1 (4).jpg'
    },
    {
      title: 'FSX65',
      subtitle: '6.5" MIDRANGE SPEAKERS',
      slug: '/products/fsx65-6-5-inch-mid-range-speaker',
      image: '/image/featured/Speakers/FSX-65-1.jpg'
    }
  ],
  ['loaded-enclosures']: [
    {
      title: 'EVL-2X12D4',
      subtitle: 'LOADED ENCLOSURE',
      slug: '/products/skar-evl-dual-12-inch-loaded-subwoofer-enclosure',
      image: '/image/featured/Loaded Enclosures/EVL-2X12D4-1 (4).jpg'
    },
    {
      title: 'SDR-1X12D2',
      subtitle: 'LOADED ENCLOSURE',
      slug: '/products/skar-sdr-single-12-inch-loaded-subwoofer-enclosure',
      image: '/image/featured/Loaded Enclosures/SDR-1X12D2-1 (3).jpg'
    }
  ],
  ['empty-enclosures']: [
    {
      title: 'SK1X12V',
      subtitle: 'VENTED ENCLOSURE',
      slug: '/products/sk1x12v-single-12-ported-subwoofer-enclosure-box',
      image: '/image/featured/Empty Enclosures/AR2X12V-1.jpg'
    },
    {
      title: 'AR2X12V',
      subtitle: 'VENTED ENCLOSURE',
      slug: '/products/ar2x12v-dual-12-ported-subwoofer-enclosure-armor-coated',
      image: '/image/featured/Empty Enclosures/SK1X12V-1 (1).jpg'
    }
  ],
  ['amp-kits-wiring-more']: [
    {
      title: 'SKAR0ANL-OFC',
      subtitle: '1/0 GAUGE WIRING KIT',
      slug: '/products/skar0anl-ofc-1-0-gauge-amp-wiring-kit',
      image: '/image/featured/Amp Kits and More/SKAR0ANL-OFC-2.jpg'
    },
    {
      title: 'SKAR2CH-RCA 17',
      subtitle: 'RCA INTERCONNECTS',
      slug: '/products/skar2ch-rca17-17-ft-2-channel-twisted-pair-rca-interconnect-cable',
      image: '/image/featured/Amp Kits and More/SKAR2CH-RCA17-2.jpg'
    }
  ]
};
