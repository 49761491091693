import cn from 'clsx';
import {createContext, useState} from 'react';

export function isClient() {
  const isClient = typeof process !== 'object' && typeof window === 'object'
  return isClient;
}

export function isServer() {
  const isServer = typeof process === 'object' && typeof window !== 'object'
  return isServer;
}

export function Debug({children, show, className}) {
  // console.log({children, show})
  if (show === false) {
    return null
  }

  if (!className) {
    className = 'border rounded bg-gray-50 p-1 m-1'
  }

  if (show === true) {
    return <div className={className}>{children}</div>
  }


  if (isClient()) {
    let href = document?.location?.href
    let isLocalhost = href?.includes('ngrok.io')
    if (!isLocalhost) {
      return null
    }
    return <div className={className}>{children}</div>
  }

  if (isServer()) {
    let isKevin = process.env.USERNAME === 'KEVIN'
    if (!isKevin) {
      return null
    }
    return <div className={className}>{children}</div>
  }

  return false
}

export function Json({name, variable, className}) {
  return (
    <pre
      className={cn('whitespace-prewrap', className)}
      style={{
        fontSize: 10,
        lineHeight: '1em',
      }}
    >
			{name ? name + ':' : ''} {JSON.stringify(variable, null, 2)}
		</pre>
  )
}

const DebugContext = createContext(null)

export function DebugProvider({debug: defaultDebug = false, children}) {
  const [debug, setDebug] = useState(defaultDebug)

  const isDebugCookie = typeof document === 'object' && document?.cookie?.includes('depidsvy')
  if (!isDebugCookie) {
    return children
  }

  const toggle = () => {
    setDebug(!debug)
  }

  return <DebugContext.Provider value={{debug, toggle}}>{children}</DebugContext.Provider>
}

export function DebugToggle() {
  return <div className="bg-gray-50">x</div>
}
