import styles from './review-photos.module.css';
import Image from 'next/image';
import {Fragment} from 'react';
import HorizontalSlider from '@components/widgets/HorizontalSlider/HorizontalSlider';

export default function ReviewPhotoGrid({images, skuList, onClickReviewPhoto, handleViewAll}) {
  return (
    <HorizontalSlider>
      {images &&
        images?.map((image, index) => {
          // console.log("[ReviewPhotoGrid] skuList:", skuList);
          const isLastReviewImage = index === images?.length - 1;
          return (
            <Fragment
              key={`review-photo-${skuList?.[0]}-${image?.review_id}-${image?.image_index}-${index}`}>
              <div
                className={`${styles['review-grid-photo']} bg-spinner`}
                onClick={() => onClickReviewPhoto(image?.review_id, image?.image_index)}>
                <Image
                  src={image?.image}
                  alt={image?.image_alt}
                  className='rounded-md object-cover object-center aspect-square relative z-10'
                  sizes='300px'
                  onLoad={(e) => {
                    e.target?.parentElement?.classList?.remove('bg-spinner');
                  }}
                  fill
                />
              </div>
              {isLastReviewImage && images?.length > 3 && (
                <button
                  onClick={handleViewAll}
                  className={`${styles['review-grid-photo']} flex justify-center items-center rounded-md relative text-white underline`}
                  aria-label='View All Photos'>
                  View All Photos
                </button>
              )}
            </Fragment>
          );
        })}
    </HorizontalSlider>
  );
}
