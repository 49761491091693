import * as menu from '../components/header/menu.json' assert {type: 'json'};
import {isPlainObject, simpleProps} from './common/object.mjs';

export const getMenu = () => {
  return menu.default;
};

export const getMenuCombined = () => {
  const menu = getMenu();
  return [...menu.left.categories, ...menu.right.categories];
};

export const getAllCollectionsSlugs = () => {
  let categories = getMenuCombined();
  // console.log(categories.map(simpleProps))

  let allCollections = categories?.flatMap((x) => x?.collections).filter(Boolean);
  // console.log('[allCollections]:', {allCollections})
  let allCollectionSlugs = [
    ...categories.map((x) => '/collections/' + x?.handle),
    '/collections/---',
    ...allCollections.map((x) => x?.slug)?.filter((x) => x?.startsWith('/collections/')),
    '/collections/---'
  ];
  allCollectionSlugs = filterOutBadPathsList(allCollectionSlugs);
  return allCollectionSlugs;
};

export const getMenuCategoryBySlug = (slug) => {
  return getMenuCombined().find((x) => {
    return x?.handle === slug;
  });
};

export const filterOutBadPathsList = (pathSlugs) => {
  if (!pathSlugs?.length) {
    return pathSlugs;
  }

  const excludeList = [
    '/collections/collections',
    '/collections/---'
  ];

  return pathSlugs.filter(item => {
    let path;
    if (typeof item === 'string') {
      path = item;
    } else if (item && typeof item === 'object' && 'loc' in item) {
      path = item.loc;
    } else {
      console.log(`Skipping unknown type: ${JSON.stringify(item)}`);
      return false;
    }
    if (typeof path !== 'string') {
      console.log(`Skipping non-string path: ${path}`);
      return false;
    }
    if (path.includes('?')) {
      return false;
    }
    return !excludeList.includes(path);
  });
};


export const getMenuCollectionBySlug = (slug) => {
  const foundMain = getMenuCategoryBySlug(slug);
  if (foundMain) {
    return foundMain;
  }

  let allCollections = getMenuCombined()?.flatMap((x) => x?.collections)?.filter(Boolean);
  // console.log({ allCollections: allCollections.map((x) => x.slug) })
  if (!allCollections?.length) return null;

  const foundSub = allCollections?.find((x) => x.slug === '/collections/' + slug);
  if (foundSub) {
    if (foundSub?.subMenu?.length) {
      foundSub['sub-collections'] = foundSub?.subMenu
    }

    return foundSub
  }


  const subMenu = allCollections?.flatMap((x) => x?.['subMenu'])?.filter(Boolean);
  const subMenuFound = subMenu?.find((x) => x?.slug === '/collections/' + slug);
  return subMenuFound ?? null;
};

// if slug corresponds to the deep item in the menu
// we need to find the category it belongs to
export const findCategoryByCollection = (collection) => {
  // console.log({collection})
  const {id: collectionId} = collection || {};

  const isMatchingCollection = (c) => c?.id === collectionId;
  const isMatchingSubMenu = (subMenu) => subMenu?.id === collectionId;

  return getMenuCombined().find(({collections}) => {
    return collections?.some((c) => isMatchingCollection(c)) ||
      collections?.some((c) => c?.subMenu?.some(isMatchingSubMenu));
  });
};

export const getMenuCategoryDataBySlug = (slug) => {
  let category = getMenuCategoryBySlug(slug);
  let collection;
  if (!category) {
    collection = getMenuCollectionBySlug(slug);
    category = findCategoryByCollection(collection);
    // console.log({collection, category})
  }
  let categoryId = category?.id ?? collection?.id;

  return {
    slug,
    category,
    collection,
    categoryId
  };
};

export const findProductCollectionInMenu = (product) => {
  // console.dir({product}, {depth: 3})
  const upc = String(product?.upc);
  // const upc = product?.shopify_product_id ? String(product.shopify_product_id) : String(product?.upc);
  // console.log({metaProdID})

  let categoryWithProduct = null;
  let productCollection = null;
  let productCategory = null;

  const menu = getMenuCombined();

  for (const menuItem of menu) {
    // console.log({menuItem})
    // Search for the category with the product
    if (!categoryWithProduct) {
      if (menuItem?.products?.some((prd) => {
        return prd.id === upc;
      })) {
        categoryWithProduct = menuItem;
        break;
      }
    }

    // Search for the product collection and the product category
    if (menuItem?.collections) {
      for (const collection of menuItem.collections) {
        if (!productCollection && collection?.products?.some((y) => String(y?.id) === upc)) {
          productCollection = {...collection, products: null};
        }
        if (!productCategory && productCollection && collection.id === productCollection.id) {
          productCategory = menuItem;
        }
        if (productCollection && productCategory) break;
      }
    }
    if ((productCollection && productCategory)) break;
  }

  // Link the product collection with the parent category
  if (productCategory) {
    productCollection = {
      ...productCollection,
      parentCategory: {
        category: productCategory?.category,
        handle: productCategory?.handle
      }
    };
  }
  // console.log({categoryWithProduct, productCollection});
  return categoryWithProduct || productCollection;
};

export const findProductCategoryPath = ({id}) => {
  const menu = getMenuCombined();
  let path = findPathTo(menu, id);
  if (!path) return null;

  path = path.flat(99);

  const sections = [];
  let ref = menu;
  for (const x of path) {
    if (isPlainObject(x)) break;
    sections.push(simpleProps(ref));
    ref = ref?.[x];
  }
  return sections?.filter(x => Object.keys(x)?.length);
};

function findPathTo(menu, id) {
  if (Array.isArray(menu)) {
    for (let [index, item] of menu?.entries()) {
      // console.log('-', item?.handle ?? item?.sku ?? item?.slug ?? item.category)
      if (item?.id === id) {
        return item;
      }
      const found = findPathTo(item, id);
      if (found) {
        return [index, found];
      }
    }
  }

  if (!isPlainObject(menu)) {
    return null;
  }

  for (let [key, val] of Object.entries(menu)) {
    // console.log('-', key)
    const found = findPathTo(val, id);
    if (found) {
      return [key, found];
    }
  }
  return null;
}

export const getAllMenuProducts = () => {
  const menu = getMenuCombined();
  const products = menu?.flatMap((x) => {
    const productLevel = x.products ?? [];
    const collections = x?.collections;
    return [
      ...productLevel,
      ...collections?.flatMap((xx) => [
        ...(xx?.products ?? []),
        ...(xx?.subMenu?.flatMap((xxx) => xxx?.products) ?? [])
      ])
    ];
  });
  return products;
};
