import {useRouter} from 'next/router';
import {createContext, useContext, useEffect, useState} from 'react';
import {
  skuIsLoadedOrUnloadedVehicleSpecificEnclosure
} from '@helpers/products/vehicle-specific.mjs';
import {
  arraySortBToA,
  sortListOfSkusIntelligently,
  sortVariantOptions
} from '@helpers/common/array.mjs';
import {generateBreadCrumbData} from '@helpers/products/breadcrumbs.mjs';
import {combineSkusWithCombinedCoilConfigs} from '@helpers/reviews/review-sku-list-functions.mjs';
import {consoleLogFe} from '@helpers/logging/provider-logging.mjs';

export const ProductStateContext = createContext(null);

export const ProductProvider = ({data: product, reviewsFromDb, children}) => {
  // console.warn(' ☀️ [ProductProvider] product:', product);
  const router = useRouter();
  const routerQ = router?.query;
  // console.warn(' ☀️ [ProductProvider] router:', router);

  let querySku = routerQ?.variant;
  const routerPath = router?.asPath?.split('/')?.pop();
  if (!querySku) {
    const routerPathSku = routerPath?.split('=')?.[1];
    if (routerPathSku) {
      querySku = decodeURIComponent(routerPathSku);
    }
  }

  const pathOnLoad = router?.asPath;

  let productVariants = product?.variants;
  const relatedVariants = product?.relatedVariants;
  const firstVariant = productVariants?.[0] ?? product;

  const isTrueVariantSku = product?.product_type === 'variant';
  const isTrueVariantWithRelatedProducts = isTrueVariantSku && !!relatedVariants?.length;

  let initialProductOnLoad;
  if (!isTrueVariantSku) {
    initialProductOnLoad = productVariants?.find((variant) => variant?.sku === product?.sku);
  } else if (isTrueVariantWithRelatedProducts) {
    const matchingVariantForPath = productVariants?.find((variant) => {
      const productPath = product?.path;
      const path = variant?.path?.split('?')[0];
      const pathsMatch = path === productPath;
      // const skusMatch = variant?.sku === querySku;
      return pathsMatch;
      // return pathsMatch && skusMatch;
    });
    initialProductOnLoad = matchingVariantForPath;
  }

  const [selectedVariant, setSelectedVariant] = useState(initialProductOnLoad ?? null);
  const [selectedOptionOne, setSelectedOptionOne] = useState('');
  const [selectedOptionTwo, setSelectedOptionTwo] = useState('');
  const [selectedOptionThree, setSelectedOptionThree] = useState('');
  const [externalChange, setExternalChange] = useState(false);
  const [currentVariantReviewData, setCurrentVariantReviewData] = useState(reviewsFromDb);
  // if (selectedVariant?.sku) {
  //   console.warn(' ☀️ [ProductProvider] selectedVariant:', selectedVariant?.sku);
  // }

  const optionsStatus = generateOptionsStatus(relatedVariants, selectedVariant);

  const matchingRelatedVariant = relatedVariants?.find(
    (variant) => variant?.sku === selectedVariant?.sku
  );
  // console.log('[matchingRelatedVariant]:', matchingRelatedVariant);

  const hasRelatedVariants = !!relatedVariants?.length;
  const hasTwoVariantSelectors = hasRelatedVariants && relatedVariants?.some((variant) => {
    // console.log("[ProductProvider] variant:", variant);
    return variant?.variant_option_one_name && variant?.variant_option_two_name;
  });

  const hasThreeVariantSelectors =
    hasRelatedVariants &&
    relatedVariants?.some(
      (variant) =>
        variant?.variant_option_one_name &&
        variant?.variant_option_two_name &&
        variant?.variant_option_three_name
    );

  const relatedProductTotalOptionSelectors = hasRelatedVariants
    ? Math.max(
      ...relatedVariants.map((variant) =>
        [
          'variant_option_one_name',
          'variant_option_two_name',
          'variant_option_three_name'
        ].reduce((count, key) => (variant[key] ? count + 1 : count), 0)
      )
    )
    : null;

  useEffect(() => {
    // console.log('[useEffect] Router path changed:', router?.asPath);
    if (!isTrueVariantSku) {
      if (router?.asPath?.includes('variant=')) {
        consoleLogFe(
          '[useEffect] Router path changed from a variant sku to non variant product type'
        );
        setSelectedOptionOne('');
        setSelectedOptionTwo('');
        setSelectedOptionThree('');
      }
      // Update the review data for a normal product
      setCurrentVariantReviewData(reviewsFromDb);
    }
  }, [router.asPath]);

  useEffect(() => {
    if (selectedOptionOne && selectedOptionTwo && !selectedOptionThree) {
      handleMultiLevelVariantChange({
        optionOne: selectedOptionOne,
        optionTwo: selectedOptionTwo
      });
    }
  }, [selectedOptionOne, selectedOptionTwo]);

  useEffect(() => {
    if (selectedOptionOne && selectedOptionTwo && selectedOptionThree) {
      handleMultiLevelVariantChange({
        optionOne: selectedOptionOne,
        optionTwo: selectedOptionTwo,
        optionThree: selectedOptionThree
      });
    }
  }, [selectedOptionOne, selectedOptionTwo, selectedOptionThree]);

  const setSelectedOptions = (variant) => {
    setSelectedOptionOne(variant.variant_option_one_value);
    setSelectedOptionTwo(variant.variant_option_two_value);
    setSelectedOptionThree(variant.variant_option_three_value);
  };

  const isSkuWithCoilConfig = (sku) => {
    return sku?.includes(' D1') || sku?.includes(' D2') || sku?.includes(' D4');
  };

  const setNormalProductReviewData = (variant) => {
    setCurrentVariantReviewData({
      count: variant?.reviewScoreAndCounts?.count,
      avgScore: variant?.reviewScoreAndCounts?.avgScore
    });
  };

  const setCoilConfigProductReviewData = (variant, relatedVariants) => {
    const skuList = relatedVariants?.map((v) => v?.sku);
    const combinedSkus = combineSkusWithCombinedCoilConfigs(variant?.sku, skuList);

    const matchingVariants = relatedVariants?.filter((v) => combinedSkus?.includes(v.sku));

    const totalReviews = matchingVariants.reduce(
      (acc, v) => acc + (v.reviewScoreAndCounts?.count || 0),
      0
    );
    const totalScore = matchingVariants.reduce(
      (acc, v) =>
        acc + (v.reviewScoreAndCounts?.avgScore || 0) * (v.reviewScoreAndCounts?.count || 0),
      0
    );
    const avgScore = totalReviews > 0 ? totalScore / totalReviews : 0;

    setCurrentVariantReviewData({
      count: totalReviews,
      avgScore: avgScore
    });
  };

  useEffect(() => {
    if (externalChange) {
      setExternalChange(false);
      if (isTrueVariantSku) {
        return;
      }
    }

    let variantToSet;
    if (!querySku && !pathOnLoad?.includes('variant=')) {
      if (isTrueVariantSku) {
        if (isTrueVariantWithRelatedProducts) {
          consoleLogFe(
            '[useEffect] isTrueVariantWithRelatedProducts:',
            isTrueVariantWithRelatedProducts
          );
          // console.log('[relatedVariants]:', relatedVariants);

          const matchingVariantsForPath = relatedVariants?.filter((variant) => {
            const productHandle = product?.handle;
            const variantHandle = variant?.site_handle;
            return productHandle === variantHandle;
          })?.sort((a, b) => a?.sku.localeCompare(b?.sku));

          const matchingVariantForPath = matchingVariantsForPath?.[0];

          if (matchingVariantForPath) {
            setSelectedOptions(matchingVariantForPath);
            // console.log('[useEffect] Matched variant for path:', matchingVariantForPath);
            const isCoilConfig = isSkuWithCoilConfig(matchingVariantForPath?.sku);
            if (isCoilConfig) {
              setCoilConfigProductReviewData(matchingVariantForPath, relatedVariants);
            } else {
              setNormalProductReviewData(matchingVariantForPath);
            }

            variantToSet = productVariants?.find(
              (variant) => variant?.sku === matchingVariantForPath?.sku
            );
            if (variantToSet) {
              setSelectedVariant(variantToSet);
            }
          }
        }
      } else {
        variantToSet = productVariants?.find((variant) => {
          const variantPathCleaned = variant?.path?.replace('/products/', '');
          return variantPathCleaned === routerPath;
        });

        if (variantToSet) {
          // console.log('[useEffect] Line 161 (a):', variantToSet);
          setSelectedVariant(variantToSet);
          const matchedVariant = relatedVariants?.find(
            (variant) => variant?.sku === variantToSet?.sku
          );

          if (matchedVariant) {
            setSelectedOptions(matchedVariant);
            const sku = matchedVariant?.sku;
            const isCoilConfig = isSkuWithCoilConfig(sku);
            if (isCoilConfig) {
              console.log('[useEffect][isCoilConfig] Matched related variant:', matchedVariant);
              setCoilConfigProductReviewData(matchedVariant, relatedVariants);
            } else {
              setNormalProductReviewData(matchedVariant);
            }
          }
        }
      }
    } else {
      if (productVariants?.length > 1) {
        // console.log(' 🍊 [useEffect]:', querySku);

        variantToSet = productVariants?.find((variant) => variant?.sku === querySku);
        // console.log('🍊🍊 [useEffect]: Variant found for query SKU:', variantToSet);
        if (variantToSet) {
          setSelectedVariant(variantToSet);
          if (relatedVariants) {
            // console.log('[useEffect] Variant found for query SKU:', variantToSet?.sku);
            const matchedVariant = relatedVariants?.find((variant) => variant?.sku === querySku);
            if (matchedVariant) {
              setSelectedOptions(matchedVariant);
              const sku = matchedVariant?.sku;
              const isCoilConfig = isSkuWithCoilConfig(sku);
              if (isCoilConfig) {
                setCoilConfigProductReviewData(matchedVariant, relatedVariants);
              } else {
                setNormalProductReviewData(matchedVariant);
              }
            }
          }
        }
      }
    }
  }, [product, querySku, productVariants, firstVariant, router.asPath]);

  const updateUrl = (sku, path) => {
    const isVsEnclosureSku = skuIsLoadedOrUnloadedVehicleSpecificEnclosure(sku);
    const isNormalProduct = !isTrueVariantSku;
    if (isVsEnclosureSku || isNormalProduct) {
      // console.log('[updateUrl] SKU:', sku, 'Path:', path);
      router.replace(path, null, {shallow: true});
      window.scrollTo({top: 225, behavior: 'smooth'});
    } else {
      const url = path;
      router.replace(url, null, {shallow: true});
    }
  };

  const handleOptionOneChange = (value) => {
    console.log('[handleOptionOneChange] New value:', value);
    setSelectedOptionOne(value);
  };

  const handleOptionTwoChange = (value) => {
    console.log('[handleOptionTwoChange] New value:', value);
    setSelectedOptionTwo(value);
  };

  const handleOptionThreeChange = (value) => {
    console.log('[handleOptionThreeChange] New value:', value);
    setSelectedOptionThree(value);
  };

  const changeVariant = (sku) => {
    setExternalChange(true);
    const newVariant = productVariants?.find((variant) => variant?.sku === sku);
    // console.log('[changeVariant] New variant SKU:', sku);
    setSelectedVariant(newVariant);
    const variantSku = product?.variants?.find((x) => x?.sku === sku);
    updateUrl(sku, variantSku?.path || product?.slug);
  };

  const handleMultiLevelVariantChange = ({optionOne, optionTwo, optionThree}) => {
    // console.log('[handleMultiLevelVariantChange] optionOne:', optionOne);
    // console.log('[handleMultiLevelVariantChange] optionTwo:', optionTwo);
    // console.log('[handleMultiLevelVariantChange] optionThree:', optionThree);
    if (optionOne) {
      setSelectedOptionOne(optionOne);
    }
    if (optionTwo) {
      setSelectedOptionTwo(optionTwo);
    }
    if (optionThree) {
      setSelectedOptionThree(optionThree);
    }

    let matchedVariant = relatedVariants?.find(
      (variant) =>
        variant?.variant_option_one_value === optionOne &&
        (!optionTwo || variant?.variant_option_two_value === optionTwo) &&
        (!optionThree || variant?.variant_option_three_value === optionThree)
    );
    // console.log('[handleMultiLevelVariantChange] matchedVariant:', matchedVariant);

    if (!matchedVariant) {
      matchedVariant = relatedVariants?.find(
        (variant) => variant?.variant_option_one_value === optionOne
      );
      setSelectedOptionTwo(matchedVariant?.variant_option_two_value);
      setSelectedOptionThree(matchedVariant?.variant_option_three_value);
      // console.log(
      //   '[handleMultiLevelVariantChange] matchedVariant after perfect match not found:',
      //   matchedVariant
      // );
    }
    const matchedSku = matchedVariant?.sku;

    if (matchedVariant) {
      // console.log('[matchedSku]:', matchedSku);
      changeVariant(matchedSku);
    }
  };

  let disableAddToCart = false;
  let hasMoreThanOneRelatedVariant = relatedVariants?.length > 1;
  if (!disableAddToCart) {
    if (hasTwoVariantSelectors) {
      disableAddToCart = hasMoreThanOneRelatedVariant && (!selectedOptionOne || !selectedOptionTwo);
    }
    if (hasThreeVariantSelectors) {
      disableAddToCart =
        hasMoreThanOneRelatedVariant && (!selectedOptionOne || !selectedOptionTwo || !selectedOptionThree);
    }
  }

  const hasNoVariants = productVariants?.length < 2 && !hasRelatedVariants;

  const variantOptionOneName = relatedVariants?.[0]?.variant_option_one_name;
  const variantOptionTwoName = relatedVariants?.[0]?.variant_option_two_name;
  const variantOptionThreeName = relatedVariants?.[0]?.variant_option_three_name;

  let uniqueOptionOneValues = [
    ...new Set(relatedVariants?.map((opt) => opt?.variant_option_one_value))
  ];
  let uniqueOptionTwoValues = [
    ...new Set(relatedVariants?.map((opt) => opt?.variant_option_two_value))
  ];
  let uniqueOptionThreeValues = [
    ...new Set(relatedVariants?.map((opt) => opt?.variant_option_three_value))
  ];
  uniqueOptionOneValues = sortVariantOptions(uniqueOptionOneValues);

  uniqueOptionTwoValues = filterOptionTwoValues(
    relatedVariants,
    uniqueOptionTwoValues,
    selectedOptionOne
  );

  uniqueOptionThreeValues = filterOptionThreeValues(
    relatedVariants,
    uniqueOptionThreeValues,
    selectedOptionTwo
  );

  const showMultiLevelVariantOptions =
    variantOptionOneName && variantOptionTwoName && productVariants?.length > 1;

  productVariants = arraySortBToA(productVariants, (x) => x?.sku);
  productVariants = sortListOfSkusIntelligently(productVariants);

  const {
    breadCrumbLevel1,
    breadCrumbLevel1Title,
    breadCrumbLevel2,
    breadCrumbLevel2Title,
    breadCrumbLevel3,
    breadCrumbLevel3Title
  } = generateBreadCrumbData(product, selectedVariant, matchingRelatedVariant);

  const providerValue = {
    pagePath: routerPath,
    id: product?.id,
    product_type: product?.product_type,
    sku: product?.sku,
    upc: product?.upc,
    name: product?.name,
    category: product?.category,
    vendor: product?.vendor,
    path: product?.path,
    slug: product?.slug,
    price: product?.price,
    price_map: product?.price_map,
    price_msrp: product?.price_msrp,
    price_dealer: product?.price_dealer,
    variants: productVariants ?? [],
    variesBy: product?.variesBy,
    options: product?.options,
    currentVariantReviewData: currentVariantReviewData,
    seoTitle: product?.seo_title,
    seoDescription: product?.seo_description,
    metafields: product?.metafields,
    currentlyInStock: product?.currentlyInStock,
    preorderInfo: product?.preorderInfo,
    variant_parent_sku: product?.variant_parent_sku,
    google_product_line: product?.google_product_line,
    tpa_wh: product?.tpa_wh,
    lv_wh: product?.lv_wh,
    total_qty_without_fba: product?.total_qty_without_fba,
    weight: product?.weight,
    isDiscontinued: product?.isDiscontinued,
    isEnabledOnSite: product?.isEnabledOnSite,
    websiteCollectionsRow: product?.websiteCollectionsRow,
    relatedVariants: product?.relatedVariants,
    vehicleFitment: product?.vehicleFitment,
    handle: product?.handle,
    isInStock: product?.isInStock,
    inventory: product?.inventory,
    pre_order_info: product?.pre_order_info,
    disableAddToCart,
    selectedVariant,
    selectedVariantSku: selectedVariant?.sku,
    frequentlyBoughtTogetherAssociations: selectedVariant?.frequentlyBoughtTogetherAssociations,
    relatedVariantsOptions: relatedVariants,
    selectedOptionOne,
    selectedOptionTwo,
    selectedOptionThree,
    setSelectedOptionOne,
    setSelectedOptionTwo,
    setSelectedOptionThree,
    breadcrumbs: {
      breadCrumbLevel1,
      breadCrumbLevel1Title,
      breadCrumbLevel2,
      breadCrumbLevel2Title,
      breadCrumbLevel3,
      breadCrumbLevel3Title
    },
    changeVariant,
    handleOptionOneChange,
    handleOptionTwoChange,
    handleOptionThreeChange,
    handleMultiLevelVariantChange,
    variantOptionOneName,
    variantOptionTwoName,
    variantOptionThreeName,
    uniqueOptionOneValues,
    uniqueOptionTwoValues,
    uniqueOptionThreeValues,
    hasNoVariants,
    showMultiLevelVariantOptions,
    optionsStatus
  };
  // console.log('[ProductProvider] providerValue:', providerValue);
  return (
    <ProductStateContext.Provider value={providerValue}>{children}</ProductStateContext.Provider>
  );
};

export const useProductProvider = () => {
  try {
    const context = useContext(ProductStateContext);
    if (!context) {
      throw new Error('useProductProvider must be used within a ProductProvider');
    }
    return context;
  } catch (error) {
    console.error('An error occurred in useProductProvider:', error);
  }
};

const filterOptionTwoValues = (relatedVariants, uniqueOptionTwoValues, selectedOptionOne) => {
  return uniqueOptionTwoValues?.filter((optionValue) => {
    const relatedVariantsWithOptionOneValueIncluded = relatedVariants?.filter((item) => {
      return item?.variant_option_one_value === selectedOptionOne;
    });
    return relatedVariantsWithOptionOneValueIncluded?.find((variant) => {
      return variant?.variant_option_two_value === optionValue;
    });
  });
};

const filterOptionThreeValues = (relatedVariants, uniqueOptionThreeValues, selectedOptionTwo) => {
  return uniqueOptionThreeValues?.filter((optionValue) => {
    const relatedVariantsWithOptionTwoValueIncluded = relatedVariants?.filter((item) => {
      return item?.variant_option_two_value === selectedOptionTwo;
    });
    return relatedVariantsWithOptionTwoValueIncluded?.find((variant) => {
      return variant?.variant_option_three_value === optionValue;
    });
  });
};

const generateOptionsStatus = (relatedVariants, selectedVariant) => {
  // console.log('[generateOptionsStatus] relatedVariants:', relatedVariants);
  let optionsStatus = {
    optionOne: {},
    optionTwo: {},
    optionThree: {}
  };

  if (!relatedVariants || !selectedVariant) {
    return optionsStatus;
  }

  const uniqueOptionOneValues = [
    ...new Set(relatedVariants?.map((v) => v?.variant_option_one_value))
  ]?.filter(Boolean);
  const uniqueOptionTwoValues = [
    ...new Set(relatedVariants?.map((v) => v?.variant_option_two_value))
  ]?.filter(Boolean);
  const uniqueOptionThreeValues = [
    ...new Set(relatedVariants?.map((v) => v?.variant_option_three_value))
  ]?.filter(Boolean);

  sortVariantOptions(uniqueOptionOneValues);
  sortVariantOptions(uniqueOptionTwoValues);
  sortVariantOptions(uniqueOptionThreeValues);

  const matchingVariant = relatedVariants?.find((item) => item?.sku === selectedVariant?.sku);

  const isOptionEnabled = (optionValue, optionName) => {
    return relatedVariants.some((variant) => {
      if (optionName === 'optionThree') {
        return (
          variant?.variant_option_one_value === matchingVariant?.variant_option_one_value &&
          variant?.variant_option_two_value === matchingVariant?.variant_option_two_value &&
          variant?.variant_option_three_value === optionValue
        );
      } else if (optionName === 'optionTwo') {
        return (
          variant?.variant_option_one_value === matchingVariant?.variant_option_one_value &&
          variant?.variant_option_three_value === matchingVariant?.variant_option_three_value &&
          variant?.variant_option_two_value === optionValue
        );
      } else {
        return (
          variant?.variant_option_two_value === matchingVariant?.variant_option_two_value &&
          variant?.variant_option_three_value === matchingVariant?.variant_option_three_value &&
          variant?.variant_option_one_value === optionValue
        );
      }
    });
  };

  uniqueOptionOneValues?.forEach((optionOneValue) => {
    optionsStatus.optionOne[optionOneValue] = {
      enabled: isOptionEnabled(optionOneValue, 'optionOne'),
      selected: selectedVariant?.variant_option_one_value === optionOneValue
    };
  });

  uniqueOptionTwoValues.forEach((optionTwoValue) => {
    optionsStatus.optionTwo[optionTwoValue] = {
      enabled: isOptionEnabled(optionTwoValue, 'optionTwo'),
      selected: selectedVariant?.variant_option_two_value === optionTwoValue
    };
  });

  if (uniqueOptionThreeValues?.length > 1) {
    uniqueOptionThreeValues?.forEach((optionThreeValue) => {
      optionsStatus.optionThree[optionThreeValue] = {
        enabled: isOptionEnabled(optionThreeValue, 'optionThree'),
        selected: selectedVariant?.variant_option_three_value === optionThreeValue
      };
    });
  }

  optionsStatus = customFilterOptions(optionsStatus, matchingVariant);

  return optionsStatus;
};

const customFilterOptions = (optionsStatus, matchingVariant) => {
  const isZVXSubs = matchingVariant?.variant_parent_sku === 'ZVX-SUBS';
  if (!isZVXSubs) {
    return optionsStatus;
  }
  if (isZVXSubs && optionsStatus?.optionTwo) {
    const filteredTwoOptions = Object.keys(optionsStatus?.optionTwo)?.filter((key) => {
      const selectedOptionOne = matchingVariant?.variant_option_one_value;
      if (selectedOptionOne === '8"') {
        return key !== 'Dual 1 Ohm';
      } else {
        return key !== 'Dual 4 Ohm';
      }
    });

    const filteredOptionTwo = {};
    filteredTwoOptions.forEach((key) => {
      filteredOptionTwo[key] = optionsStatus.optionTwo[key];
    });

    optionsStatus.optionTwo = filteredOptionTwo;
  }

  return optionsStatus;
};
