import {shortDateWithYear} from '@helpers/common/date.mjs';
import {useState} from 'react';
import dynamic from 'next/dynamic';
import {ReviewImages, ShowStarRating} from '@components/product/reviews/reviews-with-images.jsx';
import {HStack, VStack} from '@components/widgets/Common';
import {ReviewThumbsUp} from '../reviews/thumbs-up';

const ReviewAdminBar = dynamic(
  () =>
    import('@components/product/reviews/review-photos/review-admin-bar').then(
      (mod) => mod.ReviewAdminBar
    ),
  {
    ssr: false
  }
);

export const RatingSummaryDesktop = ({avgScore, count}) => {
  if (!avgScore) {
    return null;
  }

  const score = avgScore.toFixed(1);
  const stars = Math.round(avgScore);

  return (
    <div className="font-oswald uppercase">
      <HStack className="justify-start items-center" gap={3}>
        <div className="font-bold text-3xl">{score}</div>
        <ShowStarRating stars={stars} size={20} />
      </HStack>
      <p>Based on {count} reviews</p>
    </div>
  );
};

export const DisplayOneReviewForMobileAndDesktop = ({review, reviewIndex, isMobile}) => {
  const [open, setOpen] = useState(false);

  const reviewText = review?.body;
  const stars = Math.round(review?.rating);

  const dateOfReview = review?.review_date ? shortDateWithYear(review?.review_date) : '';

  const nameOfReviewer = review?.reviewer?.name
    ? `By ${review?.reviewer?.name.split(' ')?.[0]} on ${dateOfReview}`
    : '';
  const thumbsUpCount = review?.thumbs_up || 0;

  const TEXT_LIMIT = 600;
  const visibleReviewText = open ? reviewText : String(reviewText).substring(0, TEXT_LIMIT);

  return (
    <div className="pt-2 px-2 relative lg:pt-3 lg:px-3">
      <div>
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <div className="inline-block -ml-1">
              <ShowStarRating stars={stars} size={18} className="align-middle" />
            </div>
            <div className="text-base font-bold inline ml-2 align-middle">{review?.title}</div>
          </div>

          <div className="ml-auto">
            <ReviewAdminBar review={review} />
          </div>
        </div>
        <VStack className="items-start">
          <div className="flex flex-wrap gap-x-2">
            {nameOfReviewer && <div className="text-sm whitespace-pre">{nameOfReviewer}</div>}
            {String(review?.verified).includes('buyer') && (
              <div className="text-[12px] text-skar-green font-bold font-sans">Verified Buyer</div>
            )}
          </div>
          {review?.sku && <div className="text-[12px] text-gray-600">SKU: {review.sku}</div>}
        </VStack>
      </div>
      <div>
        {visibleReviewText}
        {reviewText?.length > TEXT_LIMIT && !open && (
          <button
            className="border rounded text-right text-sm mx-1 mb-1 mt-1 inline px-2 bg-gray-100 text-base"
            onClick={() => setOpen(true)}>
            read more...
          </button>
        )}
      </div>
      <div className="mb-1.5 mt-3">
        <ReviewImages
          images={review?.images}
          review={review}
          reviewIndex={reviewIndex}
          isMobile={isMobile}
        />
      </div>
      <div className="flex gap-2 items-center">
        <div className="text-xs text-neutral-600 font-medium">Was this review helpful?</div>
        <ReviewThumbsUp reviewId={review?.id} thumbsUp={thumbsUpCount} />
      </div>
    </div>
  );
};
