export const getStorage = () => sessionStorage

export const saveScrollPosition = (path) => {
    try {
        let exists = getStorage().getItem(`scrollPosition:${path}`)
        if (!exists || exists === '0') {
            // console.log(`[scroll-position] Saving ${path} scroll position: ${window.scrollY}`)
            getStorage().setItem(`scrollPosition:${path}`, window.scrollY)
        }
    } catch (error) {
    }
}

export const restoreScrollPosition = (path) => {
    try {
        let scrollPosition = getStorage().getItem(`scrollPosition:${path}`)
        scrollPosition = parseInt(scrollPosition, 10)
        // console.log(path, scrollPosition)
        if (scrollPosition && scrollPosition > 0) {
            setTimeout(() => {
                // console.log(`[scroll-position] Restoring ${path} to scroll position: ${scrollPosition}`)

                const html = document.documentElement
                html.style.scrollBehavior = 'auto'

                window.scrollTo({
                    top: scrollPosition,
                    behavior: 'auto'
                })

                html.style.scrollBehavior = 'smooth'

            }, 150);

        }
    } catch (e) {
    }
}

export const deleteScrollPosition = (path) => {
    try {
        // console.log(`[scroll-postion] Delete ${path} scroll positon`)

        getStorage().removeItem(`scrollPosition:${path}`)
    } catch (error) {
    }
}
