import {createContext, useCallback, useContext, useMemo, useState} from 'react';

export const CartContext = createContext(null);

export default function SideCartUiProvider({children}) {
  const [open, setOpen] = useState(false)

  const openCart = useCallback(() => {
    setOpen(true)
    // add css variable --cart-top to html
    if(typeof window !== 'undefined') {
      if(window.scrollY > 0) {
        document.documentElement.style.setProperty('--cart-top', `0px`)
      }
      else {
        document.documentElement.style.removeProperty('--cart-top')
      }
    }
  }, [setOpen])

  const closeCart = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const toggleCart = useCallback(() => {
    setOpen(!open)
  }, [setOpen, open])

  const contextValue = useMemo(() => {
    return {
      isCartOpen: open,
      openCart,
      closeCart,
      toggleCart
    }
  }, [open, openCart, closeCart, toggleCart])

  return <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
}

export function useCartUI() {
  const context = useContext(CartContext)
  if (typeof context === 'undefined') {
    const errMsg = `useContext must be used within a CartContextProvider.`
    console.log([`[ERROR: useCartUi]: ${errMsg}`])
    throw new Error(errMsg)
  }
  return context
}
